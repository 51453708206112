import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    addCustomFieldFailure,
    addCustomFieldFolderFailure,
    addCustomFieldFolderSuccess,
    addCustomFieldSuccess,
    addUserMergeFieldFolderFailure,
    addUserMergeFieldFolderSuccess,
    customFieldStatusFailure,
    customFieldStatusSuccess,
    deleteCustomFieldFailure,
    deleteCustomFieldFolderFailure,
    deleteCustomFieldFolderSuccess,
    deleteCustomFieldSuccess,
    deleteUserMergeFieldFolderFailure,
    deleteUserMergeFieldFolderSuccess,
    getCustomFieldFailure,
    getCustomFieldFolderFailure,
    getCustomFieldFolderSuccess,
    getCustomFieldSuccess,
    getUserMergeFieldFoldersFailure,
    getUserMergeFieldFoldersSuccess,
    updateCustomFieldFailure,
    updateCustomFieldFolderFailure,
    updateCustomFieldFolderSuccess,
    updateCustomFieldSuccess,
    updateUserMergeFieldFolderFailure,
    updateUserMergeFieldFolderSuccess,
    userCustomFieldGroupChangeFailure,
    userCustomFieldGroupChangeSuccess,
    userCustomFieldsOrderChangeFailure,
    userCustomFieldsOrderChangeSuccess,

} from "../actions/CustomFieldInsuranceAction";
import * as CUSTOM_API from "../api/CustomFieldApi";
import * as CUSTOMFIELD_INSURANCE_ACTION from "../constants/customFieldInsuranceActionTypes";
import Utils from "../helpers/Utils";

function* customFieldInsuranceWatcher() {
  
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.ADD_CUSTOM_FIELD_FOLDER,
    addCustomFieldGroupMiddleware
  );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.GET_CUSTOM_FIELD_FOLDER,
    getCustomFieldFolderMiddleware
  );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.UPDATE_CUSTOM_FIELD_FOLDER,
    updateCustomFieldFolderMiddleware
  );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.DELETE_CUSTOM_FIELD_FOLDER,
    deleteCustomFieldFolderMiddleware
  );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.GET_CUSTOM_FIELD,
    getCustomFieldMiddleware
    );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.ADD_CUSTOM_FIELD,
    addCustomFieldMiddleware
    );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.UPDATE_CUSTOM_FIELD_INSURANCE,
    updateCustomFieldMiddleware
    );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.DELETE_CUSTOM_FIELD_INSURANCE,
    deleteCustomFieldMiddleware
    );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.HANDLE_CUSTOM_FIELD_STATUS,
    handleUserCustomFieldStatusMiddleware
    );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE,
    handleUserCustomFieldGroupChangeMiddleware
    );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE,
    handleUserCustomFieldOrderChangeMiddleware
    );

                      // Merge Field 
  
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.ADD_USER_MERGE_FIELD_FOLDER,
    addUserMergeFieldGroupMiddleware
  );

  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.GET_USER_MERGE_FIELD_FOLDER,
    getUserMergeFieldGroupMiddleware
  );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.UPDATE_USER_MERGE_FIELD_FOLDER,
    updateUserMergeFieldGroupMiddleware
  );
  yield takeEvery(
    CUSTOMFIELD_INSURANCE_ACTION.DELETE_USER_MERGE_FIELD_FOLDER,
    deleteUserMergeFieldGroupMiddleware
  );
 
}

function* addCustomFieldGroupMiddleware(action) {
  try {
    const response = yield call(CUSTOM_API.addCustomFieldFolder, action.payload);
    if (response.success) {
       
      yield put(addCustomFieldFolderSuccess(response.data));
      window.showNotification("SUCCESS", response.message);
    }
    else{
      yield put(addCustomFieldFolderFailure(response.message));
      window.showNotification("ERROR", response.message);
    }
  } catch (error) {
    yield put(addCustomFieldFolderFailure(error.message));
    window.showNotification("ERROR", error.message);
    Utils.handleException(error);
  }
}

function* getCustomFieldFolderMiddleware(action) {
    try {
      const response = yield call(
          CUSTOM_API.getCustomFieldFolder
      );
     
      if (response.success) {
         
        yield put(getCustomFieldFolderSuccess(response.data));
      }
      else{
        yield put(getCustomFieldFolderFailure(response.message));
        window.showNotification("ERROR", response.message);
        }
    } catch (error) {
      yield put(getCustomFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }


  function* updateCustomFieldFolderMiddleware(action) {

    try {
      const response = yield call(CUSTOM_API.updateCustomFieldFolder, action.payload);
  
      if (response.success) {
        yield put(updateCustomFieldFolderSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
        
      } else {
        yield put(updateCustomFieldFolderFailure(response.message));
        window.showNotification("ERROR", response?.message);
      }
    } catch (error) {
      yield put(updateCustomFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

  function* deleteCustomFieldFolderMiddleware(action) {
    try {
      const response = yield call(
          CUSTOM_API.deleteCustomFieldFolder,action.payload);

      if (response.success) {
         
        yield put(deleteCustomFieldFolderSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);
      }
      else{
        yield put(deleteCustomFieldFolderFailure(response.message));
        window.showNotification("ERROR", response.message);
        }
    } catch (error) {
      yield put(deleteCustomFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }


  function* addCustomFieldMiddleware(action) {
    try {
      const response = yield call(CUSTOM_API.addCustomField, action.payload);

      if (response.success) {
        yield put(addCustomFieldSuccess(response.data));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
      } else {
        yield put(addCustomFieldFailure(response.message));
        window.showNotification("ERROR", response.message);
      }
    } catch (error) {
      yield put(deleteCustomFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

  function* getCustomFieldMiddleware(action) {
    try {
      const response = yield call(CUSTOM_API.getCustomField, action.payload);

      if (response.success) {
        yield put(getCustomFieldSuccess(response));
      } else {
        yield put(getCustomFieldFailure(response.message));
        window.showNotification("ERROR", response.message);
      }
    } catch (error) {
      yield put(getCustomFieldFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }


  function* updateCustomFieldMiddleware(action) {

    try {
      const response = yield call(CUSTOM_API.updateCustomField, action.payload);
  
      if (response.success) {
        yield put(updateCustomFieldSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
        
      } else {
        yield put(updateCustomFieldFailure(response.message));
        window.showNotification("ERROR", response?.message);
      }
    } catch (error) {
      yield put(updateCustomFieldFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

  function* deleteCustomFieldMiddleware(action) {

    try {
      const response = yield call(CUSTOM_API.deleteCustomField, {id: action.payload.id});
  
      if (response.success) {
        yield put(deleteCustomFieldSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
        
      } else {
        yield put(deleteCustomFieldFailure(response.message));
        window.showNotification("ERROR", response?.message);
      }
    } catch (error) {
      yield put(deleteCustomFieldFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

  function* handleUserCustomFieldStatusMiddleware(action) {

    try {
      const response = yield call(CUSTOM_API.handleUserCustomFieldStatus, action.payload);
  
      if (response.success) {
        yield put(customFieldStatusSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
        
      } else {
        yield put(customFieldStatusFailure(response.message));
        window.showNotification("ERROR", response?.message);
      }
    } catch (error) {
      yield put(customFieldStatusFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

  function* handleUserCustomFieldGroupChangeMiddleware(action) {

    try {
      const response = yield call(CUSTOM_API.handleUserCustomFieldGroup, action.payload);
  
      if (response.success) {
        yield put(userCustomFieldGroupChangeSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
        
      } else {
        yield put(userCustomFieldGroupChangeFailure(response.message));
        window.showNotification("ERROR", response?.message);
      }
    } catch (error) {
      yield put(userCustomFieldGroupChangeFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  }

  function* handleUserCustomFieldOrderChangeMiddleware(action) {

    try {
      const response = yield call(CUSTOM_API.handleUserCustomFieldOrderChange, action.payload.submitData);
  
      if (response.success) {
        yield put(userCustomFieldsOrderChangeSuccess(action.payload.reOrderedFields));
        window.showNotification("SUCCESS", response.message);
      } else {
        yield put(userCustomFieldsOrderChangeFailure(response.message));
        window.showNotification("ERROR", response?.message);
      }
    } catch (error) {
      yield put(userCustomFieldsOrderChangeFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  };
              // Merge Field 
  
  function* addUserMergeFieldGroupMiddleware(action) {
    try {
      const response = yield call(CUSTOM_API.addUserMergeFieldFolderApi, action.payload);
      if (response.success) {
          
        yield put(addUserMergeFieldFolderSuccess(response.data));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
      }
      else{
        yield put(addUserMergeFieldFolderFailure(response.message));
        window.showNotification("ERROR", response.message);
      }
    } catch (error) {
      yield put(addUserMergeFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  };

  function* getUserMergeFieldGroupMiddleware(action) {
    try {
      const response = yield call(CUSTOM_API.getUserMergeFieldFolderApi, action.payload);
      if (response.success) {
          
        yield put(getUserMergeFieldFoldersSuccess(response.data));
        window.showNotification("SUCCESS", response.message);

      }
      else{
        yield put(getUserMergeFieldFoldersFailure(response.message));
        window.showNotification("ERROR", response.message);
      }
    } catch (error) {
      yield put(getUserMergeFieldFoldersFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  };

  function* updateUserMergeFieldGroupMiddleware(action) {
    try {
      const response = yield call(CUSTOM_API.updateUserMergeFieldFolderApi, action.payload);
      if (response.success) {
          
        yield put(updateUserMergeFieldFolderSuccess(action.payload));
        window.showNotification("SUCCESS", response.message);

        if (action.payload && action.payload.callback) {
          action.payload.callback(response.data);
        }
      }
      else{
        yield put(updateUserMergeFieldFolderFailure(response.message));
        window.showNotification("ERROR", response.message);
      }
    } catch (error) {
      yield put(updateUserMergeFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  };

  function* deleteUserMergeFieldGroupMiddleware(action) {
    try {
      const response = yield call(CUSTOM_API.deleteUserMergeFieldFolderApi, action.payload);
      if (response.success) {
          
        yield put(deleteUserMergeFieldFolderSuccess(action.payload.id));
        window.showNotification("SUCCESS", response.message);
      }
      else{
        yield put(deleteUserMergeFieldFolderFailure(response.message));
        window.showNotification("ERROR", response.message);
      }
    } catch (error) {
      yield put(deleteUserMergeFieldFolderFailure(error.message));
      window.showNotification("ERROR", error.message);
      Utils.handleException(error);
    }
  };


export default function* customFieldInsuranceMiddleware() {
  yield all([customFieldInsuranceWatcher()]);
}
