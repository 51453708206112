import { makeStyles } from "@material-ui/core";

export const modalStyles = makeStyles({
    actions: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    button: {
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      maxHeight:"40px",
    },
    backOption: {
      backgroundColor: "#4550f8",
      color: "#fff",
      padding: "8px 16px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      maxHeight:"40px",
    },
    addButton: {
      backgroundColor: "#4550f8",
      color: "#fff",
      "&:focus, &:hover": {
        backgroundColor: "#4550f8",
      },
    },
    text: {
      fontSize: "16px",
      color: "#fff !important",
    },
    imgContainer: {
      width: "70px",
      height: "70px",
      marginRight: "5px",
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });