import JSBI from "jsbi";

class PermissionUtils {
    static PERMISSION_BITS = 4;

    static hasPermission(permissionValue, moduleId, permissionIds, type = "all") {
        const bitOffset = moduleId * PermissionUtils.PERMISSION_BITS;

        if (type === "all") {
            return permissionIds.every(permissionId => {
                const permissionForAsset = JSBI.leftShift(JSBI.BigInt(permissionId), JSBI.BigInt(bitOffset));
                return JSBI.notEqual(JSBI.bitwiseAnd(permissionValue, permissionForAsset), JSBI.BigInt(0));
            });
        } else if (type === "any") {
            return permissionIds.some(permissionId => {
                const permissionForAsset = JSBI.leftShift(JSBI.BigInt(permissionId), JSBI.BigInt(bitOffset));
                return JSBI.notEqual(JSBI.bitwiseAnd(permissionValue, permissionForAsset), JSBI.BigInt(0));
            });
        }

        return false;
    }

    static removePermission(rolePermission, moduleId, permissions) {
        const bitOffset = moduleId * PermissionUtils.PERMISSION_BITS;

        permissions.forEach((permission) => {
            const permissionForAsset = JSBI.leftShift(JSBI.BigInt(permission), JSBI.BigInt(bitOffset));
            rolePermission = JSBI.bitwiseAnd(rolePermission, JSBI.bitwiseNot(permissionForAsset));
        });

        return rolePermission;
    }

    static addPermission(rolePermission, moduleId, permissions) {
        const bitOffset = moduleId * PermissionUtils.PERMISSION_BITS;

        permissions.forEach((permission) => {
            const permissionForAsset = JSBI.leftShift(JSBI.BigInt(permission), JSBI.BigInt(bitOffset));
            rolePermission = JSBI.bitwiseOr(rolePermission, permissionForAsset);
        });

        return rolePermission;
    }

    static toBigInt(value) {
        return JSBI.BigInt(value);
    }
}

export default PermissionUtils;