import { Chip, IconButton, TableCell, TableRow, makeStyles } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import AddOrEditTagModal from "./AddOrEditTagModal";
import { deleteTag } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import { PERMISSION_LEVELS, PERMISSION_MODULES, PRIVACY_LEVEL } from "../../constants/CoreConstants";
import CustomConfirmAlert from "../products/common/confirm-alert/CustomConfirmAlert";

const useStyles = makeStyles((theme) => ({
  tableRowWrapper: {
    color: theme.palette.text.primary,

    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,

      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,

        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },

    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },

    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
    },

    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
    "& .chipStyle": {
      width: "fit-content",
      height: "26px",
      fontWeight:600,
      color: "#FFFFFF",
      backgroundColor: (props) => props.color || "#346fef",
      "& .MuiChip-label": {
        color: "#ffffff",
      },
    },
  },
}));

const EachTableItem = ({ tag, taglist, setTagList, reset, setReset, privacyLevelTab }) => {
  const { id, title, privacyLevel, createdAt, totalContact,color } = tag || {};
  const [openEditTagModal, setOpenEditTagModal] = useState(false);
  const classes = useStyles({color});
  const [startingDialerSession, setStartingDialerSession] = useState(false);
  const isUserManageGlobalItem = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

const handleDeleteItem = async () => {
  const resp = await deleteTag({
    form_data: {
      id: id,
    },
  });
  if (resp.data.status === "success") {
    setReset(reset + 1);
    window.showNotification("SUCCESS", resp.data.message);
} else {
  window.showNotification("ERROR", resp.data.message);
}
};

  const handleDelete = () => {
    CustomConfirmAlert({
      onSubmit: () => handleDeleteItem(),
      title: "Are you sure you want to delete this Tag?",
      description: "You cannot undo this action",
    });
  };

  const convertTimeToUserTimeZone = (date) => {
    if (date) {
      return window.globalTimezoneConversionToDifferentTimezone(
        date,
        "UTC",
        "",
        "MM/DD/YYYY hh:mm a"
      );
    } else {
      return "";
    }
  };
  
  const handleClickSessionStart = (item) => {

    if (startingDialerSession) {
      return;
    }
    setStartingDialerSession(true);
    window.globalPowerDialerModal(true, {
      type: "single-tag",
      value: item.id,
      name: item.name,
      callback: (response, formData) => {
        if (response.success) {
          const actionForPowerDialer = window.open(
            `/power-dialer?listId=${response?.data}&listName=${
              formData.name
            }&tags=${[item.id].join(",")}&from=tagList&tagName=${
              item.name
            }&for=startSession`,
            "_blank"
          );
          actionForPowerDialer.focus();
          setStartingDialerSession(false);
        } else {
          setStartingDialerSession(false);
        }
      },
    });
  };

  return (
    <TableRow className={classes.tableRowWrapper}>
      <TableCell component="th" scope="row">
        <Chip label={title} className={"chipStyle"} />
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>{totalContact || 0}</TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {convertTimeToUserTimeZone(createdAt)}
      </TableCell>
      <TableCell align="center">
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <BootstrapTooltip title="Start Dialing Session" placement="top">
            <IconButton
              onClick={() => handleClickSessionStart(tag)}
              size="small"
            >
              <CallIcon />
            </IconButton>
          </BootstrapTooltip>
          {(privacyLevel === PRIVACY_LEVEL.PRIVATE || isUserManageGlobalItem) && (
            <>
              <BootstrapTooltip title="Edit Tag" placement="top">
                <IconButton
                  onClick={() => setOpenEditTagModal(true)}
                  size="small"
                >
                  <EditIcon />
                </IconButton>
              </BootstrapTooltip>

              <BootstrapTooltip title="Delete" placement="top">
                <IconButton size="small" onClick={handleDelete}>
                  <DeleteIcon style={{ color: "rgb(255, 38, 74)" }} />
                </IconButton>
              </BootstrapTooltip>
            </>
          )}
          {openEditTagModal && (
            <AddOrEditTagModal
              open={openEditTagModal}
              onClose={() => setOpenEditTagModal(false)}
              edit={tag}
              taglist={taglist}
              setTagList={setTagList}
              privacyLevelTab={privacyLevelTab}
            />
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EachTableItem;
