import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getTagWithPrivacy } from "../../api/profileApi";
import { EMPTY_DATA_IMG, PRIVACY_LEVEL, PRIVACY_LEVEL_TAB_VALUE, SORT_BY, SORT_ORDER } from "../../constants/CoreConstants";
import GlobalEmptyPage from "../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import { CustomScrollbar } from "../products/productsStyle";
import { AscendingIcon, DecendingIcon } from "../ten-dlc/helpers/Icon";
import EachTableItem from "./EachTableItem";
import TagTableSkeleton from "./TagTableSkeleton";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  tableBodyHeight: {
    maxHeight: `calc(100vh - 355px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
  sortTableHeader: {
    display:' flex',
    alignItems:'center', 
    justifyContent:'center',
    gap:10,
  },
  sortTableHeaderIconContainer: {
    display:'flex', 
    flexDirection:'column',
    alignItems:'center',
    gap:'0px',
    width:'28px',
  }
});


const TagsTable = ({reset, setReset, privacyLevel, taglist, setTagList}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(10);
  const classes = useStyles();
  const [sortBy, setSortBy] = useState(SORT_BY.CREATED);
  const [createdSortOrder, setCreatedSortOrder] = useState(SORT_ORDER.DESC); 
  const [totalContactSortOrder, setTotalContactSortOrder] = useState(null); 

  useEffect(()=> {
    getTaglist();
  },[reset])// eslint-disable-line react-hooks/exhaustive-deps

  const getTaglist = async () => {
    setIsLoading(true);
    try {
      const payload = {
        page: page +1,
        limit: rowsPerPage,
        privacyLevel: privacyLevel === PRIVACY_LEVEL_TAB_VALUE.PRIVATE ? PRIVACY_LEVEL.PRIVATE : PRIVACY_LEVEL.GLOBAL,
        sortBy,
        sortOrder: sortBy === SORT_BY.CREATED ? createdSortOrder : totalContactSortOrder
      }
      const resp = await getTagWithPrivacy(payload);
      if(resp.success && resp.data && resp.data[0]){
        setTagList(resp.data);
        if(page === 0) {
          setTotal(resp.total_count);
        }
      }
      else{
        setTagList([]);
      }
    } catch (error) {
      console.log(error.message);
      window.showNotification("error", 'Something went wrong! Please try again.');
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setReset(reset + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setReset(reset + 1);
  };

  const handleSorting = (newSortBy, newSortOrder) => {
    const isCreatedSort = newSortBy === SORT_BY.CREATED;
  
    if (sortBy === newSortBy) {
      isCreatedSort
        ? setCreatedSortOrder(createdSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC
          )
        : setTotalContactSortOrder((totalContactSortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC
        ));
    } else {
      setSortBy(newSortBy);
      if (isCreatedSort) {
        setCreatedSortOrder(newSortOrder);
        setTotalContactSortOrder(null);
      }
      else {
        setTotalContactSortOrder(newSortOrder);
        setCreatedSortOrder(null);
      }
     }
    setReset(reset + 1);
  };

  return (
    <>
      {isLoading ? (
        <TagTableSkeleton />
      ) : !isLoading && taglist && !taglist[0] ? (
        <GlobalEmptyPage image={EMPTY_DATA_IMG} title={"No Tag Available"} />
      ) : (
        <div>
          <CustomScrollbar className={`${classes.tableBodyHeight}`}>
            <TableContainer component={"span"}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeader} width={"25%"}>
                      Name
                    </TableCell>
                    <TableCell className={classes.tableHeader} style={{width:"20%"}}>
                      <div className={classes.sortTableHeader}>
                        <span>Total Contacts</span>
                        <div className={classes.sortTableHeaderIconContainer}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSorting(
                                SORT_BY.TOTAL_CONTACT,
                                SORT_ORDER.DESC
                              )
                            }
                          >
                            <AscendingIcon
                              fill={
                                totalContactSortOrder === SORT_ORDER.DESC
                                  ? "var(--light_blue)"
                                  : "#546376"
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSorting(
                                SORT_BY.TOTAL_CONTACT,
                                SORT_ORDER.ASC
                              )
                            }
                          >
                            <DecendingIcon
                              fill={
                                totalContactSortOrder === SORT_ORDER.ASC
                                  ? "var(--light_blue)"
                                  : "#546376"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      <div className={classes.sortTableHeader}>
                        <span> Created</span>
                        <div className={classes.sortTableHeaderIconContainer}>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSorting(SORT_BY.CREATED, SORT_ORDER.DESC)
                            }
                          >
                            <AscendingIcon
                              fill={
                                createdSortOrder === SORT_ORDER.DESC
                                  ? "var(--light_blue)"
                                  : "#546376"
                              }
                            />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSorting(SORT_BY.CREATED, SORT_ORDER.ASC)
                            }
                          >
                            <DecendingIcon
                              fill={
                                createdSortOrder === SORT_ORDER.ASC
                                  ? "var(--light_blue)"
                                  : "#546376"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align={"center"} className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {taglist.map((tag) => (
                    <EachTableItem
                      key={tag.id}
                      tag={tag}
                      taglist={taglist}
                      setTagList={setTagList}
                      reset={reset}
                      setReset={setReset}
                      privacyLevelTab={privacyLevel}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomScrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};

export default TagsTable;
