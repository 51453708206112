import {createSlice} from '@reduxjs/toolkit';
import {PERMISSION_ROLES} from "../../constants/PermissionConst";

const initialState = {
    users: [],
    count: 0,
    page: 1,
    perPage: 10,
    loadingUsers: false,
    savingUser: false,
    subUsers: [],
    roleDefaultPermissions: {
        [PERMISSION_ROLES.STANDARD_USER]: "0",
        [PERMISSION_ROLES.LIMITED_USER]: "0",
    },
    loadingUserCreationDetails: false,
    loadingSubUserFormData: false,
    forceLogin: false,
    editUser: null,
    deactivatingUser: false,
    activatingUser: false,
    deletingUserWithoutTransfer: false,
};

const manageUserSlice = createSlice({
    name: 'manageUser',
    initialState,
    reducers: {
        getUserCreationDetails: (state) => {
            state.loadingUserCreationDetails = true;
        },
        getUserCreationDetailsSuccess: (state, action) => {
            state.loadingUserCreationDetails = false;
            state.editUser = action.payload.editUser || null;
        },
        getUserCreationDetailsError: (state) => {
            state.loadingUserCreationDetails = false;
        },
        getSubUserFormData: (state, action) => {
            state.loadingSubUserFormData = true;
        },
        getSubUserFormDataSuccess: (state, action) => {
            state.loadingSubUserFormData = false;
            state.subUsers = action.payload.subUsers;
            state.roleDefaultPermissions = action.payload.roleDefaultPermissions;
        },
        getSubUserFormDataError: (state, action) => {
            state.loadingSubUserFormData = false;
            state.subUsers = [];
            state.roleDefaultPermissions = {
                [PERMISSION_ROLES.STANDARD_USER]: "0",
                [PERMISSION_ROLES.LIMITED_USER]: "0",
            };
        },
        getUsers: (state) => {
            state.loadingUsers = true;
        },
        getUsersSuccess: (state, action) => {
            state.loadingUsers = false;
            state.users = action.payload.data || [];
            state.count = action.payload.total || 0;
            state.page = action.payload.current_page || 1;
            state.perPage = action.payload.per_page || 10;
        },
        getUsersError: (state) => {
            state.loadingUsers = false;
        },
        saveUser: (state) => {
            state.savingUser = true;
        },
        saveUserSuccess: (state, action) => {
            state.savingUser = false;
        },
        saveUserError: (state) => {
            state.savingUser = false;
        },
        forceLogin: (state) => {
          state.forceLogin = true;
        },
        forceLoginSuccess: (state) => {
            state.forceLogin = false;
        },
        forceLoginError: (state) => {
            state.forceLogin = false;
        },
        deactivateUser: (state) => {
            state.deactivatingUser = true;
        },
        deactivateUserSuccess: (state) => {
            state.deactivatingUser = false;
        },
        deactivateUserError: (state) => {
            state.deactivatingUser = false;
        },
        activateUser: (state) => {
            state.activatingUser = true;
        },
        activateUserSuccess: (state) => {
            state.activatingUser = false;
        },
        activateUserError: (state) => {
            state.activatingUser = false;
        },
        deleteUserWithoutTransfer: (state) => {
            state.deletingUserWithoutTransfer = true;
        },
        deleteUserWithoutTransferSuccess: (state) => {
            state.deletingUserWithoutTransfer = false;
        },
        deleteUserWithoutTransferError: (state) => {
            state.deletingUserWithoutTransfer = false;
        },
        resetToInitial: (state) => {
            state.editUser = null;
        }
    },
});

export const {
    getUserCreationDetails,
    getUserCreationDetailsSuccess,
    getUserCreationDetailsError,
    getSubUserFormData,
    getSubUserFormDataSuccess,
    getSubUserFormDataError,
    getUsers,
    getUsersSuccess,
    getUsersError,
    saveUser,
    saveUserSuccess,
    saveUserError,
    forceLogin,
    forceLoginSuccess,
    forceLoginError,
    deactivateUser,
    deactivateUserSuccess,
    deactivateUserError,
    activateUser,
    activateUserSuccess,
    activateUserError,
    deleteUserWithoutTransfer,
    deleteUserWithoutTransferSuccess,
    deleteUserWithoutTransferError,
    resetToInitial,
} = manageUserSlice.actions;

export default manageUserSlice.reducer;
