import {
  sendRequest,
  sendRequestBackend,
  sendRequestBackEndPublicApiToken,
} from "./rootApi";
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const fetchUser = async (payload) => {
  return await sendRequest("post", Api.getUserData, payload);
};

export const updateUser = async (payload) => {
  return sendRequest("post", Api.updateUserData, payload.form_data);
};

export const updateUserAvatar = async (payload) => {
  return sendRequest("post", Api.updateAvatar, payload.form_data);
};

export const updateFavicon = async (payload) => {
  return sendRequest("post", Api.updateFavicon, payload);
};

export const updatePassword = async (payload) => {
  return sendRequest("post", Api.updatePassword, payload.form_data);
};

export const updateCompanyData = async (payload) => {
  return sendRequest("post", Api.updateCompanyData, payload.form_data);
};

//settings
export const getGeneralSettings = async (payload) => {
  return sendRequest("post", Api.getGeneralSettings, payload.form_data);
};

export const updateGeneralSettings = async (payload) => {
  return sendRequest("post", Api.updateGeneralSettings, payload.form_data);
};

export const getCutoffSettings = async (payload) => {
  return sendRequest("post", Api.getCutoffSettings, payload.form_data);
};

export const updateCutoffSettings = async (payload) => {
  return sendRequest("post", Api.updateCutoffSettings, payload.form_data);
};

export const updateTimezone = async (payload) => {
  return sendRequest("post", Api.updateTimezone, payload.form_data);
};

export const getNotificationSettings = async (payload) => {
  return sendRequest("post", Api.getNotificationSettings, payload.form_data);
};

export const updateNotificationSettings = async (payload) => {
  return sendRequest("post", Api.updateNotificationSettings, payload.form_data);
};

export const getCallOutcomeList = async (payload) => {
  return sendRequest("post", Api.getCallOutcomeList, payload);
};

export const saveCallOutcome = async (payload) => {
  return sendRequest("post", Api.saveCallOutcome, payload.form_data);
};

export const deleteCallOutcome = async (payload) => {
  return sendRequest("post", Api.deleteCallOutcome, payload.form_data);
};

export const getInstantTopupSettings = async (payload) => {
  return sendRequest("post", Api.getInstantTopupSettings, payload.form_data);
};

export const rechargeInstantTopup = async (payload) => {
  return sendRequest("post", Api.rechargeInstantTopup, payload.form_data);
};

export const getRechargeSettings = async (payload) => {
  return sendRequest("post", Api.getRechargeSettings, payload.form_data);
};

export const updateRechargeSettings = async (payload) => {
  return sendRequest("post", Api.updateRechargeSettings, payload.form_data);
};

export const existingCardList = async (payload) => {
  return sendRequest("post", Api.existingCardList, payload.form_data);
};

export const activateCard = async (payload) => {
  return sendRequest("post", Api.activateCard, payload.form_data);
};

export const deleteCard = async (payload) => {
  return sendRequest("post", Api.deleteCard, payload.form_data);
};

export const getCardInfoSettings = async (payload) => {
  return sendRequest("post", Api.getCardInfoSettings, payload.form_data);
};

export const saveCard = async (payload) => {
  return sendRequest("post", Api.saveCard, payload.form_data);
};

export const getSubUserList = async (payload) => {
  return sendRequest("post", Api.subUserList, payload.form_data);
};

export const snapShotAccountList = async (payload) => {
  return sendRequest("post", Api.snapShotAccountList, payload.form_data);
};

export const deactivateUser = async (payload) => {
  return sendRequest("post", Api.deactivateUser, payload.form_data);
};

export const activateUser = async (payload) => {
  return sendRequest("post", Api.activateUser, payload.form_data);
};

export const getUserCreationDetails = async (payload) => {
  return sendRequest("post", Api.userCreationDetails, payload.form_data);
};

export const saveSubUser = async (payload) => {
  return sendRequest("post", Api.saveSubUser, payload.form_data);
};

export const saveSnapShotAccount = async (payload) => {
  return sendRequest("post", Api.saveSnapShotAccount, payload.form_data);
};

export const snapShotAccountDelete = async (payload) => {
  return sendRequest("post", Api.snapShotAccountDelete, payload.form_data);
};

export const syncAccountSnapShot = async (payload) => {
  return sendRequest("post", Api.syncAccountSnapShot, payload.form_data);
};

export const getSnapShotDetails = async (payload) => {
  return sendRequest("post", Api.getSnapShotDetails, payload.form_data);
};

export const checkDuplicateSnapShotItems = async (payload) => {
  return sendRequest(
    "post",
    Api.checkDuplicateSnapShotItems,
    payload.form_data
  );
};

export const processSnapShotItems = async (payload) => {
  return sendRequest("post", Api.processSnapShotItems, payload.form_data);
};

export const transferDataDetails = async (payload) => {
  return sendRequest("post", Api.transferDataDetails, payload.form_data);
};

export const deleteUser = async (payload) => {
  return sendRequest("post", Api.deleteUser, payload.form_data);
};

export const deleteUserWithoutTransfer = async (payload) => {
  return sendRequest("post", Api.deleteUserWithoutTransfer, payload.form_data);
};

export const saveEmailProvider = async (payload) => {
  return sendRequest("post", Api.saveEmailProvider, payload.form_data);
};

export const getCanSpamAddress = async (payload) => {
  return sendRequest("post", Api.getCanSpamAddress, payload.form_data);
};

export const saveCanSpamAddress = async (payload) => {
  return sendRequest("post", Api.saveCanSpamAddress, payload.form_data);
};

export const saveSignature = async (payload) => {
  return sendRequest("post", Api.saveSignature, payload.form_data);
};

export const getConnectedCalendar = async (payload) => {
  return sendRequest("post", Api.getConnectedCalendar, payload.form_data);
};

export const calendarSyncDelete = async (payload) => {
  return sendRequest("post", Api.calendarSyncDelete, payload.form_data);
};

export const calendarSyncList = async (payload) => {
  return sendRequest("post", Api.calendarSyncList, payload.form_data);
};

export const calendarSyncStore = async (payload) => {
  return sendRequest("post", Api.calendarSyncStore, payload.form_data);
};

export const getContactList = async (payload) => {
  return sendRequest("post", Api.contactList, payload.form_data);
};

export const deleteList = async (payload) => {
  return sendRequest("post", Api.deleteList, payload.form_data);
};

export const contactListSave = async (payload) => {
  return sendRequest("post", Api.contactListSave, payload.form_data);
};

export const getTagList = async (payload) => {
  return sendRequest("post", Api.tagList, payload.form_data);
};

export const saveTag = async (payload) => {
  return sendRequest("post", Api.saveTag, payload.form_data);
};

export const deleteTag = async (payload) => {
  return sendRequest("post", Api.deleteTag, payload.form_data);
};

export const getActivityLogsList = async (payload) => {
  return sendRequest("post", Api.activityLogsList, payload.form_data);
};

export const fetchLeadGroupForDataTable = async (payload) => {
  return sendRequest("post", Api.getLeadGroupForDataTable, payload.form_data);
};

export const getTeamUsers = async (payload) => {
  return sendRequest("post", Api.getTeamUsers, payload.form_data);
};

export const getTeamUsersWithoutTemplate = async (payload) => {
  return sendRequest(
    "post",
    Api.getTeamUsersWithoutTemplate,
    payload.form_data
  );
};

export const updateOrCreateLeadGroup = async (payload) => {
  return sendRequest("post", Api.updateOrCreateLeadGroup, payload.form_data);
};

export const removeGroup = async (payload) => {
  return sendRequest("post", Api.removeGroup, payload.form_data);
};

export const getPackagesList = async (payload) => {
  return sendRequest("post", Api.packagesList, payload.form_data);
};

export const getPackageDetails = async (payload) => {
  return sendRequest("post", Api.packageDetails, payload.form_data);
};

export const packageDowngradeRequest = async (payload) => {
  return sendRequest("post", Api.packageDowngradeRequest, payload.form_data);
};

export const packagePayment = async (payload) => {
  return sendRequest("post", Api.packagePayment, payload.form_data);
};

export const packagePaymentNew = async (payload) => {
  return sendRequest("post", Api.packagePaymentNew, payload.form_data);
};

export const getMapOrdersList = async (payload) => {
  return sendRequest("post", Api.mapOrdersList, payload.form_data);
};

export const mapOrdersSaveTitle = async (payload) => {
  return sendRequest("post", Api.mapOrdersSaveTitle, payload.form_data);
};

export const getMapFetchContactList = async (payload) => {
  return sendRequest("post", Api.mapFetchContactList, payload.form_data);
};

export const mapOrderAddToContact = async (payload) => {
  return sendRequest("post", Api.mapOrderAddToContact, payload.form_data);
};

export const getPhoneSettingsDetails = async (payload) => {
  return sendRequest("post", Api.phoneSettingsDetails, payload.form_data);
};

export const getVirtualNumberList = async (payload) => {
  return sendRequest("post", Api.virtualNumberList, payload.form_data);
};

export const getVirtualSearchNumberList = async (payload) => {
  return sendRequest("post", Api.virtualSearchNumberList, payload.form_data);
};

export const buyVirtualNumber = async (payload) => {
  return sendRequest("post", Api.buyVirtualNumber, payload.form_data);
};

export const deleteVirtualNumber = async (payload) => {
  return sendRequest("post", Api.deleteVirtualNumber, payload.form_data);
};

export const deleteVirtualNumberSetting = async (payload) => {
  return sendRequest("post", Api.deleteVirtualNumberSetting, payload);
};

export const forwardVirtualNumberSubmit = async (payload) => {
  return sendRequest("post", Api.forwardVirtualNumberSubmit, payload);
};

export const incomingCallTimeoutUpdate = async (payload) => {
  return sendRequest("post", Api.incomingCallTimeoutSubmit, payload);
};

export const callWhisperVirtualNumberSubmit = async (payload) => {
  return sendRequest("post", Api.callWhisperVirtualNumberSubmit, payload);
};

export const callRecordingDisclosureSubmit = async (payload) => {
  return sendRequest(
    "post",
    Api.virtualNumberRecordingDisclosureSubmit,
    payload
  );
};

export const virtualNumberVoicemailMessageSubmit = async (payload) => {
  return sendRequest("post", Api.virtualNumberVoicemailMessageSubmit, payload);
};

export const virtualNumberChangeDefault = async (payload) => {
  return sendRequest("post", Api.virtualNumberChangeDefault, payload.form_data);
};

export const virtualNumberRequestForShakenStirRegistration = async (
  payload
) => {
  return sendRequest(
    "post",
    Api.virtualNumberRequestForShakenStirRegistration,
    payload.form_data
  );
};

export const virtualNumberCallRecordSetting = async (payload) => {
  return sendRequest(
    "post",
    Api.virtualNumberCallRecordSetting,
    payload.form_data
  );
};

export const virtualNumberSaveDialerSetting = async (payload) => {
  return sendRequest(
    "post",
    Api.virtualNumberSaveDialerSetting,
    payload.form_data
  );
};

export const virtualNumberUpdateForwardSetting = async (payload) => {
  return sendRequest(
    "post",
    Api.virtualNumberUpdateForwardSetting,
    payload.form_data
  );
};

export const virtualNumberUpdateDialerSetting = async (payload) => {
  return sendRequest(
    "post",
    Api.virtualNumberUpdateDialerSetting,
    payload.form_data
  );
};

export const editVirtualNumber = async (payload) => {
  return sendRequest("post", Api.editVirtualNumber, payload.form_data);
};

export const getMailBoxList = async (payload) => {
  return sendRequest("post", Api.mailBoxList, payload.form_data);
};

export const deleteMailBox = async (payload) => {
  return sendRequest("post", Api.deleteMailBox, payload.form_data);
};

export const getSubUserAuthKey = async (payload) => {
  return sendRequest("post", Api.getSubUserAuthKey, payload);
};

export const cancelUserAccount = async (payload) => {
  return sendRequest("post", Api.cancelUserAccount, payload.form_data);
};

/* for public api token */
export const getUserPublicApiTokenV2Api = async (user_id) => {
  return sendRequestBackEndPublicApiToken(
    "GET",
    Api.getPublicApiToken + "/" + user_id,
    "user"
  );
};

export const generateOrRefreshPublicApiTokenApi = async (user_id) => {
  return sendRequestBackEndPublicApiToken(
    "POST",
    Api.createOrGeneratePublicApiToken + "/" + user_id,
    "user"
  );
};

export const getPublicApiListApi = async (user_id) => {
  return sendRequestBackEndPublicApiToken("GET", Api.getPublicApiList, "user");
};

/* for virtual number settng */
export const getUserCallRecordingSettingApi = async (payload) => {
  return sendRequest("POST", Api.getUserCallRecordngSetting, payload);
};

export const updateUserCallRecordingSettingApi = async (payload) => {
  return sendRequest("POST", Api.updateUserCallRecordngSetting, payload);
};

export const saveNotifyAccount = async (payload) => {
  return sendRequest("post", Api.saveNotifyAccount, payload.form_data);
};

export const getNotifyAccountList = async () => {
  return sendRequest("post", Api.notifyAccountList);
};

export const deleteNotifyAccount = async (payload) => {
  return sendRequest("post", Api.deleteNotifyAccount, payload.form_data);
};
export const getUserAccountDataApi = async () => {
  return sendRequest("POST", Api.getUserAccountData);
};

/* user a2p brand list */
export const getUserA2pBrandListApi = async () => {
  return sendRequest("POST", Api.getUserA2pBrandList);
};

/* map integration api */
export const hasMapIntetrationApi = async (payload) => {
  return sendRequest("POST", Api.hasMapIntegration, payload);
};
export const getMapIntegrationPackageApi = async () => {
  return sendRequest("POST", Api.getMapIntegrationPackages);
};
export const submitMapIntegrationApi = async (payload) => {
  return sendRequest("POST", Api.submitMapIntegration, payload);
};
export const getAgencySettingApi = async (payload) => {
  return sendRequest("POST", Api.getAgencySettings, payload);
};
export const getTeamUserListApi = async (payload) => {
  return sendRequest("POST", Api.getTeamUserList, payload);
};

export const updateUserExternalServiceAccessApi = async (payload) => {
  return sendRequest("POST", Api.updateUserExternalServiceAccess, payload);
};

export const getUserSelectedPackageApi = async (payload) => {
  return sendRequest("POST", Api.getUserSelectedPackage, payload);
};

export const mapYourSalesForceLoginApi = async (payload) => {
  return sendRequest("POST", Api.mapYourSalesForceLogin, payload);
};

export const externalServiceLoginCheckApi = async (payload) => {
  return sendRequest("POST", Api.externalServiceLoginCheck, payload);
};

export const mysUpgradePackageApi = async (payload) => {
  return sendRequest("POST", Api.mysUpgradePackage, payload);
};

export const mysDowngradePackageApi = async (payload) => {
  return sendRequest("POST", Api.mysDowngradePackage, payload);
};

export const mysCancelDowngradeRequestApi = async (payload) => {
  return sendRequest("POST", Api.mysCancelDowngradeRequest, payload);
};

export const getCompanyLogoApi = async (payload) => {
  return sendRequest("POST", Api.getCompanyLogo, payload);
};

export const viewUserMapFeatureApi = async (payload) => {
  return sendRequest("POST", Api.viewUserMapFeature, payload);
};

export const deleteOrderById = async (payload) => {
  return sendRequest("post", Api.deleteOrderById, payload.form_data);
};

export const getsnapTeamUserList = async (payload) => {
  return sendRequest("post", Api.snapTeamUserList, payload.form_data);
};

export const addToPowerDialerMapContact = async (payload) => {
  return sendRequestBackend("post", Api.addToPowerDialerMapContact, payload);
};

export const getPowerDialerVirtualNumbers = async () => {
  return sendRequestBackend("get", Api.getPowerDialerVirtualNumbers);
};
export const getPowerDialerLists = async () => {
  return sendRequestBackend("get", Api.getPowerDialerLists);
};

/* power dialer related */
export const createPowerDialerFromOutsideApi = async (payload) => {
  return sendRequestBackend("POST", Api.createPowerDialerFromOutside, payload);
};

export const getLeadPermissionRequests = async (payload) => {
  return sendRequest("post", Api.getLeadPermissionRequests, payload);
};

export const rejectContactRequest = async (payload) => {
  return sendRequest("post", Api.rejectContactRequest, payload);
};

export const subUserListForContactDropdown = async (payload) => {
  return sendRequest("post", Api.subUserListForContactDropdown, payload);
};

export const acceptContactRequest = async (payload) => {
  return sendRequestBackend("post", Api.acceptContactRequest, payload);
};

export const bulkActionContactPermissionRequest = async (payload) => {
  return sendRequestBackend(
    "post",
    Api.bulkActionContactPermissionRequest,
    payload
  );
};

export const snapShotCategoryList = async (payload) => {
  return sendRequest("post", Api.snapShotCategoryList, payload);
};

export const snapShotPublicUpdate = async (payload) => {
  return sendRequest("post", Api.snapShotPublicUpdate, payload.form_data);
};

export const categorySnapShotList = async (payload) => {
  return sendRequest("post", Api.categorySnapShotList, payload);
};

export const getViewControlsByModule = async (payload) => {
  return sendRequest("post", Api.getViewControlsByModule, payload);
};

export const updateViewControl = async (payload) => {
  return sendRequest("post", Api.updateViewControl, payload);
};

export const saveUserCustomMenu = async (payload) => {
  return sendRequest("post", Api.saveCustomMenu, payload.form_data);
};

export const getUserCustomMenusApi = async (payload) => {
  return sendRequest("post", Api.getUserCustomMenus, payload.form_data);
};

export const deleteCustomMenuApi = async (payload) => {
  return sendRequest("post", Api.deleteCustomMenu, payload.form_data);
};

export const getContactLookupUsagesReportApi = async (payload) => {
  return sendRequestBackend("post", Api.getContactLookupUsagesReport, payload);
};
export const getA2pTrustCenterStatusApi = async (payload) => {
  return sendRequest("post", Api.getA2pTrustCenterStatus, payload);
};

export const getLookupSettingApi = async (payload) => {
  return sendRequest("post", Api.getLookupSettings, payload.form_data);
};

export const updateLookupSettingApi = async (payload) => {
  return sendRequest("post", Api.updateLookupSettings, payload.form_data);
};

export const cancelGeofarmingApi = async (payload) => {
  return sendRequest("post", Api.cancelGeofarming, payload.form_data);
};

export const getUserA2PRegistrationTypeApi = async () => {
  return sendRequest("post", Api.getUserA2PRegistrationType);
};

export const getCreditSettings = async (payload) => {
  return sendRequest("post", Api.getCreditSettings, payload);
};

export const addContactWithSkipTrace = async (payload) => {
  return sendRequest("post", Api.addContactWithSkipTrace, payload);
};

export const getUserBrandInfo = async (payload)=>{
    return sendRequest("post", Api.getUserBrandInfo, payload);
}

export const getUserBrandInfoForUpdate = async (payload)=>{
  return sendRequest("post", Api.getUserBrandInfoForUpdate, payload);
}

export const carrierLookupMapApi = async (payload) => {
  return sendRequestBackend("post", Api.carrierLookupMap, payload);
};

// App List

export const createNewApp = async (payload) => {
  return HttpRequest.post(Api.addNewApp, payload);
};

export const getAppList = async (payload) => {
  return HttpRequest.get(Api.getAllAppList, payload);
};

export const requestAuthCode = async (payload) => {
  return HttpRequest.post(Api.requestAuthCode, payload);
};

export const deleteApp = async (id, payload) => {
  return HttpRequest.delete(Api.deleteApp + id, payload);
};
export const getAppInfo = async (clientID, payload) => {
  return HttpRequest.get(Api.getAppInfo + clientID, payload);
};

export const mysBuyNewSubscription = async (payload) => {
  return HttpRequest.post(Api.mysBuyNewSubscription, payload);
}

export const mysUpgradeSubscription = async (payload) => {
  return HttpRequest.post(Api.mysUpgradeSubscription, payload);
}

export const mysDowngradeSubscription = async (payload) => {
  return HttpRequest.post(Api.mysDowngradeSubscription, payload);
}

export const hasMapIntegrationV2 = async (payload) => {
  return HttpRequest.post(Api.hasMapIntegrationV2, payload);
};

export const updateDataAfterMapSkipTrace = async (payload) => {
  return HttpRequest.post(Api.updateDataAfterMapSkipTrace, payload);
}

export const getConnectedEmails = async (payload) => {
  return sendRequest("post", Api.getUserConnectedEmailList, payload);
};

export const getUserConnectedEmailListLimit = async (payload) => {
  return HttpRequest.get(Api.userConnectedEmailListLimit, payload);
};

export const updateUserConnectedEmailsLimits = async (payload) => {
  return sendRequestBackend("put", Api.userConnectedEmailListLimit, payload);
};

export const markEmailAsDefault = async (payload) => {
  return sendRequest("post", Api.markEmailAsDefault, payload);
};

export const deleteUserConnectedEmail = async (payload) => {
  return sendRequest("post", Api.deleteUserConnectedEmail, payload);
};

export const disconnectUserConnectedEmail = async (payload) => {
  return sendRequest("post", Api.disconnectUserConnectedEmail, payload);
};

export const connectNylas = async (payload) => {
  return HttpRequest.post(Api.connectNylas, payload);
};

export const calendarSyncData = async (payload) => {
  return sendRequest("get", Api.calendarSyncData, payload);
}

export const calendarSyncDataDelete = async (payload) => {
  return sendRequest("post", Api.calendarSyncDataDelete, payload);
}
export const emailListData=async(payload)=>{
  return sendRequest("get", Api.emailList, payload);
}
export const emailCalendarListData=async(payload)=>{
  return sendRequest("get", Api.emailCalendarList, payload);
}

export const getConnectedEmailReportStats = async () => {
  return HttpRequest.post(Api.getUserConnectedEmailReport);
};

export const getOpenAIApikey = async () => {
  return HttpRequest.get(Api.openAIApiKey);
};

export const saveOpenAIApikey = async (payload) => {
  return HttpRequest.post(Api.openAIApiKey, payload);
};

export const deleteOpenAIApikey = async () => {
  return HttpRequest.delete(Api.openAIApiKey);
};

export const googleAccountApi = async() => {
  return HttpRequest.get(Api.googleAccountApi)
}

export const deleteGoogleAccountApi = async (payload) => {
  return HttpRequest.post(Api.deleteGoogleAccountApi,payload)
}
export const getTagWithPrivacy = async (payload) => {
  return HttpRequest.get(Api.tagsApi, payload);
};
export const addTagWithPrivacy = async (payload) => {
  return HttpRequest.post(Api.tagsApi, payload);
};

export const editTagWithPrivacy = async (payload) => {
  return HttpRequest.put(Api.tagsApi, payload);
};

export const getUserCredit = (payload) => {
  return HttpRequest.get(Api.getUserCredit, payload);
};