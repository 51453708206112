import React from "react";
import { icons } from "../icon";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },

  fieldLabel: {
    fontSize: 14,
    marginBottom: "8px",
  },
  note: {
    fontSize: 14,
    marginTop: "8px",
  },
}));
const PaginationCallOutCome = ({
  currentPage,
  total,
  perPage,
  onPageChange,
}) => {
  const classes = useStyles();
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    if (newPage === null) {
      return;
    }
    onPageChange(newPage - 1);
  };

  let previous_page = null,
    next_page = null;
  let totalPages = Math.ceil(total / perPage);
  let _currentPage = currentPage;

  if (_currentPage > 1) {
    previous_page = _currentPage - 1;
  }
  if (_currentPage !== totalPages) {
    next_page = _currentPage + 1;
  }
  if (total === 0) {
    return null;
  }

  const buttonStyle = (isDisabled) => ({
    cursor: isDisabled ? "not-allowed" : "pointer",
    backgroundColor: isDisabled ? "#9daac1" : "#4550f8",
    borderColor: "#9daac1",
    padding: "13px",
    border: "none",
    borderRadius: "5px",
  });

  return (
    <div className="pagination_bar">
      <ul className={classes.buttonContainer}>
        <li>
          <button
            onClick={(e) => handleChangePage(e, previous_page)}
            style={buttonStyle(previous_page == null)}
          >
            {icons.paginationPrevious}
          </button>
        </li>
        <li>
          <div
            style={{
              border: "2px solid #3C7EF3",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <span>{_currentPage}</span>
          </div>
        </li>
        <li>
          <div className="d-flex align-items-center">
            <span>{icons.paginationBackwardSlash}</span>
            {totalPages}
          </div>
        </li>
        <li>
          <button
            onClick={(e) => handleChangePage(e, next_page)}
            style={buttonStyle(next_page == null)}
          >
            {icons.paginationNext}
          </button>
        </li>
        <li style={{ color: "#9daac1", marginLeft: 20 }}>
          Total Outcome : {total}
        </li>
      </ul>
    </div>
  );
};

export default PaginationCallOutCome;
