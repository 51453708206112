import { InputAdornment } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  deleteCallOutcome,
  getCallOutcomeList,
} from "../../../../api/profileApi";
import Utils from "../../../../helpers/Utils";
import { useIsMounted } from "../../../../hooks/IsMounted";
import { BasicInput } from "../../../products/productsStyle";
import CustomDeleteModal from "../../../settings/CustomDeleteModal";
import "../lead_source.css";
import CallOutcomePopup from "./CallOutcomePopup";
import CallOutcomesSkeleton from "./CallOutcomesSkeleton";
import CallOutcomeTable from "./CallOutcomeTable";
import { modalStyles } from "./modalStyles";
import PaginationCallOutCome from "./PaginationCallOutCome";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../constants/PermissionConst";

const CallOutcomeInsurance = () => {
  const classes = modalStyles();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [editCallOutcome, setEditCallCome] = useState(null);
  const description =
    "You are attempting to delete a global call outcome disposition. This change will be applied to all users on your team.";
  const DeleteModalImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`;
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

  const [apiState, setApiState] = useState({
    page: 1,
    per_page: 10,
    sort_by: "outcome",
    sort_type: "asc",
    query: "",
  });

  useEffect(() => {
    Utils.scrollToTop();
  }, []);

  useEffect(() => {
    loadData();
  }, [apiState]); // eslint-disable-line react-hooks/exhaustive-deps

  // debouncing
  useEffect(() => {
    if (isMounted) {
      const timer = setTimeout(() => {
        setApiState((prevState) => ({
          ...prevState,
          query: query,
        }));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideModal = (isReset) => {
    if (isReset) {
      setApiState((prev) => ({ ...prev }));
    }
    setEditCallCome(null);
    setModalVisible(false);
  };

  const deleteData = (_id) => {
    setLoading(true);
    deleteCallOutcome({
      form_data: {
        id: _id,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          setData((prevData) => {
            const newData = prevData.filter((item) => item.id !== _id);
            setTotal(total - 1);
            return newData;
          });
          window.showNotification("SUCCESS", response.data.message);
        } else {
          console.error(
            "Failed to delete call disposition:",
            response.data.message
          );
          window.showNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error deleting call disposition:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = (item) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <CustomDeleteModal
          confirmationText={item.outcome}
          title="Notice"
          onClose={onClose}
          handleConfirm={() => deleteData(item.id)}
          closeButtonText="Cancel"
          confirmButtonText="Delete"
          showConfirmButton={true}
          showCloseButton={true}
          description={description}
          placeholder="Type call disposition name"
          imgSrc={DeleteModalImg}
        />
      ),
      closeOnClickOutside: false,
    });
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await getCallOutcomeList(apiState);
      if (response.data.status === "success") {
        setData(response.data.data.data);
        setTotal(response.data.data.total);
      } else {
        throw new Error(
          response.data.message || "Failed to load call disposition"
        );
      }
    } catch (error) {
      console.error("Error loading call disposition:", error);
      window.showNotification(
        "error",
        error.message || "Failed to load call disposition"
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePerPageChange = (event) => {
    setApiState((prevState) => ({
      ...prevState,
      per_page: event.target.value,
      page: 0,
    }));
  };

  return (
    <div className="settings_section">
      <div className="settings_content">
        <div id="call_outcome" className="col s12 active">
          <div className="heading">
            <div className={classes.actions}>
              <div className={classes.imgContainer}>
                <img src={DeleteModalImg} alt="logo" className={classes.img} />
              </div>
              <div>
                <div style={{ fontSize: "20px", fontWeight: 600 }}>
                  Call Dispositions
                </div>
                <div style={{}}>
                  Record and categorize the outcome of your phone calls using
                  codes or labels
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              {isUserHasPermission && (
                <button
                  type="submit"
                  className={`${classes.button} ${classes.addButton}`}
                  onClick={() => setModalVisible(true)}
                >
                  <div className={classes.actions}>
                      <AddIcon />
                    <span>New Disposition</span>
                  </div>
                </button>
              )}
              <div className={classes.backOption}>
                <Link to="/user/settings">
                  <div className={classes.actions}>
                    <span style={{ display: "flex" }}>
                      <ArrowBackIcon style={{ color: "#fff" }} />
                    </span>
                    <span className={classes.text}>Back</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <form action="#" method="POST">
            <div className="search_form">
              <div className="form_group" style={{ height: 40 }}>
                <span>Show</span>
                <div className="input-field col s12">
                  <select
                    style={{
                      display: "block",
                      height: 35,
                      width: 60,
                      textAlign: "center",
                    }}
                    value={apiState.per_page}
                    onChange={handlePerPageChange}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <p>Records</p>
              </div>

              <div className="">
                <BasicInput
                  value={query}
                  placeholder="Search call disposition name"
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon style={{ color: "#656565" }} />
                    </InputAdornment>
                  }
                  style={{ width: "300px" }}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </form>
          <div style={{ minHeight: "48vh" }}>
            {loading ? (
              <CallOutcomesSkeleton />
            ) : (
              <CallOutcomeTable
                loading={loading}
                data={data}
                onEdit={(item) => {
                  setEditCallCome(item);
                  setModalVisible(true);
                }}
                onDelete={handleDelete}
              />
            )}
          </div>

          <PaginationCallOutCome
            currentPage={apiState.page}
            total={total}
            perPage={apiState.per_page}
            onPageChange={(newPage) => {
              setApiState((prevState) => ({
                ...prevState,
                page: newPage + 1,
              }));
            }}
          />
        </div>

        {modalVisible && (
          <CallOutcomePopup
            hideModal={hideModal}
            title={"Add Call Outcome"}
            editCallOutcome={editCallOutcome}
            modalVisible={modalVisible}
            onClose={() => {
              setModalVisible(false);
              setEditCallCome(null);
            }}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

export default CallOutcomeInsurance;
