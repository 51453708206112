import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { renderFolderIcon } from "../../products/helper/functions";
import CreateNewFolderModal from "./modal/CreateNewFolderModal";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAlertV3 from "../../common/confirm-alert/ConfirmAlertV3";
import { deleteUserMergeFieldFolder, handleSelectedUserMergeFieldFolder } from "../../../actions/CustomFieldInsuranceAction";
import { customFieldInsuranceState } from "../../products/common/productSelector";



const PopoverStyles = styled(Popover)(({ theme }) => ({
  boxShadow:
    "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  "& .popoverWrapper": {
    padding: theme.spacing(1),
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  },

  "& .popoverList": {
    padding: 0,
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .icon": { color: "#1d2939" },
    "& .text": { color: "#1d2939", fontWeight: 600 },
  },
}));

const useStyles = makeStyles({
  folderTitle: {
    width: "85%",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const EachFolderItem = ({ folder }) => {
  const { userMergeFieldParams  } = useSelector(customFieldInsuranceState);
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const handleSelectedFolder = () => {
      dispatch(handleSelectedUserMergeFieldFolder(folder.id));
  };

  const handleEditCategory = () => {
    handlePopoverClose();
    setOpenAddFolderModal(true);
  };

  const confirmDeleteCategory = () => {
    dispatch(deleteUserMergeFieldFolder({id: folder.id}));
  };

  const handleDeleteCategory = () => {
    handlePopoverClose();
    ConfirmAlertV3({
      handleConfirm: () => confirmDeleteCategory(),
      title: "Are you sure you want to delete this group?",
      description: "All custom fields within this group will be removed. \n This action cannot be undone.",
    });
  };
  // Popover
  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;
  const { folderTitle } = useStyles();
  return (
    <>
      <BootstrapTooltip
        title={`${folder?.title.length > 26 ? folder.title : ""}`}
      >
        <ListItem
          button
          selected={userMergeFieldParams.selectedUserMergeFieldFolder === folder.id}
          onClick={handleSelectedFolder}
        >
          <ListItemIcon>
            {renderFolderIcon(folder.folder_icon, folder.folder_color, 20)}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" className={folderTitle}>
              {folder.title}
            </Typography>
          </ListItemText>

          <ListItemSecondaryAction>
            <IconButton size="small" onClick={handlePopover}>
              <MoreVertIcon style={{ color: "#949db2" }} />
            </IconButton>

            <PopoverStyles
              id={popoverId}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              elevation={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className="popoverWrapper">
                <List className="popoverList" component="div" dense>
                  <ListItem onClick={handleEditCategory} button>
                    <ListItemIcon>
                      <EditIcon
                        fontSize="small"
                        className="icon"
                        style={{ color: "#1d2939" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        className="text"
                        style={{
                          color: "#1d2939",
                          textAlign: "left",
                        }}
                      >
                        Edit
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    onClick={handleDeleteCategory}
                  >
                    <ListItemIcon>
                      <DeleteIcon
                        fontSize="small"
                        style={{ color: "var(--red)" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        color={"text.primary"}
                        style={{
                          color: "var(--red)",
                          textAlign: "left",
                        }}
                      >
                        Delete
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            </PopoverStyles>
          </ListItemSecondaryAction>
        </ListItem>
      </BootstrapTooltip>
      {openAddFolderModal && (
        <CreateNewFolderModal
          open={openAddFolderModal}
          onClose={() => setOpenAddFolderModal(false)}
          edit={folder}
        />
      )}
    </>
  );
};

export default EachFolderItem;
