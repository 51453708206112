import { Box, InputAdornment, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../constants/PermissionConst";
import { InsuranceCompanyIcon } from "../../../ten-dlc/helpers/Icon";
import BasicSelect from "../../policyTypes/content/BasicSelect";
import AddOrEditNewCompanyModal from "./AddOrEditNewCompanyModal";
import { CustomButton } from "./contentBody/CustomButton";
import { BasicInput, insuranceCompanyStyle } from "./InsuranceCompanyStyle";
import SelectedCompaniesActions from "./SelectedCompaniesActions";

const INSURANCE_COMPANIES_STATUS = [
  {
    value: "ALL",
    label: "Show All",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

const InsuranceCompanyHeader = ({
  setInsuranceCompanyList,
  search,
  setSearch,
  folderList,
  handleFolderList,
  selectedFolder,
  setPage,
  selectedCompanies,
  setSelectedCompanies,
  insuranceCompanyList,
  setFilterTrigger,
  filterTrigger,
  status,
  setStatus,
}) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

  useEffect(() => {
    setStatus("");
  }, [selectedFolder]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseModal = () => {
    setOpenAddModal(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(0);
  };

  const handleFilterStatus = (e) => {
    setStatus(e.target.value);
    setFilterTrigger(filterTrigger + 1);
  };

  const { topbarHeaderTitle, productBodySubTitle } = insuranceCompanyStyle();

  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div style={{ marginRight: "5px" }}>
            <InsuranceCompanyIcon />
          </div>
          <div>
            <Typography
              variant="h5"
              style={{ height: "unset !important" }}
              className={topbarHeaderTitle}
            >
              Insurance Companies
            </Typography>
            <Typography
              style={{ height: "unset !important" }}
              variant="body2"
              className={productBodySubTitle}
            >
              In this section you’ll select the insurance companies that you
              represent in your agency. In the status column, click the toggle
              button “on” for all the companies you represent.
            </Typography>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection:'column',
            alignItems: "flex-end",
            gap: 8,
            justifyContent: "flex-end",
          }}
        >
           { isUserHasPermission && (
            <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
            <CustomButton
              variant="contained"
              onClick={() => setOpenAddModal(true)}
              startIcon={<AddIcon />}
              disableElevation
              style={{ width: "170px", fontSize: 14, paddingBlock: 5 }}
            >
              New Company
            </CustomButton>
          </Box>
        )}
        <div style={{display:'flex', alignItems:'center', gap:'8px'}}>
        {( isUserHasPermission && selectedCompanies && selectedCompanies[0]) ? (
            <SelectedCompaniesActions
              setInsuranceCompanyList={setInsuranceCompanyList}
              folderList={folderList}
              handleFolderList={handleFolderList}
              selectedCompanies={selectedCompanies}
              insuranceCompanyList={insuranceCompanyList}
              setSelectedCompanies={setSelectedCompanies}
              setFilterTrigger={setFilterTrigger}
              filterTrigger={filterTrigger}
            />
          ) : (
            <BasicInput
              value={search}
              placeholder="Search for existing company"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "#656565" }} />
                </InputAdornment>
              }
              style={{ width: "275px" }}
              onChange={handleSearch}
            />
          )}
          <div style={{ width: "145px" }}>
            <BasicSelect
              options={INSURANCE_COMPANIES_STATUS}
              defaultText="Filter By Status"
              mapping={{
                value: "value",
                label: "label",
              }}
              value={status}
              onChange={handleFilterStatus}
              style={{ width: "145px" }}
            />
          </div>
        </div>
        </Box>
      </Box>

      {openAddModal && (
        <AddOrEditNewCompanyModal
          open={openAddModal}
          onClose={handleCloseModal}
          setInsuranceCompanyList={setInsuranceCompanyList}
          folderList={folderList}
          handleFolderList={handleFolderList}
          selectedFolder={selectedFolder}
        />
      )}
    </div>
  );
};

export default InsuranceCompanyHeader;
