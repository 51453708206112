import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  makeStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PersonIcon from "@material-ui/icons/Person";
import SwitchC from "../customField/subComponents/Switch";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import AddNewUserModal from "./AddNewUserModal";
import {useDispatch, useSelector} from "react-redux";
import {selectManageUserState} from "../../reducers/manageUser/manageUserSelector";
import {
  deactivateUser,
  activateUser,
  forceLogin,
  deleteUserWithoutTransfer
} from "../../reducers/manageUser/manageUserSlice";
import {confirmAlert} from "react-confirm-alert";
import ActionConfirmationModal from "../team/ActionConfirmationModal";
import ConfirmAlertV3 from "../common/confirm-alert/ConfirmAlertV3";

const useStyles = makeStyles((theme) => ({
  tableRowWrapper: {
    color: theme.palette.text.primary,

    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,

      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,

        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },

    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },

    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
    },

    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
  },
  description:{
    textAlign: 'center',
    padding: '4px',
    fontSize:"16px"
  },
  customOverlay: {
    backgroundColor: '#54545480',
  },
}));

const roleMapper = {
  "User Admin": "Standard User",
  "User Agent": "Limited User",
};

const EachProductTableItem = ({ user, setTransferDataDrawer }) => {
  const dispatch = useDispatch();
  const { subUsers } = useSelector(selectManageUserState);
  const {
    id,
    full_name,
    email,
    status,
    name: permissionLevel,
    collaborator_id,
    created_at,
  } = user || {};
  const [userStatus, setUserStatus] = useState(status === 1);
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUserStatus = (state) => {
    if(!state){
      confirmAlert({
        customUI: ({ onClose }) => (
            <ActionConfirmationModal
                title="Notice: User Status Update"
                handleDelete={() => {
                  ConfirmAlertV3({
                    handleConfirm: () => {
                      ConfirmAlertV3({
                        handleConfirm: () => {

                        },
                        handleClose: () => {
                          dispatch(deleteUserWithoutTransfer({ user_id: id }));
                        },
                        title: "Delete User",
                        description: "Do you want to transfer the contacts to other users? If you click No, all contacts of the user will be deleted permanently.",
                        cancelText: "Delete",
                        submitText: "Transfer",
                        imgSrc: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/conatct_source/1.0.0/contacts_icon.png`
                      });
                    },
                    callConfirmLast: true,
                    title: "Delete User",
                    description: "Do you want to delete this user? You cannot restore user information again.",
                    typeConfirm: "DELETE USER",
                    cancelText: "Cancel",
                    submitText: "Confirm",
                    imgSrc: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/conatct_source/1.0.0/contacts_icon.png`
                  });
                }}
                handleConfirm={() => {
                  dispatch(deactivateUser({ user_id: id }));
                  setUserStatus(false);
                  onClose();
                }}
                onClose={onClose}
                closeButtonText="Delete User"
                confirmButtonText="Turn User Status 'OFF'"
                showConfirmButton={true}
                showCloseButton={true}
                description={"When you turn the user status to 'OFF' this removes access for that particular user. This inactive user will still count towards your teams total user count."}
                imgSrc={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/conatct_source/1.0.0/contacts_icon.png`}
            >
              <div className={classes.description}>
                To completely remove a user and free up a spot, you'll need to
                delete their account.
              </div>
              <div className={classes.description}>
                <span style={{fontWeight:600}}> Note:</span> When you turn the user status to "off" or delete a user,
                we release their number back to the phone company.
              </div>
            </ActionConfirmationModal>
        ),
        closeOnClickOutside: true,
        overlayClassName: classes.customOverlay,
      });
    }else {
      dispatch(activateUser({ user_id: id }));
      setUserStatus(true);
    }
  };

  const handleDelete = () => {
    ConfirmAlertV3({
      handleConfirm: () => {
        ConfirmAlertV3({
          handleConfirm: () => {

          },
          handleClose: () => {
            dispatch(deleteUserWithoutTransfer({ user_id: id }));
          },
          title: "Are you sure?",
          description: "Do you want to transfer the contacts to other users? If you click No, all contacts of the user will be deleted permanently.",
          cancelText: "No, Delete all",
          submitText: "Yes, Transfer",
          imgSrc: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/conatct_source/1.0.0/contacts_icon.png`
        });
      },
      callConfirmLast: true,
      title: "Are you sure?",
      description: "Do you want to delete this user? You cannot restore user information again.",
      typeConfirm: "DELETE USER",
      cancelText: "Cancel",
      submitText: "Confirm",
      imgSrc: `${process.env.REACT_APP_CDN_LINK}assets/images/releases/conatct_source/1.0.0/contacts_icon.png`
    });
  };

  const getCollaboratorName = () => {
    try{
      const collaborator = subUsers.find((item) => item.id === Number(collaborator_id));

      return collaborator ? collaborator.name : "";
    }catch (err){
      return "";
    }
  };

  const handleForceLogin = () => {
    dispatch(forceLogin({ id: id }));
  };

  return (
    <TableRow className={classes.tableRowWrapper}>
      <TableCell component="th" scope="row">
        <div className="titleWrap">{full_name}</div>
      </TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="center">
        <SwitchC
          status={userStatus}
          brandColor={"#3f51b5"}
          onChange={handleUserStatus}
          onText="On"
          offText="Off"
        />
      </TableCell>
      <TableCell align="center">{roleMapper[permissionLevel]}</TableCell>
      <TableCell align="center">{getCollaboratorName()}</TableCell>
      <TableCell align="center">{created_at}</TableCell>
      <TableCell align="center">
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <BootstrapTooltip title="Edit User" placement="top">
            <IconButton size="small" onClick={() => setIsModalOpen(true)}>
              <EditIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Load Quick Clone" placement="top">
            <IconButton size="small">
              <FileCopyIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Force Login" placement="top">
            <IconButton size="small" onClick={handleForceLogin}>
              <PersonIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Delete" placement="top">
            <IconButton size="small" onClick={handleDelete}>
              <DeleteIcon style={{ color: "rgb(255, 38, 74)" }} />
            </IconButton>
          </BootstrapTooltip>
        </div>
      </TableCell>

      {isModalOpen && (
          <AddNewUserModal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              userId={id}
              collaboratorId={collaborator_id}
          />
      )}
    </TableRow>
  );
};

export default EachProductTableItem;
