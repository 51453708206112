import { Chip, IconButton, TableCell } from "@material-ui/core";
import React, { useState } from "react";
import BootstrapTooltip from "../../common/BootstrapTooltip";
import {
  AGENCY_BUSINESS_TYPE,
  PERMISSION_LEVELS,
  PERMISSION_MODULES,
  PRIVACY_LEVEL,
} from "../../../constants/CoreConstants";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../products/common/productSelector";
import Utils from "../../../helpers/Utils";
import { EachRowItemStyle } from "./EachRowItemStyle";
import ConfirmAlertV3 from "../../common/confirm-alert/ConfirmAlertV3";
import CustomDrawerPype from "../../common/drawer/CustomDrawerPype";
import AddCustomField from "./modal/add-field/AddCustomField";

const EachContantItem = ({ field }) => {
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const isAgencyTypeInsurance =
    Utils.getAccountData("agencyBusinessType") ===
    AGENCY_BUSINESS_TYPE.INSURANCE;
  const canUserManageGlobalItem = window.hasPermission(
    PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,
    PERMISSION_LEVELS.WRITE
  );
  const isCustomFieldPrivate = field.privacy_level === PRIVACY_LEVEL.PRIVATE;

  const handleEdit = () => {
    setIsEditDrawerOpen(true);
  };
  const confirmDelete = () => {
    const payload = {
      id: field.id,
    };
    // dispatch(
    //   deleteCustomFieldInsurance({
    //     ...payload,
    //     callback: () => {
    //        dispatch(handleSelectedCustomFieldFolder(fieldsObject.selectedFolder));
    //     },
    //   })
    // );
  };

  const handleDelete = () => {
    ConfirmAlertV3({
      handleConfirm: () => confirmDelete(),
      title: "Are you sure you want to delete this group?",
      description: "This action cannot be undone.",
    });
  };

  return (
    <EachRowItemStyle>
      <TableCell style={{ width: "25%" }}>
        <div className="titleWrap">{field.title}</div>
      </TableCell>
      <TableCell style={{ width: "25%" }}>
        <div className="titleWrap">{field.personalize_tag}</div>
      </TableCell>

      <TableCell style={{ width: "20%" }}>
        <div className="titleWrap">{field.field_value}</div>
      </TableCell>

      <TableCell>
        <Chip
          size="small"
          label={isCustomFieldPrivate ? "My Field" : "Global Field"}
          className={isCustomFieldPrivate ? "privateChip" : "globalChip"}
        />
      </TableCell>

      <TableCell style={{ width: "75px", display: "flex", alignItems:'center', gap:'6px' }}>
        <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
          <IconButton size="small" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </BootstrapTooltip>

        <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
          <IconButton size="small" onClick={handleDelete}>
            <DeleteOutlineIcon />
          </IconButton>
        </BootstrapTooltip>
      </TableCell>

      {isEditDrawerOpen && (
        <CustomDrawerPype
          isShow={isEditDrawerOpen}
          hanldeDrawer={(status) => {}}
          renderChild={() => {
            return (
              <AddCustomField
                title="Edit Personalized Field "
                onClose={() => setIsEditDrawerOpen(false)}
                edit={field}
              />
            );
          }}
        />
      )}
    </EachRowItemStyle>
  );
};

export default EachContantItem;
