import React, {useEffect, useState} from "react";
import { CustomScrollbar } from "../products/productsStyle";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import EachProductTableItem from "./EachTableItem";
import TransferDataPopup from "../team/TransferDataPopup";
import {useDispatch, useSelector} from "react-redux";
import {getSubUserFormData, getUsers} from "../../reducers/manageUser/manageUserSlice";
import {selectManageUserState} from "../../reducers/manageUser/manageUserSelector";
import useDebounce from "../../hooks/useDebounce";
import {SearchIcon} from "./Icons";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  tableBodyHeight: {
    maxHeight: `calc(100vh - 355px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});

const SettingsTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { users, count, page, perPage } = useSelector(selectManageUserState);
  const [transferDataDrawer, setTransferDataDrawer] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);
  const [getUserPayload, setGetUserPayload] = useState({
    page: 1,
    per_page: 10,
    query: "",
    sort_by: "full_name",
    sort_type: "asc"
  });

  useEffect(() => {
    dispatch(getSubUserFormData());
  }, []);

  useEffect(() => {
    dispatch(getUsers(getUserPayload));
  },[getUserPayload]);

  useDidUpdateEffect(() => {
    setGetUserPayload((prevState)=>({
      ...prevState,
      query: debouncedSearch,
      page: 1,
      per_page: 10,
      sort_by: "full_name",
      sort_type: "asc"
    }));
  },[debouncedSearch]);

  const handleChangePage = (event, newPage) => {
    setGetUserPayload((prevState)=>({
      ...prevState,
      page: newPage + 1
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setGetUserPayload((prevState)=>({
      ...prevState,
      page: 1,
      per_page: event.target.value,
    }));
  };

  return (
      <>
        <div className="record_search">
          <input
              type="text"
              placeholder="Search user"
              className="search"
              onChange={(e) =>{
                setSearch(e.target.value);
              }}
          />
          <div className="search_icon">
            <SearchIcon/>
          </div>
        </div>
        <CustomScrollbar className={`${classes.tableBodyHeight}`}>
          <TableContainer component={"span"}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeader} width={"25%"}>
                    Full Name
                  </TableCell>
                  <TableCell className={classes.tableHeader}>Email</TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Status
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Permission Level
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Collaborator
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Created
                  </TableCell>
                  <TableCell align={"center"} className={classes.tableHeader}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((user) => (
                    <EachProductTableItem
                        key={user.id}
                        user={user}
                        setTransferDataDrawer={() => {
                          setTransferDataDrawer(true);
                        }}
                    />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CustomScrollbar>

        {transferDataDrawer && (
            <TransferDataPopup
                hideModal={() => {
                  // setReset(reset + 1);
                  // setDataToEdit(null);
                  setTransferDataDrawer(false);
                }}
                title={"Transfer Data"}
                // user={props.user}
                // data={dataToEdit}
            />
        )}

        <TablePagination
            rowsPerPageOptions={[2, 10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={perPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
  );
};

export default SettingsTable;
