import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import TagsTable from "./TagsTable";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),

    "& .record_search": {
      display: "flex",
      justifyContent: "flex-end",
      "& .search": {
        margin: 0,
        "&:fucus": {
          boxShadow: "none !important",
        },
      },

      "& .search_icon": {
        right: 12,
        top: 12,
      },
    },
  },
}));

const MainContent = ({taglist, setTagList, privacyLevel, setPrivacyLevel}) => {
  const [reset, setReset] = useState(0);
  const classes = useStyles();
  const handleTabChange = (event, newValue) => {
   setPrivacyLevel(newValue);
   setReset(reset+1);
 };
  return (
    <div className={classes.mainContent}>
      <Tabs
        value={privacyLevel}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab disableRipple label="Global Tags" style={{textTransform: 'capitalize'}} />
        <Tab disableRipple label="My Tags" style={{textTransform: 'capitalize'}} />
      </Tabs>

      <TagsTable reset={reset} setReset={setReset} privacyLevel={privacyLevel} taglist={taglist} setTagList={setTagList} />
    </div>
  );
};

export default MainContent;
