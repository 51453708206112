import React from "react";
import { useStyles } from "./ActionConfirmationModal.styles";

const ActionConfirmationModal = ({
    onClose,
    handleDelete,
    handleConfirm,
    closeButtonText = "Close",
    confirmButtonText = "Confirm",
    showConfirmButton = true,
    showCloseButton = true,
    description = "",
    imgSrc = "",
    title = "",
    children,
}) => {
    const classes = useStyles();
    const handleSubmit = () => {
        onClose();
        handleConfirm();
    };

    const handleClose = () => {
        handleDelete();
    };

    return (
        <div className={classes.modalContainer}>
            <div className={classes.header}>
                {imgSrc && (
                    <div className={classes.logoContainer}>
                        <img src={imgSrc} alt="logo" className={classes.logo} />
                    </div>
                )}
                {title && <span className={classes.title}>{title}</span>}
            </div>
            <div className={classes.content}>
                {description && (
                    <div className={classes.description}>{description}</div>
                )}
                <div className={classes.modalBody}>{children}</div>
                <div className={classes.actions}>
                    {showConfirmButton && (
                        <button
                            onClick={handleSubmit}
                            className={`${classes.button} ${classes.closeButton}`}
                        >
                            {confirmButtonText}
                        </button>
                    )}
                    {showCloseButton && (
                        <button
                            onClick={handleClose}
                            className={`${classes.button} ${classes.deleteButton}`}
                        >
                            {closeButtonText}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ActionConfirmationModal;