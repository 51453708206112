import { Button } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { getGeneralSettings, updateGeneralSettings } from "../../api/profileApi";
import SwitchC from "../customField/subComponents/Switch";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import GenaralSettingSkeleton from "./Skeleton/GenaralSettingSkeleton";
import Utils from "../../helpers/Utils";
import { AGENCY_BUSINESS_TYPE } from "../../constants/CoreConstants";


const defaultValues = {
    topBarBackground: '#FFFFFF',
    topBarTextColor: '#555555',
    sideBarBackground: '#FFFFFF',
    sideBarTextColor: '#000000',
    sideBarIconColor: '#000000',
    sideBarHoverColor: '#A3C5FF',
    sidebarAddNewButtonBackground: '#6070ed',
    sidebarAddNewButtonTextColor: '#FFFFFF',
    accentColor: '#6070ed',
    accentTextColor: '#FFFFFF',
}

const ResetToDefaultIcon = ({ onClick }) => {
    return <BootstrapTooltip arrow placement="right" title="Reset to default">
        <svg className="cursor-pointer" width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <path d="M13.1461 0.801229C13.037 0.755849 12.9113 0.780947 12.828 0.864557L11.6146 2.078C10.3126 0.737901 8.57309 0 6.71615 0C6.32334 0 5.92718 0.0342904 5.53889 0.101704C2.8959 0.56105 0.754427 2.53968 0.0835012 5.14269C0.0608841 5.2301 0.0801451 5.3229 0.135302 5.39425C0.190604 5.4656 0.275674 5.50734 0.365996 5.50734H2.22468C2.34711 5.50734 2.45669 5.43088 2.49872 5.31575C3.04284 3.82842 4.39447 2.69348 5.94206 2.42455C6.19698 2.38034 6.45642 2.35802 6.71367 2.35802C7.94345 2.35802 9.09197 2.85034 9.94835 3.74422L8.71608 4.97635C8.63277 5.05981 8.60781 5.1853 8.65276 5.2943C8.69814 5.40344 8.80436 5.47451 8.92241 5.47451H13.0345C13.1956 5.47451 13.3263 5.34376 13.3263 5.18267V1.07074C13.3263 0.952837 13.255 0.846463 13.1461 0.801229Z" fill="#3C7EF3"></path>
            <path d="M12.96 8.21875H11.1015C10.9791 8.21875 10.8695 8.29506 10.8275 8.41019C10.2833 9.89752 8.93171 11.0326 7.38412 11.3014C7.12906 11.3456 6.86976 11.3679 6.61251 11.3679C5.38272 11.3679 4.23407 10.8756 3.37783 9.98172L4.60995 8.7496C4.69341 8.66613 4.71836 8.54064 4.67328 8.43164C4.62804 8.3225 4.52167 8.25144 4.40362 8.25144H0.291834C0.130741 8.25144 0 8.38218 0 8.54327V12.6554C0 12.7734 0.0710615 12.8798 0.180207 12.925C0.289061 12.9702 0.414695 12.9451 0.49816 12.8615L1.7116 11.6482C3.01347 12.9882 4.75295 13.7262 6.60988 13.7262C7.00284 13.7262 7.399 13.6919 7.78729 13.6245C10.4301 13.1655 12.5718 11.1866 13.2427 8.58354C13.2653 8.49599 13.246 8.40348 13.191 8.33184C13.1356 8.26063 13.0504 8.21875 12.96 8.21875Z" fill="#3C7EF3"></path>
        </svg>
    </BootstrapTooltip>
}

const General = (props) => {

    const [componentLoading, setComponentLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [shortMenu, setShortMenu] = useState(false);
    const [topBarBackground, setTopBarBackground] = useState(defaultValues.topBarBackground);
    const [topBarTextColor, setTopBarTextColor] = useState(defaultValues.topBarTextColor);
    const [sideBarBackground, setSideBarBackground] = useState(defaultValues.sideBarBackground);
    const [sideBarTextColor, setSideBarTextColor] = useState(defaultValues.sideBarTextColor);
    const [sideBarIconColor, setSideBarIconColor] = useState(defaultValues.sideBarIconColor);
    const [sideBarHoverColor, setSideBarHoverColor] = useState(defaultValues.sideBarHoverColor);
    const [sidebarAddNewButtonBackground, setSidebarAddNewButtonBackground] = useState(defaultValues.sidebarAddNewButtonBackground);
    const [sidebarAddNewButtonTextColor, setSidebarAddNewButtonTextColor] = useState(defaultValues.sidebarAddNewButtonTextColor);
    const [accentColor, setAccentColor] = useState(defaultValues.accentColor);
    const [accentTextColor, setAccentTextColor] = useState(defaultValues.accentTextColor);
    const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
    const userIsAgent = Utils.getAccountData("userIsAgent"); 

    
    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        getGeneralSettings({})
        .then((response) => {
            setShortMenu(response.data.data.shortMenu);
            setTopBarBackground(response.data.data.topBarBackground);
            setTopBarTextColor(response.data.data.topBarTextColor);
            setSideBarBackground(response.data.data.sideBarBackground);
            setSideBarTextColor(response.data.data.sideBarTextColor);
            setSideBarIconColor(response.data.data.sideBarIconColor);
            setSideBarHoverColor(response.data.data.sideBarHoverColor);
            setSidebarAddNewButtonBackground(response.data.data.sidebarAddNewButtonBackground);
            setSidebarAddNewButtonTextColor(response.data.data.sidebarAddNewButtonTextColor);
            setAccentColor(response.data.data.accentColor);
            setAccentTextColor(response.data.data.accentTextColor);
        }).finally(() => {
            setComponentLoading(false);
        })
    }

    const submitData = () => {
        setLoading(true);

        updateGeneralSettings({
            "form_data": {
                shortMenu: shortMenu,
                topBarBackground: topBarBackground,
                topBarTextColor: topBarTextColor,
                sideBarBackground: sideBarBackground,
                sideBarTextColor: sideBarTextColor,
                sideBarIconColor: sideBarIconColor,
                sideBarHoverColor: sideBarHoverColor,
                sidebarAddNewButtonBackground: sidebarAddNewButtonBackground,
                sidebarAddNewButtonTextColor: sidebarAddNewButtonTextColor,
                accentColor: accentColor,
                accentTextColor: accentTextColor,
            }
        }).then((response) => {
          window.showNotification("SUCCESS", response.data.message)
          if(window.globalFetchAccountData) {
              window.globalFetchAccountData();
          }
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Fragment>
            {
                componentLoading ? (
                    <div style={{paddingTop: 50}}>
                        <GenaralSettingSkeleton/>
                    </div>
                ) : (
                    <Fragment>
                        <div id="g_setting" class="col s12 active">
                            <div class="row">
                                <div class="col s6">
                                    <div class="color_plate">
                                        <ul>
                                            <li>
                                                <h5>Collapsed Menu</h5>
                                                <SwitchC 
                                                    status={shortMenu} 
                                                    brandColor={"#f94f72"} 
                                                    onChange={() => setShortMenu(!shortMenu)}
                                                />
                                            </li>
                                            <li>
                                                <h5>Topbar Background</h5>
                                                <div class="color_option">
                                                    <input type="color" value={topBarBackground} onChange={(e) => {
                                                        setTopBarBackground(e.target.value);
                                                    }} style={{backgroundColor: topBarBackground}}/>
                                                    <ResetToDefaultIcon onClick={() => setTopBarBackground(defaultValues.topBarBackground)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>Topbar Text Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={topBarTextColor} onChange={(e) => {
                                                        setTopBarTextColor(e.target.value);
                                                    }} style={{backgroundColor: topBarTextColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setTopBarTextColor(defaultValues.topBarTextColor)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>Sidebar Background</h5>
                                                <div class="color_option">
                                                    <input type="color" value={sideBarBackground} onChange={(e) => {
                                                        setSideBarBackground(e.target.value);
                                                    }} style={{backgroundColor: sideBarBackground}}/>
                                                    <ResetToDefaultIcon onClick={() => setSideBarBackground(defaultValues.sideBarBackground)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>Sidebar Text Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={sideBarTextColor} onChange={(e) => {
                                                        setSideBarTextColor(e.target.value);
                                                    }} style={{backgroundColor: sideBarTextColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setSideBarTextColor(defaultValues.sideBarTextColor)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>Sidebar Icon Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={sideBarIconColor} onChange={(e) => {
                                                        setSideBarIconColor(e.target.value);
                                                    }} style={{backgroundColor: sideBarIconColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setSideBarIconColor(defaultValues.sideBarIconColor)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>Sidebar Hover Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={sideBarHoverColor} onChange={(e) => {
                                                        setSideBarHoverColor(e.target.value);
                                                    }} style={{backgroundColor: sideBarHoverColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setSideBarHoverColor(defaultValues.sideBarHoverColor)}/>
                                                </div>
                                            </li>
                                            {/* <li>
                                                <h5>Sidebar Add New Button Background</h5>
                                                <div class="color_option">
                                                    <input type="color" value={sidebarAddNewButtonBackground} onChange={(e) => {
                                                        setSidebarAddNewButtonBackground(e.target.value);
                                                    }} style={{backgroundColor: sidebarAddNewButtonBackground}}/>
                                                    <ResetToDefaultIcon onClick={() => setSidebarAddNewButtonBackground(defaultValues.sidebarAddNewButtonBackground)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>Sidebar Add New Button  Text Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={sidebarAddNewButtonTextColor} onChange={(e) => {
                                                        setSidebarAddNewButtonTextColor(e.target.value);
                                                    }} style={{backgroundColor: sidebarAddNewButtonTextColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setSidebarAddNewButtonTextColor(defaultValues.sidebarAddNewButtonTextColor)}/>
                                                </div>
                                            </li>
                                            */}
                                            <li>
                                                <h5>System Wide Accent Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={accentColor} onChange={(e) => {
                                                        setAccentColor(e.target.value);
                                                    }} style={{backgroundColor: accentColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setAccentColor(defaultValues.accentColor)}/>
                                                </div>
                                            </li>
                                            <li>
                                                <h5>System Wide Accent Text Color</h5>
                                                <div class="color_option">
                                                    <input type="color" value={accentTextColor} onChange={(e) => {
                                                        setAccentTextColor(e.target.value);
                                                    }} style={{backgroundColor: accentTextColor}}/>
                                                    <ResetToDefaultIcon onClick={() => setAccentTextColor(defaultValues.accentTextColor)}/>
                                                </div>
                                            </li>

                                        </ul>
                                        <hr/>
                                        <div className="mt-3">
                                           { ((isAgencyTypeInsurance && !userIsAgent) || (!isAgencyTypeInsurance)) && ( 
                                            <Button variant="contained" className={"accent--bg--text--color"} disabled={loading} onClick={submitData}>
                                                {!loading ? 'Save Settings' : 'Saving...'}
                                            </Button>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment> 
                )
            }
        </Fragment>
    )
}

export default General;