import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  _handleDeleteLeadSourceModal,
  _handleLeadSourceEdit,
  _handleLeadSourceModal,
  _handleLeadSourcePagination,
  _handleLeadSourceReload,
  _handleLeadSourceSearch,
  addLeadSource,
  deleteLeadSource,
  editLeadSource,
  getAllLeadSource,
  getLeadSourceByPagination,
} from "../../../actions/leadSourceAction";
import Utils from "../../../helpers/Utils";
import { icons } from "./icon";
import "./lead_source.css";

import AddIcon from '@material-ui/icons/Add';
import PaginationLeadSrouce from "./modals/PaginationLeadSrouce";
import AddLeadSourceModal from "./modals/AddLeadSourceModal";
import SearchLeadSource from "./modals/SearchLeadSource";
import Table from "./Table";
import DeleteLeadSourceModal from "./modals/DeleteLeadSourceModal";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    gap: "10px",
    marginLeft: "16px",
    alignItems: "center",
  },
  headerText: {
    height: "unset !important",
    fontSize: "20px",
    fontWeight: 600,
    color: "#133159",
  },
  headerDescription: {
    height: "unset !important",
    fontSize: "14px",
    fontWeight: 400,
    color: "#133159",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  button: {
    padding: "8px 16px",
    border: "none",
    borderRadius: "20px",
    cursor: "default",
    "&:focus, &:hover": {
      backgroundColor: "#efefef",
    },
  },
  actionButton: {
    border: "none",
    cursor: "pointer",
    background: "transparent",
    "&:focus, &:hover": {
      backgroundColor: "transparent",
    },
  },
  text: {
    fontSize: "16px !important",
  },
  addLeadSourceButton: {
    padding: "12px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    background: "#466fef",
    "&:focus, &:hover": {
      backgroundColor: "#466fef",
    },
  },
  buttonContainer: {
    display: "flex",
    gap: "5px",
    justifyContent: "center",
    alignItems: "center",
    color:"white",
    textTransform: "uppercase",
    fontWeight:600
  }
});

const mapStateToProps = (state) => ({
  leadSourceReducer: state.leadSourceReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addLeadSource: (params, callback) =>
    dispatch(addLeadSource(params, callback)),
  editLeadSource: (params, callback) =>
    dispatch(editLeadSource(params, callback)),
  deleteLeadSource: (params, callback) =>
    dispatch(deleteLeadSource(params, callback)),
  getLeadSourceByPagination: (params, callback) =>
    dispatch(getLeadSourceByPagination(params, callback)),
  getAllLeadSource: (params, callback) =>
    dispatch(getAllLeadSource(params, callback)),
  _handleLeadSourceModal: (params) => dispatch(_handleLeadSourceModal(params)),
  _handleLeadSourceEdit: (params) => dispatch(_handleLeadSourceEdit(params)),
  _handleLeadSourceSearch: (params) =>
    dispatch(_handleLeadSourceSearch(params)),
  _handleLeadSourceReload: (params) =>
    dispatch(_handleLeadSourceReload(params)),
  _handleLeadSourcePagination: (params) =>
    dispatch(_handleLeadSourcePagination(params)),
  _handleDeleteLeadSourceModal: (params) =>
    dispatch(_handleDeleteLeadSourceModal(params)),
});

const LeadSourceApp = (props) => {
  const { show_modal, show_delete_modal } = props.leadSourceReducer;
  return (
    <div className="container-fluid mail__inbox">
      <Header />
      <div className="details">
        <SearchLeadSource />
        <Table />
      </div>
      <div style={{background:"#ffffff",padding:"20px 0px"}}>
        <PaginationLeadSrouce />
      </div>
        
      {show_modal && <AddLeadSourceModal />}
      {show_delete_modal && <DeleteLeadSourceModal />}
    </div>
  );
};
const LeadSourceV2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadSourceApp);
export default LeadSourceV2;

const HeaderApp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [openAddLeadSourceModal, setOpenAddLeadSourceModal] = useState(false);
  
  const handleAddLeadSourceModal = () => {
    setOpenAddLeadSourceModal(true);
  };
  useEffect(() => {
    document.title = `Lead Source | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("setting");
    }
  }, []);

  return (
    <>
      <div className="tagBox lead_source_page">
        <div>
          <div className={classes.headerContainer}>
            <div style={{ width: "60px", height: "60px" }}>
              <img
                src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/lead_sources_icon.png`}
                alt="logo"
                className={classes.img}
              />
            </div>
            <div>
              <div className={classes.headerText}>Lead Sources</div>
              <div className={classes.headerDescription}>
                Track where your business is coming from
              </div>
            </div>
          </div>
        </div>
        <div className="add_new_user ">
          {(Utils.getAccountData("userIsOwner")|| Utils.getAccountData("userIsAdmin")) && (
            
            <button
            className={`${classes.addLeadSourceButton} "modal-trigger accent--bg--text--color"`}
              onClick={handleAddLeadSourceModal}
            >
              <div className={classes.buttonContainer}><AddIcon/> <span style={{paddingTop:"2px"}}>Add New Lead Source</span></div>
            </button>
          )}
          {history.location.state && history.location.state.from === "settingPage" && (
              <Link
                to="/user/settings"
                className={"modal-trigger accent--bg--text--color"}
              >
                <span>{icons.backArrow}</span>
                <span>Back</span>
              </Link>
            )}
          {openAddLeadSourceModal && (
            <AddLeadSourceModal
              open={openAddLeadSourceModal}
              onClose={() => setOpenAddLeadSourceModal(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};
export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderApp);

