export const CalenderIcons = ({
  width = 32,
  height = 33,
  color = "#006DF5",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9999 0C18.6274 0 24 5.37242 24 11.9997C24 18.6272 18.6274 23.9996 11.9999 23.9996C5.37261 23.9998 0 18.6272 0 11.9997C0 5.37242 5.37261 0 11.9999 0ZM6.66127 13.2183L6.65834 13.2155C6.50503 13.0753 6.4226 12.8864 6.41303 12.6942C6.40346 12.5024 6.46674 12.3065 6.60522 12.1519C6.60717 12.1497 6.60893 12.1478 6.61088 12.1458C6.75092 11.9927 6.93979 11.9101 7.13197 11.9005C7.32514 11.8909 7.5222 11.9552 7.67728 12.0954L10.2077 14.3899L16.2798 8.03079C16.4243 7.87923 16.6163 7.80033 16.8108 7.79564C17.0045 7.79076 17.2003 7.8599 17.352 8.00404C17.5038 8.14837 17.5825 8.34095 17.5874 8.53509C17.5922 8.72903 17.5229 8.92512 17.3788 9.07668L10.7966 15.9704C10.7921 15.9751 10.7872 15.9796 10.7823 15.9835C10.6427 16.1231 10.4603 16.1973 10.2749 16.2042C10.0847 16.2114 9.89152 16.1469 9.73898 16.0089L6.66362 13.2202L6.66127 13.2183Z"
      fill="black"
    />
  </svg>
);
