import {
  Checkbox,
  Chip,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TableCell,
  TableRow,
  Typography,
  alpha,
  makeStyles,
  styled
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useState } from "react";
import { deleteInsuranceCompanyApi, insuranceCompanyStatusApi } from "../../../../../api/productsApi";
import { INSURANCE_COMPANIES_STATUS } from "../../../../../constants/CoreConstants";
import { AddIcon } from "../../../../customField/custom-field-v2/helpers/IconList";
import SwitchC from "../../../../customField/subComponents/Switch";
import CustomDeleteModal from "../../../../settings/CustomDeleteModal";
import { renderFolderIcon } from "../../../helper/functions";
import AddOrEditNewCompanyModal from "../AddOrEditNewCompanyModal";
import Utils from "../../../../../helpers/Utils";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../../constants/PermissionConst";
 
  
  const TableRowWrapper = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
      },
    color: theme.palette.text.primary,
  
    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      color:'var(--dark_blue)',
      fontSize:'15px'

    },
  
    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,
  
      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,
  
        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },
  
    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },
  
    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
      padding:'10px'
    },
  
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
  }));

  const useStyles = makeStyles((theme) => ({
    checbox: {
      color: 'rgba(0, 0, 0, 0.54) !important',
      '&.Mui-checked': {
        color: '#343fef !important',
      }
    },
  }));
  
  const PopoverStyles = styled(Popover)(({ theme }) => ({
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
    "& .popoverWrapper": {
      padding: theme.spacing(1),
      boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
    },
  
    "& .popoverList": {
      padding: 0,
      "& .MuiListItemIcon-root": {
        minWidth: 30,
      },
      "& .MuiListItem-button:hover": {
        borderRadius: theme.spacing(1),
      },
      "& .icon": { color: "#1d2939" },
      "& .iconDelete": { color: "#ff002c" },
      "& .text": { color: "#1d2939", fontWeight: 600, textAlign: "left" },
    },
  }));
  
  const EachInsuranceCompaniesTableItem = ({ 
    company,
    folderList,
    handleFolderList,
    selectedFolder,
    setInsuranceCompanyList,
    folderObject,
    onUpdateCompanyName,
    isChecked,
    onCheckboxChange,
 }) => {
    const { id, company_name, status, folders } = company || {};
    const [openAddModal, setOpenAddModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [insuranceStatus, setInsuranceStatus] = useState(
      status === INSURANCE_COMPANIES_STATUS.ACTIVE ? true : false
    );
    const classes = useStyles();
    const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

    const handleCheckboxChange = () => {
      onCheckboxChange(id);
    };
    const DescriptionForDelete =
      `You are attempting to delete a Insurance Company.\nThis change will be applied to all users on your team.`;
    const ImgSrcForDeleteModal = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/policy/1.0.0/policy_types_icon.png`;


    useEffect(()=>{
      setInsuranceStatus(status === INSURANCE_COMPANIES_STATUS.ACTIVE ? true : false)
    },[status])
  
    const handleInsuranceCompanyStatus = async(value) => {
      setInsuranceStatus(value);

      const payload = {
        "insurance_company_id": id,
        "status": value ? INSURANCE_COMPANIES_STATUS.ACTIVE : INSURANCE_COMPANIES_STATUS.INACTIVE 
      };

      try {
        const resp = await insuranceCompanyStatusApi(payload);
        if(resp.success){
          window.showNotification('success', resp.message);
        }
        else {
          window.showNotification('error', resp.message);
        }
      
      } catch (error) {
        window.showNotification('error', 'Something Went Wrong. Please Try Again');
      }
    
    };
  
    const handleEditInsuranceCompany = () => {
        setOpenAddModal(true);
      handlePopoverClose();
    };
    const handleDeleteModal = () => {
      setOpenDeleteModal(true);
      handlePopoverClose();
    };
    const handleDeleteModalClose = () => {
      setOpenDeleteModal(false);
      handlePopoverClose();
    };

    const handleConfirmDeletion = () => {
      handleDeletedCompany();
      setOpenDeleteModal(false);
    };

    const handleDeletedCompany = async () => {
        const newPayload = {
          insurance_company_id: id,
        };
        try {
          const response = await deleteInsuranceCompanyApi(newPayload);
    
          if (response?.success) {
            window.showNotification("SUCCESS", response.message);
    
            setInsuranceCompanyList((prevState) =>
              prevState.filter((company) => company.id !== id)
            );
          } else {
            window.showNotification("ERROR", response.data.message);
          }
        } catch (error) {}
    
      };
  
  
    const renderActionButton = () => {
          return (
            <>
              <ListItem onClick={handleEditInsuranceCompany} button>
                <ListItemIcon>
                  <EditIcon fontSize="small" className="icon" />
                </ListItemIcon>
                <ListItemText>
                  <Typography className="text">Edit</Typography>
                </ListItemText>
              </ListItem>
                <ListItem button onClick={handleDeleteModal}>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" className="iconDelete" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography fontSize="small" className="text">
                      Delete
                    </Typography>
                  </ListItemText>
                </ListItem>
            </>
          );
    };
  
    // Popover
    const handlePopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? "simple-popover" : undefined;
    const getFolderDetailsById = (folderId) => {
        if (folderObject) {
          return folderObject[folderId];
        }
        return {};
      };
    return (
      <TableRowWrapper>
        <TableCell component="th" scope="row">
          <div className="titleWrap">
            {isUserHasPermission && (
              <Checkbox
              size="small"
              checked={isChecked}
              onClick={handleCheckboxChange}
              className={classes.checbox}
            />
          )}
            {company_name || " "}
          </div>
        </TableCell>
        <TableCell align="center">
          {folders && folders[0] ? (
            folders.map((folderId) => {
              const folder = getFolderDetailsById(folderId);
              return (
                folder && (
                  <Chip
                    key={folder.id}
                    avatar={renderFolderIcon(
                      folder.folder_icon,
                      folder.folder_color,
                      18
                    )}
                    style={{
                      backgroundColor:
                        alpha(folder.folder_color, 0.12) || "grey",
                      color: folder.folder_color,
                      paddingLeft: 6,
                      marginRight: 4,
                      marginBottom: 4,
                    }}
                    size="small"
                    label={folder.title}
                  />
                )
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
             { isUserHasPermission &&  ( 
              <div
                onClick={() => setOpenAddModal(true)}
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#346FEF",
                  color: "grey",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
              </div>
            )}
            </div>
          )}
        </TableCell>
        <TableCell align="center">
          <SwitchC
            status={insuranceStatus}
            brandColor={"#346fef"}
            onChange={() => handleInsuranceCompanyStatus(!insuranceStatus)}
          />
        </TableCell>
        { isUserHasPermission && (
          <TableCell align="center">
          <IconButton size="small" onClick={handlePopover}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      )}

        <PopoverStyles
          id={popoverId}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          elevation={1}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="popoverWrapper">
            <List className="popoverList" component="div" dense>
              {renderActionButton()}
            </List>
          </div>
        </PopoverStyles>

        <Dialog
          open={openDeleteModal}
          PaperProps={{
            style: {
              borderRadius: "14px",
            },
          }}
        >
          <CustomDeleteModal
            title="Notice"
            onClose={handleDeleteModalClose}
            handleConfirm={handleConfirmDeletion}
            closeButtonText="Close"
            confirmButtonText="Delete"
            showConfirmButton={true}
            showCloseButton={true}
            description={DescriptionForDelete}
            placeholder="Type Insurance Company name"
            imgSrc={ImgSrcForDeleteModal}
            confirmationText={company_name}
          />
        </Dialog>

        {openAddModal && (
          <AddOrEditNewCompanyModal
            open={openAddModal}
            onClose={() => setOpenAddModal(false)}
            setInsuranceCompanyList={setInsuranceCompanyList}
            folderList={folderList}
            handleFolderList={handleFolderList}
            selectedFolder={selectedFolder}
            edit={company}
            onUpdateCompanyName={onUpdateCompanyName}
          />
        )}
      </TableRowWrapper>
    );
  };
  
  export default EachInsuranceCompaniesTableItem;
  