import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// import components
import Activity from "../components/activity/Activity";
import ActivityLogs from '../components/activityLogs/ActivityLogs';
import Contact from "../components/contactCustomFields/ContactCustomFields";
import CustomField from '../components/customField';
import EmailSettings from '../components/emailSettings/EmailSettings';
import General from "../components/general/General";
import LeadGroup from "../components/group/LeadGroup";
import HealthCheck from '../components/HealthCheck';
import leadSource from '../components/leadSource';
import Lists from '../components/list/Lists';
import MailBox from '../components/mailBox/MailBox';
import MapOrder from '../components/mapOrderList/MapOrder';
import MapOrderList from '../components/mapOrderList/MapOrderList';
import Package from '../components/packages/Package';
import Packages from '../components/packages/Packages';
import PhoneSettings from '../components/phoneSettings/PhoneSettings';
import Profile from "../components/profile/Profile";
import PublicApi from '../components/publicApi';
import Settings from '../components/settings/Settings';
import LeadNotification from '../components/siteNotifications/LeadNotification';
import SiteNotification from '../components/siteNotifications/SiteNotification';
import Tags from '../components/tag/Tags';
import Team from '../components/team/Team';
// import MapIntegration from '../components/mapIntegration/Index';
import AppAuthorization from '../components/appList/AppAuthorization';
import AppAuthorizeUrl from '../components/appList/AppAuthorizeUrl';
import { AppList } from '../components/appList/AppList';
import CalenderSyncV2 from '../components/CalendarSyncV2/CalenderSyncV2';
import ContactValidationReport from "../components/contactValidationReport/ContactValidationReport";
import CustomFields from '../components/customFields/Index';
import CustomMenu from '../components/customMenu/CustomMenu';
import DealReport from '../components/dealReport';
import FirstPromoter from "../components/firstPromoter/FirstPromoter";
import GeoFarmingUserNotiMain from '../components/geo-farming-user-noti/GeoFarmingUserNotiMain';
import GeoMain from '../components/geo/GeoMain';
import LeadSourceV2 from '../components/insurance/leadSourceV2';
import CallOutcomeInsurance from '../components/insurance/leadSourceV2/callOutcome/CallOutcomeInsurance';
import Invoice from '../components/invoice/Invoice';
import InvoiceSettings from '../components/invoiceSettings/InvoiceSettings';
import LeadPermissionRequest from "../components/leadPermissionRequest";
import LookupValidation from '../components/lookupValidation/LookupValidation';
import CardMainPage from '../components/mapIntegration/CardmainPage';
import CardPayment from '../components/mapIntegration/CardPayment';
import MapOrderV2 from "../components/mapOrderV2/MapOrderV2";
import OpenAi from "../components/openAI/OpenAi";
import PermissionSettings from '../components/permissionSettings/Index';
import Policies from '../components/policies/Policies';
import Products from '../components/products';
import SalesReports from '../components/salesReports/SalesReports';
import SmartWebhookHistoryReport from "../components/smartWebhookReport/SmartWebhookHistoryReport";
import SmartWebhookReport from "../components/smartWebhookReport/SmartWebhookReport";
import SnapShot from "../components/snapshot/SnapShot";
import SnapShotCategory from '../components/snapshotCategory/SnapShotCategory';
import TagsV2 from '../components/tagsV2/Index';
import TelephonyService from "../components/telephonyService";
import ViewSettings from "../components/viewControl/ViewSettings";
import ConnectedGoogleAccount from '../ConnectedGoogleAccount/ConnectedGoogleAccount';
import { AGENCY_BUSINESS_TYPE } from '../constants/CoreConstants';
import Utils from '../helpers/Utils';


const Routes = () => {
    const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
    return (
        <Router>
            <Switch>
                <Route exact path={'/user/profile'} component={Profile} />
                <Route exact path={'/user/general-settings'} component={Settings} />
                
                {/* <Route exact path={'/user/call-outcome'} component={CallOutcome} /> */}
                
                <Route exact path={'/user/call-outcome'} component={CallOutcomeInsurance} />
                
                <Route exact path={'/user/settings'} component={General} />
                <Route exact path={'/user/profile/email-settings'} component={EmailSettings} />
                <Route exact path={'/user/profile/team'} component={Team} />
                <Route exact path={'/user/profile/permission-settings'} component={PermissionSettings} />
                <Route exact path={'/user/profile/snapshot'} component={SnapShot} />
                
                <Route exact path={'/user/profile/calendar-sync'} component={CalenderSyncV2} />
                {/* <Route exact path={'/user/profile/calendar-sync'} component={CalendarSyncNew} /> */}
                <Route exact path={'/user/profile/activity-logs'} component={ActivityLogs} />
                <Route exact path={'/user/lists'} component={Lists} />
                <Route exact path={'/user/tags'} component={isAgencyTypeInsurance ? TagsV2 :  Tags} />
                {/* <Route exact path={'/user/tags-v2'} component={TagsV2} /> */}
                <Route exact path={'/user/activity'} component={Activity} />
                <Route exact path={'/user/lead-group'} component={LeadGroup} />
                <Route exact path={'/user/packages'} component={Packages} />
                <Route exact path={'/user/package/:id'} component={Package} />
                <Route exact path={'/user/map-orders'} component={MapOrderList} />
                <Route exact path={'/user/map-orders/:id'} component={MapOrder} />
                <Route exact path={'/user/phone-settings'} component={PhoneSettings} />
                <Route exact path={'/user/mail-box'} component={MailBox} />
                <Route exact path={'/user/all-site-notification'} component={SiteNotification} />
                <Route exact path={'/user/all-lead-notification'} component={LeadNotification} />
                <Route path={'/user/contact'} component={Contact} />
                <Route exact path={'/user/lookup-validation'} component={LookupValidation} />

                {/* custom fields */}
                <Route exact path={'/contact-custom-fields'} component={CustomField} />
                <Route exact path={'/custom-fields'} component={CustomFields} />
                {/* lead source */}
                {
                   (Utils.getAccountData("agencyBusinessType") === undefined || 
                    Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?  
                    (<Route exact path={'/lead-source-type'} component={leadSource} />
                    ) : (
                     <Route exact path={'/lead-source-type'} component={LeadSourceV2} />
                    )
                }

                <Route exact path={'/lead-permission-requests'} component={LeadPermissionRequest} />

                <Route path={"/profile/health-check"} component={HealthCheck} />

                {/* public api related routes */}
                <Route path={"/public-api"} component={PublicApi} />
                <Route exact path={"/user/profile/first-promoter"} component={FirstPromoter} />

                {/* Geofarming integration related routes */}
                <Route exact path={"/map/integration"} component={CardMainPage} />
                <Route exact path={"/map/integration/payment"} component={CardPayment} />


                {/* Geo route */}
                <Route path={"/geo"} component={GeoMain} />

                {/* Geo farming user Notifying route */}
                <Route path={"/geo-farming-user"} component={GeoFarmingUserNotiMain} />

                <Route exact path={'/user/profile/snapshot-marketplace'} component={SnapShotCategory} />
                <Route exact path={'/user/profile/view-settings'} component={ViewSettings} />

                <Route exact path={'/user/profile/custom-menu'} component={CustomMenu} />
                <Route exact path={'/user/contact-validation-report'} component={ContactValidationReport} />
                <Route exact path={'/user/trust-center'} component={TelephonyService} />
                <Route exact path={'/user/invoice'} component={Invoice} />
                <Route exact path={'/user/invoice-settings'} component={InvoiceSettings} />
                <Route exact path={'/user/telephony-service/phone-settings'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/trust-center'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/shaken-stir-cnam'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/call-outcome'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/call-logs'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/call-logs-details'} component={TelephonyService} />

                <Route exact path={'/user/smart-webhook-report'} component={SmartWebhookReport} />
                <Route exact path={'/user/smart-webhook-report/history'} component={SmartWebhookHistoryReport} />

                <Route exact path={'/user/map-orders/v2/:id'} component={MapOrderV2} />

                <Route exact path={'/user/app-list'} component={AppList} />
                <Route exact path={'/user/app-list/authorize/:clientID'} component={AppAuthorizeUrl} />
                <Route exact path={'/oauth2/authorize'} component={AppAuthorization} />
                <Route exact path={'/user/profile/open-ai-integration'} component={OpenAi} />

                <Route exact path={'/user/deal-report'} component={DealReport} />
                <Route exact path={'/user/connected-google-account'} component={ConnectedGoogleAccount} />,
                <Route exact path={'/user/products'} component={Products} />
                <Route exact path={'/user/sales-reports'} component={SalesReports} />
                <Route exact path={'/user/policies'} component={Policies} />
            </Switch>
        </Router>
    );
}

export default Routes;