import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    modalContainer: {
        maxWidth: "720px",
        background: "white",
        borderRadius: "14px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        },
    header: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "10px",
        gap: "5px",
    },
    logoContainer: {
        width: "80px",
        height: "80px",
    },
    logo: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    title: {
        fontSize: "20px",
        fontWeight: 600,
        paddingLeft: "5px",
    },
    content: {
        padding: "5px 20px",
    },
    description: {
        padding: "5px",
        textAlign: "center",
        marginBottom: "10px",
        fontSize: "16px",
    },
    actions: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        padding: "5px",
        marginTop: "10px",
        marginBottom: "20px",
    },
    button: {
        padding: "8px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        transition: "all 0.2s ease",
    },
    closeButton: {
        backgroundColor: "#4550f8",
        color: "#ffffff",
        fontSize: "16px",
        "&:focus, &:hover": {
            backgroundColor: "#2c38ee",
            transform: "translateY(-1px)",
        },
    },
    deleteButton: {
        backgroundColor: "#ef244a",
        color: "#fff",
        fontSize: "16px",
        padding: "12px 50px",
        "&:focus, &:hover": {
            backgroundColor: "#c32845",
            transform: "translateY(-1px)",
        },
    }
});