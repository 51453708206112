import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
// import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga';
import rootMiddleware from "../middlewares/rootMiddleware";
import {watchManageUsersSaga} from "../reducers/manageUser/manageUserSaga";
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootMiddleware);
sagaMiddleware.run(watchManageUsersSaga);

export default store;
