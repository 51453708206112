import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Header from './Header';
import MainContent from './MainContent';
import { PRIVACY_LEVEL_TAB_VALUE } from '../../constants/CoreConstants';

const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 120px)",
      backgroundColor: "white",
      borderRadius: "10px",
    },
  }));

const TagsV2 = () => {
  const [taglist, setTagList] = useState([]);
  const [privacyLevel, setPrivacyLevel] = useState(PRIVACY_LEVEL_TAB_VALUE.GLOBAL);
    const classes = useStyles();
        return (
          <div className={classes.root}>
            <Header taglist={taglist} setTagList={setTagList} privacyLevel={privacyLevel} />
            <MainContent taglist={taglist} setTagList={setTagList} privacyLevel={privacyLevel} setPrivacyLevel={setPrivacyLevel} />
          </div>
        );
};

export default TagsV2;