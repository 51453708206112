import { makeStyles } from "@material-ui/core";

export const ModalStylesLeadSource = makeStyles({
    errorMessage: {
      color: "red",
      fontSize: "12px",
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
    },
    editButton: {
      display: "flex",
      cursor: "pointer",
      border:"none",
      background: "#f2f2f2",
      borderRadius: "5px",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 0",
      width: "95px",
      "& .MuiSvgIcon-root": {
      fontSize: "18px",  
      },
      "&:hover": {
        backgroundColor: "#E5E8EF !important",
        border: "none",
        color: "#466fef",
        "& .MuiSvgIcon-root": {
          fill: "#466fef",
          fontSize: "18px",  
        },
      },
      "&:focus": {
        backgroundColor: "#f2f2f2 !important",
        border: "none",
      },
    },
    deleteButton: {
      display: "flex",
      border:"none",
      cursor: "pointer",
      background: "#f2f2f2",
      borderRadius: "5px",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 0",
      width: "95px",
      "& .MuiSvgIcon-root": {
          fill: "#000",
          fontSize: "18px",  
        },
      "&:hover": {
        backgroundColor: "#E5E8EF !important",
        border: "none",
        color: "#FF264A !important",
        "& .MuiSvgIcon-root": {
          fill: "#FF264A",
        },
      },
      "&:focus": {
        backgroundColor: "#f2f2f2 !important",
        border: "none",
      },
    },
    button: {
      padding: "8px 16px",
      border: "none", 
      borderRadius: "20px",
      cursor: "default",
      "&:focus, &:hover": {
        backgroundColor: "#efefef",
      },
    },
    buttontext: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
    headerText: {
      fontSize: "18px !important",
    },
    text: {
      fontSize: "16px !important",
    },
  });