import {makeStyles} from "@material-ui/core";

export const useSkeletonStyles = makeStyles((theme) => ({
    modalWrapper: {
        "& .MuiDialog-paper": {
            // maxWidth: 1200,
            minWidth: 1080,
            maxHeight: "calc(100% - 40px)"
        },
        "& .modalHeader": {
            display: "flex",
            //   justifyContent: "space-between",
            gap: 16,
            alignItems: "center",
            padding: theme.spacing(2),
            borderBottom: "1px solid #3c7ef31a",
            fontSize: 24,
            marginBottom: theme.spacing(2),

            "& .headerLeft": {
                display: "flex",
                alignItems: "center",
                gap: 20,

                "& .headerText": {
                    "& .title": {
                        fontSize: 22,
                        fontWeight: "bold",
                        margin: "0 0 8px 0",
                        color: "var(--dark_blue)",
                    },

                    "& .description": {
                        fontSize: 16,
                        color: "grey",
                        margin: 0,
                    },
                },
            },
        },
        "& .modalFooter": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "15px",
            borderTop: "1px solid rgba(148, 157, 178, 0.24)",
            padding: "16px 24px",
            gap: "10px",
            "& .submitButton": {
                "&:hover, &:focus": {
                    backgroundColor: "#3f51b5",
                },
            },
        },
        "& .error": {
            color: "var(--red)",
            fontSize: "14px",
        },
    },

    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    section: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        padding: theme.spacing(2),
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    sectionHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "18px",
        marginBottom: theme.spacing(1),
    },
    sectionSubtitle: {
        color: "gray",
        fontSize: 14,
        // fontWeight: 600,
        lineHeight: "16px",
        marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(2),
    },
    fieldLabel: {
        fontSize: 14,
        marginBottom: theme.spacing(0.25),
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        background: "#f3f3f3",
        zIndex: 3,
        position: "sticky",
        top: 0,
    },
    tableHeader: {
        fontSize: "16px",
        fontWeight: 600,
        whiteSpace: "nowrap",
        width: "200px",

        "& .flexWrapper": {
            display: "flex",
            alignItems: "center",
            gap: 4,
        },

        "& .permissionName": {
            width: "200px",
            textWrap: "auto",
        }
    },
    tableRowWrapper: {
        color: theme.palette.text.primary,

        "& .titleWrap": {
            display: "flex",
            alignItems: "center",
            gap: 8,
        },

        "& .folderWrap": {
            display: "flex",
            flexWrap: "wrap",
            gap: 4,
            marginTop: 2,

            "& .folderItem": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                // border: "1px solid #D0D5DD",
                borderRadius: "4px",
                padding: "2px 4px",
                marginRight: 10,

                "& .folderName": {
                    fontSize: "12px !important",
                    color: "#475467",
                    lineHeight: 1,
                },
            },
        },

        "& .iconFavorite": {
            fontSize: 16,
            color: "gray",
            cursor: "pointer",
            "&.active": {
                color: "#f79009",
            },
        },

        "& .MuiTableCell-root": {
            fontSize: "15px",
            fontWeight: 500,
        },

        "& .MuiIconButton-root": {
            "&:focus": {
                background: "transparent",
            },
        },
    },
}));

export const useModalStyles = makeStyles((theme) => ({
    modalWrapper: {
        "& .MuiDialog-paper": {
            // maxWidth: 1200,
            minWidth: 1080,
            maxHeight: "calc(100% - 40px)"
        },
        "& .modalHeader": {
            display: "flex",
            //   justifyContent: "space-between",
            gap: 16,
            alignItems: "center",
            padding: theme.spacing(2),
            borderBottom: "1px solid #3c7ef31a",
            fontSize: 24,
            marginBottom: theme.spacing(2),

            "& .headerLeft": {
                display: "flex",
                alignItems: "center",
                gap: 20,

                "& .headerText": {
                    "& .title": {
                        fontSize: 22,
                        fontWeight: "bold",
                        margin: "0 0 8px 0",
                        color: "var(--dark_blue)",
                    },

                    "& .description": {
                        fontSize: 16,
                        color: "grey",
                        margin: 0,
                    },
                },
            },
        },
        "& .modalFooter": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "15px",
            borderTop: "1px solid rgba(148, 157, 178, 0.24)",
            padding: "16px 24px",
            gap: "10px",
            "& .submitButton": {
                "&:hover, &:focus": {
                    backgroundColor: "#3f51b5",
                },
            },
        },
        "& .error": {
            color: "var(--red)",
            fontSize: "14px",
        },
    },

    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    section: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        padding: theme.spacing(2),
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    sectionHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: "18px",
        marginBottom: theme.spacing(1),
    },
    sectionSubtitle: {
        color: "gray",
        fontSize: 14,
        // fontWeight: 600,
        lineHeight: "16px",
        marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(2),
    },
    fieldLabel: {
        fontSize: 14,
        marginBottom: theme.spacing(0.25),
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        background: "#f3f3f3",
        zIndex: 3,
        position: "sticky",
        top: 0,
    },
    tableHeader: {
        fontSize: "16px",
        fontWeight: 600,
        whiteSpace: "nowrap",
        width: "200px",

        "& .flexWrapper": {
            display: "flex",
            alignItems: "center",
            gap: 4,
        },

        "& .permissionName": {
            width: "200px",
            textWrap: "auto",
        }
    },
    tableRowWrapper: {
        color: theme.palette.text.primary,

        "& .titleWrap": {
            display: "flex",
            alignItems: "center",
            gap: 8,
        },

        "& .folderWrap": {
            display: "flex",
            flexWrap: "wrap",
            gap: 4,
            marginTop: 2,

            "& .folderItem": {
                display: "flex",
                alignItems: "center",
                gap: 8,
                // border: "1px solid #D0D5DD",
                borderRadius: "4px",
                padding: "2px 4px",
                marginRight: 10,

                "& .folderName": {
                    fontSize: "12px !important",
                    color: "#475467",
                    lineHeight: 1,
                },
            },
        },

        "& .iconFavorite": {
            fontSize: 16,
            color: "gray",
            cursor: "pointer",
            "&.active": {
                color: "#f79009",
            },
        },

        "& .MuiTableCell-root": {
            fontSize: "15px",
            fontWeight: 500,
        },

        "& .MuiIconButton-root": {
            "&:focus": {
                background: "transparent",
            },
        },
    },
}));