export const HANDLE_NEW_FOLDER_CREATE_MODAL = "HANDLE_NEW_FOLDER_CREATE_MODAL";
export const HANDLE_SELECTED_CUSTOM_FIELD_FOLDER = "HANDLE_SELECTED_CUSTOM_FIELD_FOLDER";
export const ADD_CUSTOM_FIELD_FOLDER = "ADD_CUSTOM_FIELD_FOLDER";
export const ADD_CUSTOM_FIELD_FOLDER_SUCCESS = "ADD_CUSTOM_FIELD_FOLDER_SUCCESS";
export const ADD_CUSTOM_FIELD_FOLDER_FAILURE = "ADD_CUSTOM_FIELD_FOLDER_FAILURE";

export const GET_CUSTOM_FIELD_FOLDER = "GET_CUSTOM_FIELD_FOLDER";
export const GET_CUSTOM_FIELD_FOLDER_SUCCESS = "GET_CUSTOM_FIELD_FOLDER_SUCCESS";
export const GET_CUSTOM_FIELD_FOLDER_FAILURE = "GET_CUSTOM_FIELD_FOLDER_FAILURE";

export const UPDATE_CUSTOM_FIELD_FOLDER = "UPDATE_CUSTOM_FIELD_FOLDER";
export const UPDATE_CUSTOM_FIELD_FOLDER_SUCCESS = "UPDATE_CUSTOM_FIELD_FOLDER_SUCCESS";
export const UPDATE_CUSTOM_FIELD_FOLDER_FAILURE = "UPDATE_CUSTOM_FIELD_FOLDER_FAILURE";

export const DELETE_CUSTOM_FIELD_FOLDER = "DELETE_CUSTOM_FIELD_FOLDER";
export const DELETE_CUSTOM_FIELD_FOLDER_SUCCESS = "DELETE_CUSTOM_FIELD_FOLDER_SUCCESS";
export const DELETE_CUSTOM_FIELD_FOLDER_FAILURE = "DELETE_CUSTOM_FIELD_FOLDER_FAILURE";

export const ADD_CUSTOM_FIELD = "ADD_CUSTOM_FIELD";
export const ADD_CUSTOM_FIELD_SUCCESS = "ADD_CUSTOM_FIELD_SUCCESS";
export const ADD_CUSTOM_FIELD_FAILURE = "ADD_CUSTOM_FIELD_FAILURE";

export const GET_CUSTOM_FIELD = "GET_CUSTOM_FIELD";
export const GET_CUSTOM_FIELD_SUCCESS = "GET_CUSTOM_FIELD_SUCCESS";
export const GET_CUSTOM_FIELD_FAILURE = "GET_CUSTOM_FIELD_FAILURE";

export const HANDLE_PAGE_CHANGE = "HANDLE_PAGE_CHANGE";
export const HANDLE_ROW_CHANGE = "HANDLE_ROW_CHANGE";
export const HANDLE_SEARCH = "HANDLE_SEARCH";

export const UPDATE_CUSTOM_FIELD_INSURANCE = "UPDATE_CUSTOM_FIELD_INSURANCE";
export const UPDATE_CUSTOM_FIELD_INSURANCE_SUCCESS = "UPDATE_CUSTOM_FIELD_INSURANCE_SUCCESS";
export const UPDATE_CUSTOM_FIELD_INSURANCE_FAILURE = "UPDATE_CUSTOM_FIELD_INSURANCE_FAILURE";

export const DELETE_CUSTOM_FIELD_INSURANCE = "DELETE_CUSTOM_FIELD_INSURANCE";
export const DELETE_CUSTOM_FIELD_INSURANCE_SUCCESS = "DELETE_CUSTOM_FIELD_INSURANCE_SUCCESS";
export const DELETE_CUSTOM_FIELD_INSURANCE_FAILURE = "DELETE_CUSTOM_FIELD_INSURANCE_FAILURE";

export const HANDLE_CUSTOM_FIELD_STATUS = "HANDLE_CUSTOM_FIELD_STATUS";
export const HANDLE_CUSTOM_FIELD_STATUS_SUCCESS = "HANDLE_CUSTOM_FIELD_STATUS_SUCCESS";
export const HANDLE_CUSTOM_FIELD_STATUS_FAILURE = "HANDLE_CUSTOM_FIELD_STATUS_FAILURE";

export const HANDLE_CUSTOM_FIELD_GROUP_CHANGE= "HANDLE_CUSTOM_FIELD_GROUP_CHANGE";
export const HANDLE_CUSTOM_FIELD_GROUP_CHANGE_SUCCESS = "HANDLE_CUSTOM_FIELD_GROUP_CHANGE_SUCCESS";
export const HANDLE_CUSTOM_FIELD_GROUP_CHANGE_FAILURE = "HANDLE_CUSTOM_FIELD_GROUP_CHANGE_FAILURE";

export const HANDLE_CUSTOM_FIELD_ORDER_CHANGE = "HANDLE_CUSTOM_FIELD_ORDER_CHANGE";
export const HANDLE_CUSTOM_FIELD_ORDER_CHANGE_SUCCESS = "HANDLE_CUSTOM_FIELD_ORDER_CHANGE_SUCCESS";
export const HANDLE_CUSTOM_FIELD_ORDER_CHANGE_FAILURE = "HANDLE_CUSTOM_FIELD_ORDER_CHANGE_FAILURE";
export const RESET_STATE = "RESET_STATE";
