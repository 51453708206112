import React, { useState } from "react";
import { Button, makeStyles, Tab, Tabs } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CustomFieldsContent from "./CustomFieldsContent/Index";
import SystemDefineField from "../customField/SystemDefineField";
import { handleUserCustomFieldTabChange, } from "../../actions/CustomFieldInsuranceAction";
import { useDispatch } from "react-redux";
import HowItWorksModal from "../common/Modal/HowItWorksModal";
import MergeFieldsContent from "./MergeFieldsContent/Index";
import { CUSTOM_FIELD_TAB_VALUE } from "../../constants/CoreConstants";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    "& .helpButton": {
      marginTop: 6,
      "&:focus": {
        background: "#e0e0e0",
      },
    }
  },
}));

const MainContent = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(1);
  const dispatch = useDispatch();
  const [openHowItsWorksModal, setOpenHowItsWorksModal] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    dispatch(handleUserCustomFieldTabChange(newValue));
  };
  
  return (
    <div className={classes.mainContent}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            disableRipple
            label="Standard Fields"
            style={{ textTransform: "capitalize" }}
          />
          <Tab
            disableRipple
            label="Custom Fields"
            style={{ textTransform: "capitalize" }}
          />
          <Tab
            disableRipple
            label="Merge Fields"
            style={{ textTransform: "capitalize" }}
          />
        </Tabs>

        <Button
            size="small"
            variant="contained"
            color="inherit"
            startIcon={<HelpOutlineIcon />}
            disableElevation
            className="helpButton"
            onClick={() => setOpenHowItsWorksModal(true)}
          >
            HOW IT WORKS
        </Button>
      </div>

      {
        tabValue === CUSTOM_FIELD_TAB_VALUE.STANDARD_FIELDS && 
        <SystemDefineField />
      }
      {
        tabValue === CUSTOM_FIELD_TAB_VALUE.CUSTOM_FIELDS && 
        <CustomFieldsContent />
      }
      {
        tabValue === CUSTOM_FIELD_TAB_VALUE.PERSONALIZED_FIELDS && 
        <MergeFieldsContent />
      }

      {
        openHowItsWorksModal && (
          <HowItWorksModal
            open={openHowItsWorksModal}
            onClose={() => setOpenHowItsWorksModal(false)}
            videoSrc="https://cdn.pixabay.com/video/2020/06/22/42890-432450896_large.mp4" // this need to be changed
          />
        )
      }
    </div>
  );
};

export default MainContent;
