import { Grid, makeStyles, Typography, withStyles } from "@material-ui/core";
import { useState } from "react";
import { saveCallOutcome } from "../../../../api/profileApi";
import CustomDynamicModal from "../../../invoiceSettings/common/modal/CustomDynamicModal";
import { BasicInput } from "../../../products/productsStyle";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  fieldLabel: {
    fontSize: 14,
    marginBottom: "8px",
    fontWeight:600,
  },
  fieldDescription: {
    fontSize: "16px",
    textAlign: "center",
    fontWeight:400,
    marginBottom: "20px",
    padding: "0px 10px",
  },
  note: {
    fontSize: 14,
    marginTop: "8px",
  },
}));

const ErrorMessage = withStyles((theme) => ({
  root: {
    fontSize: "11px",
    color: "red",
    marginLeft: "4px",
    marginTop: "2px",

    "& .restoreText": {
      color: "#0b0be1",
      textDecoration: "underline",
      fontWeight: 600,
      cursor: "pointer",
      userSelect: "none",
    },
  },
}))(Typography);
const CallOutcomePopup = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(
    props.editCallOutcome ? props.editCallOutcome.outcome : ""
  );
  const id = props.editCallOutcome ? props.editCallOutcome.id : null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const submitData = () => {
    if (value === "") {
      setError("call desiposition name is required");
      return;
    }
    if (props.editCallOutcome && value === props.editCallOutcome.outcome) {
      props.onClose();
      return;
    }
    if (!value.match(/^[A-Za-z 0-9]+$/)) {
      setError("Invalid title. Valid text: [A-Z, a-z, 0-9]");
      return;
    }

    setLoading(true);
    saveCallOutcome({
      form_data: {
        id: id,
        outcome: value,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          setError("");
          setLoading(false);
          props.hideModal(true);
          window.showNotification("SUCCESS", response.data.message);
        } else {
          setError("Failed to save call disposition");
          window.showNotification("error", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error saving call disposition:", error);
        setError(
          "An unexpected error occurred while saving the call disposition"
        );
      });
  };

  return (
    <CustomDynamicModal
      open={props.modalVisible}
      onClose={props.onClose}
      headerIconSrc={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`}
      headerTitle={
        props.editCallOutcome
          ? "Edit Call Disposition"
          : "Add A New Call Disposition"
      }
      closeButtonText={"Close"}
      submitButtonText={"Save"}
      modalWidth={640}
      handleSubmit={submitData}
    >
      <div className={classes.cardWrapper}>
        <Grid md={12}>
          {props.editCallOutcome &&
          (
            <Typography className={classes.fieldDescription}>
              You are attempting to edit a global call outcome disposition.<br/>
              This change will be applied to all users on your team.
            </Typography>
          )}
          <Typography variant="body1" className={classes.fieldLabel}>
            Call Disposition Name <span style={{ color: "red" }}>*</span>
          </Typography>
          <BasicInput
            fullWidth
            name="name"
            placeholder={"Enter Call Disposition Name"}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Grid>
      </div>
    </CustomDynamicModal>
  );
};

export default CallOutcomePopup;
