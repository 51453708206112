import { Button, makeStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from "react";
import AddOrEditTagModal from "./AddOrEditTagModal";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #3c7ef31a",

    "& .headerLeft": {
      display: "flex",
      alignItems: "center",
      gap: 20,

      "& .headerText": {
        width:806,
        "& .title": {
          fontSize: 20,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 14,
          color: "#525252",
          margin: 0,
          height: "unset !important" 
        },
      },
    },

    "& .headerRight": {
      display: "flex",
      alignItems: "flex-start",
      gap: 8,

      "& .backButton": {
        width: 110,
      }
    },

    "& .addNewUserButton": {
      width: 120,
      backgroundColor: "#3f51b5",
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },
  },
}));

const Header = ({taglist, setTagList, privacyLevel}) => {
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/tags_icon.png`}
          alt=""
          height={70}
        />

        <div className="headerText">
          <h5 className="title">Tags</h5>
          <p className="description">
          Tags are identifiers that help organize contacts into groups based on shared attributes, such as interests, preferences, or demographics. Tags can help you segment your customer base into groups for targeted marketing.
          </p>
        </div>
      </div>

      <div className="headerRight">
      {history.location.state !== undefined &&
        history.location.state.from === "settingPage" && (
          <Link to='/user/settings' >
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              className="backButton"
            >
              Back
            </Button>
          </Link>
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          disableElevation
          className="addNewUserButton"
          onClick={() => setIsAddTagModalOpen(true)}
        >
          Add Tag
        </Button>
      </div>

      {isAddTagModalOpen && (
        <AddOrEditTagModal
          open={isAddTagModalOpen}
          onClose={() => setIsAddTagModalOpen(false)}
          taglist={taglist}
          setTagList={setTagList}
          privacyLevelTab={privacyLevel}
        />
      )}
    </div>
  );
};

export default Header;