import { call, put, takeLatest } from 'redux-saga/effects';
import manageUserService from "./manageUserService";
import {
    getUsers,
    getUsersError,
    getUsersSuccess,
    getUserCreationDetails,
    getUserCreationDetailsError,
    getUserCreationDetailsSuccess,
    saveUser,
    saveUserError,
    saveUserSuccess,
    getSubUserFormData,
    getSubUserFormDataSuccess,
    getSubUserFormDataError,
    forceLoginError,
    forceLoginSuccess,
    forceLogin,
    deactivateUser,
    deactivateUserSuccess,
    deactivateUserError,
    activateUserSuccess,
    activateUserError,
    activateUser,
    deleteUserWithoutTransferSuccess,
    deleteUserWithoutTransferError,
    deleteUserWithoutTransfer
} from "./manageUserSlice";
import Utils from "../../helpers/Utils";
import { PERMISSION_ROLES } from "../../constants/PermissionConst";
import { deleteCookie, getCookie, setCookie } from "../../helpers/Cookie";

function* getUserCreationDetailsSaga(action) {
    try {
        const response = yield call(manageUserService.getUserCreationDetails, action.payload);

        if (response?.status === "success" && response?.data) {
            const editUser = { ...response.data.editUser };

            if (response.data.role) {
                editUser.role_id = response.data.role.id;
            }

            yield put(getUserCreationDetailsSuccess({ editUser: editUser }));
        } else {
            yield put(getUserCreationDetailsError());
        }
    } catch (err) {
        yield put(getUserCreationDetailsError());
    }
}

function* getSubUserFormDataSaga() {
    try {
        const response = yield call(manageUserService.getSubUserFormData);

        if (response.success) {
            const roleDefaultPermissions =
                response
                    .data
                    .permissionRoles
                    .reduce((acc, { name, permission_value }) => {
                        acc[name] = (permission_value || "0");
                        return acc;
                    }, {
                        [PERMISSION_ROLES.STANDARD_USER]: "0",
                        [PERMISSION_ROLES.LIMITED_USER]: "0",
                    });

            yield put(getSubUserFormDataSuccess({
                subUsers: response.data.subUsers,
                roleDefaultPermissions: roleDefaultPermissions,
            }));
        } else {
            yield put(getSubUserFormDataError());
        }
    } catch (err) {
        console.log(err);
        yield put(getSubUserFormDataError());
    }
}

function* getUsersSaga(action) {
    try {
        const response = yield call(manageUserService.getUsers, action.payload);

        if (response?.status === "success" && response?.data?.data) {
            yield put(getUsersSuccess(response.data));
        } else {
            yield put(getUsersError());
        }
    } catch (err) {
        yield put(getUsersError());
    }
}

function* saveUserSaga(action) {
    try {
        const response = yield call(manageUserService.saveUser, action.payload);

        if (response?.status === "success") {
            yield put(saveUserSuccess(response.data));
            yield put(getUsers({
                page: 1,
                per_page: 10,
                query: "",
                sort_by: "full_name",
                sort_type: "asc"
            }));
            action.payload.callback();
        } else {
            yield put(saveUserError());
            if (response.status === "validation-error") {
                for (const key of Object.keys(response.message)) {
                    let errors = response.message[key];
                    errors.forEach(error => {
                        Utils.showNotification(error, "error");
                    });
                }
            } else {
                Utils.showNotification(response.message, "error");
            }
        }
    } catch (err) {
        yield put(saveUserError());
    }
}

function* forceLoginSaga(action) {
    try {
        const response = yield call(manageUserService.forceLogin, action.payload);

        if (response.success) {
            yield put(forceLoginSuccess());

            const parentId = Utils.getAccountData("userId");
            const parentKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

            setCookie(
                process.env.REACT_APP_ACCESS_TOKEN,
                response.data.user.api_key,
                process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
            );
            setCookie(
                process.env.REACT_APP_ACCESS_PARENT_TOKEN,
                parentKey,
                process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
            );
            deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
            setCookie(
                process.env.REACT_APP_ACCOUNT_DATA,
                JSON.stringify(response.data.userAccountData),
                process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
            );

            try {
                let local_data = localStorage.getItem("global__custom__field__data");
                if (local_data) {
                    localStorage.removeItem("global__custom__field__data")
                }
            }
            catch (error) {
                console.log(error)
            }
            setTimeout(() => {
                const channel = new BroadcastChannel("pypepro-channel");
                channel.postMessage({ action: "reload-page-" + parentId, link: '/' });
            }, 1000);
        } else {
            yield put(forceLoginError());
        }
    } catch (err) {
        yield put(forceLoginError());
    }
}

function* deactivateUserSaga(action) {
    try {
        const response = yield call(manageUserService.deactivateUser, action.payload);

        if (response.status === "success") {
            yield put(deactivateUserSuccess());
        } else {
            yield put(deactivateUserError());
        }
    } catch (err) {
        yield put(deactivateUserError());
    }
}

function* activateUserSaga(action) {
    try {
        const response = yield call(manageUserService.activateUser, action.payload);

        if (response.status === "success") {
            yield put(activateUserSuccess());
        } else {
            yield put(activateUserError());
        }
    } catch (err) {
        yield put(activateUserError());
    }
}

function* deleteUserWithoutTransferSaga(action) {
    try {
        const response = yield call(manageUserService.deleteUserWithoutTransfer, action.payload);

        if (response.status === "success") {
            yield put(deleteUserWithoutTransferSuccess());
            yield put(getUsers({
                page: 1,
                per_page: 10,
                query: "",
                sort_by: "full_name",
                sort_type: "asc"
            }));
        } else {
            yield put(deleteUserWithoutTransferError());
        }
    } catch (err) {
        yield put(deleteUserWithoutTransferError());
    }
}

export function* watchManageUsersSaga() {
    yield takeLatest(getUsers.type, getUsersSaga);
    yield takeLatest(saveUser.type, saveUserSaga);
    yield takeLatest(getSubUserFormData.type, getSubUserFormDataSaga);
    yield takeLatest(getUserCreationDetails.type, getUserCreationDetailsSaga);
    yield takeLatest(forceLogin.type, forceLoginSaga);
    yield takeLatest(deactivateUser.type, deactivateUserSaga);
    yield takeLatest(activateUser.type, activateUserSaga);
    yield takeLatest(deleteUserWithoutTransfer.type, deleteUserWithoutTransferSaga);
}