import { combineReducers } from 'redux'
import profileReducer from "./profileReducer";
import leadSourceReducer from "./leadSourceReducer";
import invoicePersonalizeTagReducer from "./invoicePersonalizeTagReducer";
import productReducer from './productReducer';
import manageUserSlice from "./manageUser/manageUserSlice";
import customFieldInsuranceReducer from './customFieldInsuranceReducer';

export default combineReducers({
    profileReducer,
    leadSourceReducer,
    invoicePersonalizeTagReducer,
    productReducer,
    manageUser: manageUserSlice,
    customFieldInsuranceReducer
})