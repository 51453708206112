import { makeStyles } from "@material-ui/core";

export const modalStylesLostReason = makeStyles({
    modalContainer: {
      minWidth: 550,
    },
    headerContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      borderBottom: "1px solid #d3d3d3",
      padding: "5px 10px",
      gap: "10px",
    },
    titleHeader: {
      fontSize: "20px",
    },
    bodyContainer: {
      padding: "20px",
    },
    errorStyle: {
      border: "1px solid red !important",
      borderRadius: "4px",
    },
    errorText: {
      color: "red",
    },
    buttonContainer: {
      padding: "15px 20px",
      display: "flex",
      justifyContent: "end",
      gap: "10px",
    },
    description: {
      border: "1px solid #ced4da !important",
      borderRadius: "4px !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .MuiInputBase-multiline": {
        color: "var(--dark_blue)",
      },
    },
    editDescription: {
      padding: "20px",
      textAlign: "center",
    },
  });