import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  styled,
} from "@material-ui/core";

export const CalenderSyncPopupStyle = makeStyles({
  header: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderBottom: "1px solid #e5e8ef",
    gap: "5px",
    padding: "12px 5px 12px 10px",
  },
  headerTitle: {
    fontSize: "20px",
    color: "#133159",
    fontWeight: "bold",
  },
  headerDescription: {
    fontSize: "14px",
    color: "#525252",
  },
  connectButton: {
    background: "#3e50f7",
    color: "white",
    display: "flex",
    fontSize: "16px",
    alignItems: "center",
    "&:hover ,&:focus": {
      background: "#3e50f7",
    },
  },
  content: {
    height: "calc(100vh - 153px)", 
    overflowY: "auto", 
    display: "flex",
    flexDirection: "column",
    justifyContent: "spce-between",
    gap: "30px",
    padding: "0px 20px",
  },
  bodyContent: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    gap: "10px",
  },
  deleteConfirmButton: {
    background: "#006df5",
    color: "white",
    "&:focus": {
      background: "#006df5",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px",
  },
  SelectTitle: {
    fontWeight: 600,
    paddingBottom: "5px",
    color: "#133159",
  },
});
export const CustomRadio = styled(Radio)(() => ({
  "& .MuiSvgIcon-root": {
    fill: "#0000008a",
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      fill: "#3f51b5",
    },
  },
  "&:hover": {
    "& .MuiSvgIcon-root": {
      fill: "#3f51b5",
    },
  },
}));
export const CustomFormControlLabel = styled(FormControlLabel)((props) => ({
  "& .MuiTypography-root": {
    fontSize: "14px",
    color: "#133159",
  },
  "& .MuiFormControlLabel-label": {
    marginLeft: "0px",
  },
  "&:not(:last-child)": {
    marginBottom: "5px",
  },
  marginLeft: "0px",
  marginRight: "0px",
  backgroundColor: props.checked ? "#E8F0FE" : "#fafbff",
  padding: "10px",
  borderRadius: "5px",
  border: props.checked ? "2px solid #317AE2" : "2px solid #c7c7c78a",
  cursor: "pointer",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#E8F0FE",
    border: "2px solid #c7c7c78a",
  },
}));
export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
  flexDirection: "column",
  "& .MuiSvgIcon-root": {
    fill: "#0000008a",
  },
  "& .Mui-checked": {
    "& .MuiSvgIcon-root": {
      fill: "#3f51b5",
    },
  },
  "&:hover": {
    "& .MuiSvgIcon-root": {
      fill: "#3f51b5",
    },
  },
}));
