import { makeStyles } from "@material-ui/core";

export const CalanderStyle = makeStyles({
    TableContainer: {
      padding: "20px 10px",
    },
    connectButton: {
      background: "#3e50f7",
      color: "white",
      display: "flex",
      fontSize: "16px",
      alignItems: "center",
      "&:hover ,&:focus": {
        background: "#3e50f7",
      },
    },
    bodyContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0px",
    },
    tableHead: {
      background: "#f5f7f9",
      color: "#133159",
    },
    tableHeadText: {
      color: "#133159",
      fontSize: "16px",
      fontWeight: 600,
    },
    tableRowText: {
      fontSize: "16px",
      color: "gray",
    },
    deleteConfirmButton: {
      background: "#006df5",
      color: "white",
      "&:focus": {
        background: "#006df5",
      },
    },
    bodyText:{
      fontSize: "16px",
      fontWeight: 600,
    },
    content: {
      display: "flex",
      alignItems: "center",
      padding: "30px 20px",
      gap: 30,
    },
    emptyItemcontainer: {
      border: "1px solid #3e50f7",
      background: "#ecedff",
      padding: "30px",
      minHeight: "250px",
      minWidth: "510px",
      borderRadius: "5px",
    },
    emptyItem: {
      display: "flex",
      alignItems: "center",
      padding: "5px",
      gap: 10,
    },
  });