import React from 'react';
import CalenderHeader from './CalenderHeader';
import CalendarSyncBody from './CalendarSyncBody';

const CalenderSyncV2 = () => {
  return (
    <div style={{
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      minHeight: '750px'
    }}>
      <CalenderHeader />
      <CalendarSyncBody />
    </div>
  );
};

export default CalenderSyncV2;