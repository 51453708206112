import React, { useState } from "react";
import { Divider, Modal } from "@material-ui/core";
import ConnectedEamilEmptyPageUseStyles from "./ConnectedEamilEmptyPageUseStyles";
import ConnectEmailView from "../ConnectEmailView";
import HowItWorksModal from "../../../common/Modal/HowItWorksModal";

const ConnectedEmailEmptyPage = ({
  image,
  icon,
  title,
  description,
  defaultNylasEmail,
}) => {
  const classes = ConnectedEamilEmptyPageUseStyles();
  const [openConnectNewEmailModal, setOpenConnectNewEmailModal] = useState(false);
  const [openHowItsWorksModal, setOpenHowItsWorksModal] = useState(false);
  
  return (
    
    <div>
        <div className={classes.wrapper}>
            {image && (
            <div className={classes.imageWrapper}>
                <img src={image} alt="Not Found" />
            </div>
            )}
            {icon && <div className={classes.iconWrapper}>{icon}</div>}
            <span className={classes.titleText}>{title}</span>
            <div
            variant="body1medium"
            color="text.secondary"
            sx={{ maxWidth: "720px", textAlign: "center" }}
            >
            {description}
            </div>
            <button
            className={classes.button}
            onClick={() => {
                setOpenConnectNewEmailModal(true);
            }}
            >
            Connect Your Email
            </button>
            <Modal
            open={openConnectNewEmailModal}
            onClose={() => {
                setOpenConnectNewEmailModal(false);
            }}
            className={"global-xs-modal add_list_modal"}
            style={{ display: "grid", placeContent: "center" }}
            >
            <ConnectEmailView
                defaultNylasEmail={defaultNylasEmail}
                handleCancel={() => {
                setOpenConnectNewEmailModal(false);
                }}
            />
            </Modal>
        </div>
        <div className={classes.footerContainer}>
            <span>
                Gmail/GSuite users watch this quick video before connecting.
                <div className={classes.footerBody} onClick={() => setOpenHowItsWorksModal(true)}>
                    <span className={classes.footerText}>Watch Now</span>
                </div>
            </span>
            <Divider
            orientation="vertical"
            style={{ backgroundColor: "#000", width: "2px", height: "50px" }}
            />
            <span>
                Microsoft users watch this quick video before connecting.
                <div className={classes.footerBody} onClick={() => setOpenHowItsWorksModal(true)}>
                    <span className={classes.footerText}>Watch Now</span>
                </div>
            </span>
        </div>
        {openHowItsWorksModal && (
        <HowItWorksModal
          open={openHowItsWorksModal}
          onClose={() => setOpenHowItsWorksModal(false)}
          videoSrc="https://cdn.pixabay.com/video/2020/06/22/42890-432450896_large.mp4" // this need to be changed
        />
        )}
    </div>
  );
};

export default ConnectedEmailEmptyPage;
