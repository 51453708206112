import React, { useEffect, useState } from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddOrEditNewPolicyTypeModal from "./AddOrEditNewPolicyTypeModal";
import { policyTypeStyle } from "./PolicyTypesStyle";
import { CustomButton } from "./policyTypesBody/CustomButton";
import { BasicInput } from "../../productsStyle";
import SearchIcon from "@material-ui/icons/Search";
import {
  policyTypeFolderState,
  policyTypeState,
} from "../../common/productSelector";
import { useDispatch, useSelector } from "react-redux";
import SelectedPoliciesActions from "./SelectedPoliciesActions";
import useDebounce from "../../../../hooks/useDebounce";
import { getPolicyTypes } from "../../../../actions/ProductAction";
import { InsuranceCompanyIcon } from "../../../ten-dlc/helpers/Icon";
import BasicSelect from "./BasicSelect";
import Utils from "../../../../helpers/Utils";

const POLICY_TYPE_STATUS = [
  {
    value: "ALL",
    label: "Show All",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

const PolicyTypesHeader = () => {
  const { selectedPolicyList, search } = useSelector(policyTypeState);
  const { selectedFolder } = useSelector(policyTypeFolderState);

  const { topbarHeaderTitle, productBodySubTitle } = policyTypeStyle();
  const [openAddPolicyTypeModal, setOpenAddPolicyTypeModal] = useState(false);
  const [searchValue, setSearchValue] = useState(search ? search : "");
  const debouncedSearch = useDebounce(searchValue, 500);
  const dispatch = useDispatch();
  const [policyStatus, setPolicyStatus] = useState("");
  const isUserOwnerOrAdmin = Utils.getAccountData("userIsOwner") || Utils.getAccountData("userIsAdmin");

  useEffect(() => {
    setPolicyStatus("");
  }, [selectedFolder]);

  const handleFilterStatus = (event) => {
    setPolicyStatus(event.target.value);
    dispatch(
      getPolicyTypes({
        page: 1,
        perPage: 10,
        status: event.target.value,
        userProductFolderId: selectedFolder === "all" ? null : selectedFolder,
        search: search,
      })
    );
  };
  useEffect(() => {
    dispatch(getPolicyTypes({ page: 1, perPage: 25, search: debouncedSearch }));
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCloseModal = () => {
    setOpenAddPolicyTypeModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div style={{ marginRight: "5px" }}>
            <InsuranceCompanyIcon />
          </div>
          <div>
            <Typography
              style={{ height: "unset !important" }}
              variant="h5"
              className={topbarHeaderTitle}
            >
              Manage Policy Types
            </Typography>
            <Typography
              style={{ height: "unset !important" }}
              variant="body2"
              className={productBodySubTitle}
            >
              In this section, select the different types of insurance policies
              you offer in your agency. In the status column, click the toggle
              button “on” for each policy type you offer.
            </Typography>
          </div>
        </div>

        <Box
          style={{
            display: "flex",
            flexDirection:'column',
            justifyContent: "flex-end",
            alignItems: "flex-end",
            gap: 8,
          }}
        >
         { isUserOwnerOrAdmin && ( 
          <CustomButton
            variant="contained"
            onClick={() => setOpenAddPolicyTypeModal(true)}
            startIcon={<AddIcon />}
            disableElevation
            style={{ fontSize: 14, paddingBlock: 5 }}
          >
            New Policy Type
          </CustomButton>
        )}
         <div style={{display:'flex', alignItems:'center', gap:'8px'}}>

         {( isUserOwnerOrAdmin && selectedPolicyList && selectedPolicyList[0]) ? (
            <SelectedPoliciesActions />
          ) : (
            <BasicInput
              value={searchValue}
              placeholder="Search for existing policy"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "#656565" }} />
                </InputAdornment>
              }
              style={{ width: "265px" }}
              onChange={handleSearch}
            />
          )}
          <div style={{ width: "170px" }}>
            <BasicSelect
              options={POLICY_TYPE_STATUS}
              defaultText="Filter By Status"
              mapping={{
                value: "value",
                label: "label",
              }}
              value={policyStatus}
              onChange={handleFilterStatus}
              style={{ width: "170px" }}
            />
          </div>
         </div>
        </Box>
      </Box>

      {openAddPolicyTypeModal && (
        <AddOrEditNewPolicyTypeModal
          open={openAddPolicyTypeModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default PolicyTypesHeader;
