import { Button, Dialog, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecycleEvent,
  resetUpdateRecycleEventSuccess,
  updateRecycleEvent,
} from "../../../../actions/ProductAction";
import { recycleEventState } from "../../common/productSelector";
import { BasicInput } from "../../productsStyle";
import { RecycleEventAddEditModalStyle } from "./RecycleEventBody/RecycleEventAddEditModalStyle";

const AddOrEditRecycleEventModal = ({ open, onClose, edit }) => {
  const classes = RecycleEventAddEditModalStyle();
  const dispatch = useDispatch();
  const { isAddRecycleEventSuccessful, isSubmitting } =
    useSelector(recycleEventState);
  const [formData, setFormData] = useState({
    title: edit?.event_title || "",
  });
  const [error, setError] = useState({
    title: "",
    description: "",
    closeDate: "",
  });

  useEffect(() => {
    if (isAddRecycleEventSuccessful) {
      onClose();
      dispatch(resetUpdateRecycleEventSuccess());
    }
  }, [isAddRecycleEventSuccessful]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: "",
    };
    if (formData?.title.trim() === "") {
      errors.title = "Recycle Event is required";
      isValid = false;
    }
    if (edit && formData?.title === edit?.event_title) {
      isValid = false;
      errors.title = "Recycle Event is already in use";
    }
    if (formData?.title.length > 100) {
      errors.title = "Recycle Event must be less than 20 characters";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    if (!validateForm()) {
      return;
    }

    if (edit) {
      dispatch(
        updateRecycleEvent({
          event_title: formData.title,
          deal_recycle_event_id: edit.id,
        })
      );
    } else {
      dispatch(addRecycleEvent({ event_title: formData.title }));
    }
  };

  const handleOnChange = (e) => {
    const newValue = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      title: newValue,
    }));

    if (newValue.length > 50) {
      setError((prevState) => ({
        ...prevState,
        title: "Recycle Event must be less than 50 characters",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        title: "",
      }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.modalContainer}>
        <div className={classes.headerContainer}>
          <div style={{ width: "50px", height: "50px" }}>
            <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className={classes.titleHeader}>
            {edit ? "Edit Recycle Event" : "Add a New Recycle Event"}
          </div>
        </div>
        <div className={classes.bodyContainer}>
          {edit && (
            <div className={classes.editDescription}>
              <Typography className={classes.fieldDescription}>
                You are attempting to edit an existing Recycle Event.
                <br />
                This change will be applied to all users on your team.
              </Typography>
            </div>
          )}
          <div>
            <Typography className={`${error.title ? classes.errorText : ""}`}>
              Recycle Event<span style={{ color: "red" }}>*</span>
            </Typography>
            {isSubmitting ? (
              <Skeleton width={"100%"} height={"48px"} />
            ) : (
              <div>
                <BasicInput
                  fullWidth
                  value={formData?.title}
                  className={`${error.title ? classes.errorStyle : ""}`}
                  onChange={handleOnChange}
                  required
                />
                {error.title && (
                  <Typography variant="body2" className={classes.errorText}>
                    {error.title}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className={classes.buttonContainer}
        >
            <Button
              onClick={onClose}
              color="primary"
              className={classes.lightBlue}
              variant="text"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={classes.topbarNewProductButton}
              variant="contained"
              disableElevation
              disabled={isSubmitting}
            >
              Save
            </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddOrEditRecycleEventModal;
