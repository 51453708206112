import Select from "react-select";
import styled from "styled-components";

export const StyledOptionFormatter = styled.div`
  display: flex;
  align-items: center;
  pointer-events: none;
  .input-select__icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
    svg {
      vertical-align: middle;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .select__input {
    opacity: 0;
    width: 0;
    position: absolute;
  }
  .select__control {
    min-height: 45px;
    border: 1px solid #e5e7eb;
    border-radius: 5px;
    margin:0px 1px;
    &:hover {
      border-color: #317ae2;
    }
  }

  .select__value-container {
    padding: 8px 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .select__multi-value {
    background-color: #e8f0fe;
    border-radius: 4px;
    margin: 4px;
    padding: 4px 8px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #dce4f2;
    }
  }

  .select__multi-value__label {
    color: #317ae2;
    font-size: 14px;
    padding: 0;
  }

  .select__multi-value__remove {
    color: #317ae2;
    padding: 0;
    margin-left: 6px;
    &:hover {
      background-color: transparent;
      color: #1e40af;
    }
  }

  .select__dropdown-indicator,
  .select__clear-indicator,
  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    border: 1px solid #e5e7eb;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .select__option {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #e8f0fe;
    }

    &.select__option--is-selected {
      background-color: #e8f0fe;
      color: #317ae2;
    }
  }
`;
export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  padding: 30px 24px;
  background-color: white;
  border-top: none;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: auto;
  z-index: 10;
`;
 
export const StyledButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;

  &.primary {
    background-color: #3e50f7;
    color: white;
    font-weight: bold;
    border: 1px solid #3e50f7;
    padding:8px 30px;

    &:hover:not(:disabled) {
      background-color: #2563eb;
      border-color: #2563eb;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: white;
    color: #000000;
    font-weight: bold;
    border: 2px solid #000000;
    padding:8px 30px;

    &:hover {
      background-color: #f3f4f6;
      border-color: #000000;
    }
  }
`;

export const Wrapper = styled.div`
  margin: 10px;
  max-width: 100%;
  width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;