import {
  Dialog,
  IconButton,
  TableCell,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteLostReason } from "../../../../../actions/ProductAction";
import CustomDeleteModal from "../../../../settings/CustomDeleteModal";
import AddOrEditLostReasonModal from "../AddOrEditLostReasonModal";
import Utils from "../../../../../helpers/Utils";
import { LostReasonStyle } from './LostReasonStyle';
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../../constants/PermissionConst";

const EachLostReasonTableItem = ({ policy }) => {
  const { id, reason, deletable } = policy || {};
  const dispatch = useDispatch();
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const DescriptionForDelete = "You are attempting to delete a global lost deal reason.\nThis change will be applied to all users on your team.";
  const ImgSrcForDeleteModal = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`;
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);


  const handleEditLostReason = () => {
    setOpenAddOrEditModal(true);
  };

  const confirmDeleteLostReason = async () => {
    dispatch(deleteLostReason({ lost_deal_reason_id: id }));
  };

  const handleDeleteLostReason = () => {
    setOpenDeleteModal(true);
  };

  return (
    <LostReasonStyle>
      <TableCell style={{width:'60%'}}>
        <div className="titleWrap">{reason}</div>
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*  <SwitchC*/}
      {/*    status={status}*/}
      {/*    brandColor={"#346fef"}*/}
      {/*    onChange={() => setStatus(!status)}*/}
      {/*  />*/}
      {/*</TableCell>*/}

      {isUserHasPermission &&
      (
        <TableCell style={{width:'40%'}}>
          {
            deletable &&
            ( 
              <div className="buttonWrap">
                  <IconButton className="editButton" onClick={handleEditLostReason}>
                    <div className="actionContainer" arrow title="Delete">
                      <span style={{marginTop:"2px"}}>
                       <EditIcon/>
                      </span>
                      Edit
                    </div>
                  </IconButton>
                  <IconButton  className="deleteButton" onClick={handleDeleteLostReason}>
                  <div className="actionContainer" arrow title="Delete">
                    <span style={{marginTop:"2px"}}>
                      <DeleteIcon/>
                    </span>
                      Delete    
                  </div>
                  </IconButton>
              </div>
            )}
          
        </TableCell>
      )}
      <Dialog
        open={openDeleteModal}
        PaperProps={{
          style: {
            borderRadius: '14px',
          },
        }}
      >
          <CustomDeleteModal
            title="Notice"
            onClose={() => setOpenDeleteModal(false)}
            handleConfirm={confirmDeleteLostReason}
            closeButtonText="Close"
            confirmButtonText="Delete"
            showConfirmButton={true}
            showCloseButton={true}
            description={DescriptionForDelete}
            placeholder="Type lost deal reason"
            imgSrc={ImgSrcForDeleteModal}
            confirmationText={reason}
          />
      </Dialog>

      {openAddOrEditModal && (
        <AddOrEditLostReasonModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModal(false)}
          edit={policy}
        />
      )}
    </LostReasonStyle>
  );
};

export default EachLostReasonTableItem;
