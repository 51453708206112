import {
  Dialog,
  IconButton,
  TableCell
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteRecycleEvent } from "../../../../../actions/ProductAction";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../../constants/PermissionConst";
import CustomDeleteModal from "../../../../settings/CustomDeleteModal";
import AddOrEditRecycleEventModal from "../AddOrEditRecycleEventModal";
import { RecycleEventStyle } from "./RecycleEventStyle";

const EachRecycleEventTableItem = ({ event }) => {
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const DescriptionForDelete =
    "You are attempting to delete an existing Recycle Event.This change will be applied to all users on your team.";
  const ImgSrcForDeleteModal = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/policy/1.0.0/recycle_event_icon.png`;

  const dispatch = useDispatch();

  const handleEditRecycleEvent = () => {
    setOpenAddOrEditModal(true);
  };

  const handleDeleteModal = () => {
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleConfirmDeletion = () => {
    confirmDeleteRecycleEvent();
    setOpenDeleteModal(false);
  };

  const confirmDeleteRecycleEvent = async () => {
    dispatch(deleteRecycleEvent({ deal_recycle_event_id: event.id }));
  };

 
  return (
    <RecycleEventStyle>
      <TableCell style={{ width: "60%" }}>
        <div className="titleWrap">{event.event_title}</div>
      </TableCell>
      {isUserHasPermission &&(
        <TableCell style={{ width: "40%" }}>
          {event.deletable && (
            <div className="buttonWrap">
              <IconButton className="editButton" onClick={handleEditRecycleEvent}>
                <div className="actionContainer" arrow title="Delete">
                  <span style={{ marginTop: "2px" }}>
                    <EditIcon />
                  </span>
                  Edit
                </div>
              </IconButton>
              <IconButton className="deleteButton" onClick={handleDeleteModal}>
                <div className="actionContainer" arrow title="Delete">
                  <span style={{ marginTop: "2px" }}>
                    <DeleteIcon />
                  </span>
                  Delete
                </div>
              </IconButton>
            </div>
          )}
        </TableCell>
      )}
      <Dialog
        open={openDeleteModal}
        PaperProps={{
          style: {
            borderRadius: "14px",
          },
        }}
      >
        <CustomDeleteModal
          title="Notice"
          onClose={handleDeleteModalClose}
          handleConfirm={handleConfirmDeletion}
          closeButtonText="Close"
          confirmButtonText="Delete"
          showConfirmButton={true}
          showCloseButton={true}
          description={DescriptionForDelete}
          placeholder="Type Recycle Event"
          imgSrc={ImgSrcForDeleteModal}
          confirmationText={event.event_title}
        />
      </Dialog>

      {openAddOrEditModal && (
        <AddOrEditRecycleEventModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModal(false)}
          edit={event}
        />
      )}
    </RecycleEventStyle>
  );
};

export default EachRecycleEventTableItem;
