import {
  Button,
  Chip,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUser } from "../../actions/profileAction";
import {
  calendarSyncData,
  calendarSyncDataDelete,
  getConnectedCalendar,
} from "../../api/profileApi";
import CustomConfirmModal from "../../components/common/CustomConfirmModal";
import { getCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";
import DynamicSkeleton from "../common/DynamicSkeleton";
import CustomDeleteModal from "../settings/CustomDeleteModal";
import { CalenderIcons } from "./CalanderIcon";
import { CalanderStyle } from "./CalanderStyle";
import CalanderSyncDrawer from "./calenderSyncPopup/CalanderSyncDrawer";

const CalendarSyncBody = (props) => {
  const classes = CalanderStyle();
  const [calenderConnector, setCalendarDisconnect] = useState(false);
  const [calendarSetting, setCalendarSetting] = useState(null);
  const [modalVisibleNew, setModalVisibleNew] = useState(false);
  const [loading, setLoading] = useState(true);
  const [componentLoading, setComponentLoading] = useState(true);
  const [nylasMail, setNylasMail] = useState(null);
  const [calendarListData, setCalendarListData] = useState([]);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [deleteCalendarId, setDeleteCalendarId] = useState();
  const userTimeZone = Utils.getAccountData("userTimezoneRaw");
  const calenderImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/calendar/1.0.0/calendar_icon.png`;
  useEffect(() => {
    document.title = `Calendar Sync | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("setting");
    }
    props.getUser({
      "api-key": getCookie(process.env.REACT_APP_ACCESS_TOKEN),
    });
    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.user && props.user.nylas_email_provider_info !== null) {
      setNylasMail(JSON.parse(props.user.nylas_email_provider_info).email);
    }
  }, [props.user]);

  const openModalNew = () => {
    setModalVisibleNew(true);
  };

  const closeModal = () => {
    setModalVisibleNew(false);
  };

  const loadData = async (_componentLoading = true) => {
    setComponentLoading(_componentLoading);
    try {
      const [connectedResponse, syncDataResponse] = await Promise.all([
        getConnectedCalendar({}),
        calendarSyncData(),
      ]);
      setCalendarSetting(connectedResponse.data.data);
      setCalendarListData(syncDataResponse.data.data);
    } catch (error) {
      Utils.showNotification(
        "We were unable to load calendar data. Please try again.",
        "error"
      );
    } finally {
      setComponentLoading(false);
      setLoading(false);
    }
  };

  const disconnectNew = async () => {
    try {
      setLoading(true);
      const response = await calendarSyncDataDelete({
        calendar_id: deleteCalendarId,
      });
      window.showNotification("SUCCESS", response.data.message);
      const resp = await calendarSyncData();
      setCalendarListData(resp.data.data);
      handleClose();
      setLoading(false);
    } catch (error) {
      Utils.showNotification("Something went wrong", "Error");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenAlertModal = (id) => {
    setOpenAlertModal(true);
    setDeleteCalendarId(id);
  };

  const handleClose = () => {
    setOpenAlertModal(false);
  };
  const handleCloseModal = () => {
    setCalendarDisconnect(false);
  };
  return (
    <Fragment>
      {!props.user || loading ? (
        <DynamicSkeleton count={8} width={"100%"} />
      ) : calendarListData && calendarListData.length === 0 ? (
        <div className={classes.content}>
          <div className={classes.emptyItemcontainer}>
            <div>
             We integrate with major calendar providers including:
            </div>
            <div className="row" style={{paddingTop:"20px",paddingLeft:"20px"}}>
              <div className={classes.emptyItem}>
                <CalenderIcons height={18} width={18} /> Google
              </div>
              <div className={classes.emptyItem}>
                {" "}
                <CalenderIcons height={18} width={18} /> Microsoft 365
              </div>
              <div className={classes.emptyItem}>
                {" "}
                <CalenderIcons height={18} width={18} /> Outlook.com
              </div>
              <div className={classes.emptyItem}>
                {" "}
                <CalenderIcons height={18} width={18} /> Microsoft Exchange
              </div>
            </div>
          </div>

          <Button
            className={classes.connectButton}
            variant="contained"
            disableElevation
            onClick={openModalNew}
          >
            <AddIcon />
            Connect Your Calendar
          </Button>
        </div>
      ) : (
        <div className={classes.TableContainer}>
          <div className={classes.bodyContent}>
            <div className={classes.bodyText}>
              The following calendars are currently connected to your CRM:
            </div>
            <div>
              <Button
                className={classes.connectButton}
                variant="contained"
                disableElevation
                onClick={openModalNew}
              >
                <AddIcon />
                New Calendar
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classes.tableHeadText}>Email</TableCell>
                  <TableCell className={classes.tableHeadText} align="center">
                    Calendar Name
                  </TableCell>
                  <TableCell className={classes.tableHeadText} align="center">
                    Connected At
                  </TableCell>
                  <TableCell className={classes.tableHeadText} align="center">
                    Status
                  </TableCell>
                  <TableCell className={classes.tableHeadText} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calendarListData?.map((calendarData) => (
                  <TableRow key={calendarData.user_id}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableRowText}
                    >
                      {calendarData.email}
                    </TableCell>
                    <TableCell align="center" className={classes.tableRowText}>
                      {calendarData.name}
                    </TableCell>
                    <TableCell align="center" className={classes.tableRowText}>
                      {window.globalTimezoneConversionToDifferentTimezone(
                        calendarData.created_at,
                        "UTC",
                        userTimeZone,
                        "MM/DD/YYYY hh:mm a"
                      )}
                    </TableCell>
                    {calendarData.status === 1 ? (
                      <TableCell align="center">
                        {" "}
                        <Chip
                          style={{
                            background: "#00ff91",
                            color: "#000",
                            padding: "10px",
                            fontWeight: 600,
                          }}
                          size="small"
                          label={"ACTIVE"}
                        />
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        {" "}
                        <Chip
                          style={{ padding: "10px" }}
                          size="small"
                          label={"PAUSE"}
                        />
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Button
                        onClick={() => handleOpenAlertModal(calendarData.id)}
                        disabled={loading}
                        variant="outlined"
                        style={{
                          border: "2px solid #ff0055",
                          color: "#ff0055",
                        }}
                      >
                        {loading ? (
                          <React.Fragment>
                            <i className="la la-spinner la-spin"></i>{" "}
                            Disconnecting
                          </React.Fragment>
                        ) : (
                          "Disconnect"
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      {modalVisibleNew && (
        <CalanderSyncDrawer
          visible={modalVisibleNew}
          handleCancel={closeModal}
          email={nylasMail}
          callback={() => {
            setCalendarDisconnect(true);
            loadData(false);
            closeModal();
          }}
          calendarSetting={calendarSetting}
        />
      )}
      <Dialog open={openAlertModal} onClose={handleClose}>
        <CustomDeleteModal
          title="Disconnect Calendar"
          onClose={handleClose}
          handleConfirm={disconnectNew}
          closeButtonText="Close"
          confirmButtonText="Disconnect"
          showConfirmButton={true}
          showCloseButton={true}
          description={
            "Are you certain you want to disconnect this calendar? \n \nThis action will permanently remove the selected calendar from the system, effectively ending synchronization of future events from this calendar."
          }
          imgSrc={calenderImg}
        />
      </Dialog>
      <Dialog open={calenderConnector} onClose={handleCloseModal}>
        <CustomConfirmModal
          title="Success! Your calendar is now connected"
          onClose={handleCloseModal}
          closeButtonText="Exit"
          showCloseButton={true}
          showConfirmButton={false}
          description="Keep in mind that only future events will appear inside of your CRM. Events scheduled in your calendar prior to the connection will not appear."
          imgSrc={calenderImg}
          minWidth="600px"
        />
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileReducer.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (params) => dispatch(getUser(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarSyncBody);
