import { CUSTOM_FIELD_TAB_VALUE } from "../constants/CoreConstants";
import * as ACTION from "../constants/customFieldInsuranceActionTypes";

const initialState = {
  customFieldTabValue: CUSTOM_FIELD_TAB_VALUE.CUSTOM_FIELDS,
  /* folder */
  openNewFolderCreateModal: false,
  openFolderUpdateModal: false,
  isAddingFolder: false,
  isUpdatingFolder: false,
  isDeletingFolder: false,
  gettigFolders: true,
  folderList: [],
  searchText:'',


  /* fields */
  isAddingCustomField: false,
  gettingFields: false,
  fields: [],
  oldFields:[],
  page:0,
  limit:10,
  totalFields: 0,
  fieldsObject: {
    page: 0,
    limit: 10,
    selectedFolder:null
  },


  isDeletingField: false,
  isUpdatingField: false,


  // MERGE FIELD STATE
  isAddingUserMergeFieldFolder: false,
  isUpdatingUserMergeFieldFolder: false,
  isDeletingUserMergeFieldFolder: false,
  gettigUserMergeFieldFolders: true,
  userMergeFieldFolderList: [],
  selectedUserMergeFieldFolder: null,
  userMergeFieldParams: {
    page: 0,
    limit: 10,
    selectedUserMergeFieldFolder:null
  },



};

const customFieldInsuranceReducer = (state = initialState, action) => {
  let { payload } = action;
  switch (action.type) {
    case ACTION.HANDLE_CUSTOM_FIELD_TAB_CHANGE:
      return {
         ...initialState, 
         customFieldTabValue: payload 
        };
    case ACTION.HANDLE_NEW_FOLDER_CREATE_MODAL:
      return {
        ...state,
        openNewFolderCreateModal: payload,
      };

    case ACTION.ADD_CUSTOM_FIELD_FOLDER:
      return {
        ...state,
        isAddingFolder: true,
      };
    case ACTION.ADD_CUSTOM_FIELD_FOLDER_SUCCESS:
      return {
        ...state,
        isAddingFolder: false,
        folderList: [payload, ...state.folderList],
        openNewFolderCreateModal:false
      };
    case ACTION.ADD_CUSTOM_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        isAddingFolder: false,
        openNewFolderCreateModal:false
      };
    case ACTION.GET_CUSTOM_FIELD_FOLDER:
      return {
        ...state,
        gettigFolders: true,
      };
    case ACTION.GET_CUSTOM_FIELD_FOLDER_SUCCESS:
      return {
        ...state,
        gettigFolders: false,
        folderList: payload,
        openNewFolderCreateModal: false,
      };
    case ACTION.GET_CUSTOM_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        gettigFolders: false,
        folderList: [],
      };
    case ACTION.UPDATE_CUSTOM_FIELD_FOLDER:
      return {
        ...state,
        isUpdatingFolder: true,
      };
    case ACTION.UPDATE_CUSTOM_FIELD_FOLDER_SUCCESS:
      let updatedFolderList = state.folderList.map((folder) =>
        folder.id === payload.id
          ? {
              ...folder,
              title: payload.title,
              folder_color: payload.folder_color,
              folder_icon: payload.folder_icon,
            }
          : folder
      );
      return {
        ...state,
        isUpdatingFolder: false,
        folderList: updatedFolderList,
      };

    case ACTION.UPDATE_CUSTOM_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        isUpdatingFolder: false,
      };

    case ACTION.DELETE_CUSTOM_FIELD_FOLDER:
      return {
        ...state,
        isDeletingFolder: true,
      };
    case ACTION.DELETE_CUSTOM_FIELD_FOLDER_SUCCESS:
     
      return {
        ...state,
        isDeletingFolder: false,
        folderList: state.folderList.filter(
          (folder) => folder.id !== payload.id
          )
      };

    case ACTION.DELETE_CUSTOM_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        isDeletingFolder: false,
      };
    case ACTION.HANDLE_SELECTED_CUSTOM_FIELD_FOLDER:
      return {
        ...state,
        fieldsObject: {
          ...state.fieldsObject,
          selectedFolder: payload,
          page: 0,
        }
      };
  

      case ACTION.ADD_CUSTOM_FIELD:
      return {
        ...state,
        isAddingCustomField: true,
      };
    case ACTION.ADD_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        isAddingCustomField: false,
        fields: [...state.fields, payload],
      };
    case ACTION.ADD_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        isAddingCustomField: false,
      };
    case ACTION.GET_CUSTOM_FIELD:
      return {
        ...state,
        gettingFields: true,
      };
    case ACTION.GET_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        gettingFields: false,
        fields: payload.data,
        totalFields: state.fieldsObject.page === 0 ? payload.total_count : state.totalFields,
        
      };
    case ACTION.GET_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        gettingFields: false,
      };
   
    case ACTION.HANDLE_PAGE_CHANGE:
      return {
        ...state,
        fieldsObject: {
          ...state.fieldsObject,
          page: payload,
        }
      }
    case ACTION.HANDLE_ROW_CHANGE:
      return {
        ...state,
        fieldsObject: {
          ...state.fieldsObject,
          limit: payload,
          page:0
        }
      }
    case ACTION.HANDLE_SEARCH:
      return {
        ...state,
        searchText: payload,
        page:0
        }

        case ACTION.UPDATE_CUSTOM_FIELD_INSURANCE:
      return {
        ...state,
        isUpdatingField: true,
      };
    case ACTION.UPDATE_CUSTOM_FIELD_INSURANCE_SUCCESS:
      return {
        ...state,
        isUpdatingField: false,
      };
    case ACTION.UPDATE_CUSTOM_FIELD_INSURANCE_FAILURE:
      return {
        ...state,
        isUpdatingField: false,
      };
    case ACTION.DELETE_CUSTOM_FIELD_INSURANCE:
      return {
        ...state,
        isDeletingField: true,
      };
    case ACTION.DELETE_CUSTOM_FIELD_INSURANCE_SUCCESS:
      return {
        ...state,
        isDeletingField: false,
      };
    case ACTION.DELETE_CUSTOM_FIELD_INSURANCE_FAILURE:
      return {
        ...state,
        isDeletingField: false,
      };
    case ACTION.HANDLE_CUSTOM_FIELD_STATUS:
      return {
        ...state,
        isUpdatingField: true,
      };
    case ACTION.HANDLE_CUSTOM_FIELD_STATUS_SUCCESS:
       
      return {
        ...state,
        isUpdatingField: false,
        fields: state.fields.map((field) =>
          field.id === payload.id
            ? {
                ...field,
                status: payload.status,
              }
            : field
        )
      };
    case ACTION.HANDLE_CUSTOM_FIELD_STATUS_FAILURE:
      return {
        ...state,
        isUpdatingField: false
        };
    case ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE:
      return {
        ...state,
        isUpdatingField: true
        };
    case ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE_SUCCESS:
      return {
        ...state,
        isUpdatingField:false
        };
    case ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE_FAILURE:
      return {
        ...state,
        isUpdatingField: false
        };
    case ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE:
      return {
        ...state,
        oldFields:state.fields,
        fields: payload.reOrderedFields
      };
    case ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE_SUCCESS:
      return {
        ...state,
        oldFields:state.fields,
      }
    case ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE_FAILURE:
      return {
        ...state,
        fields: state.oldFields
      };
    case ACTION.RESET_STATE:
      return {
        ...initialState,
      };

    // MERGE FIELD ACTION

    case ACTION.ADD_USER_MERGE_FIELD_FOLDER:
      return {
        ...state,
        isAddingUserMergeFieldFolder: true,
      };
    case ACTION.ADD_USER_MERGE_FIELD_FOLDER_SUCCESS:
      return {
        ...state,
        isAddingUserMergeFieldFolder: false,
        userMergeFieldFolderList: [
          ...state.userMergeFieldFolderList,
          payload,
        ],
      };
    case ACTION.ADD_USER_MERGE_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        isAddingUserMergeFieldFolder: false,
      };
    case ACTION.GET_USER_MERGE_FIELD_FOLDER:
      return {
        ...state,
        gettigUserMergeFieldFolders: true,
        };
    case ACTION.GET_USER_MERGE_FIELD_FOLDER_SUCCESS:
      return {
        ...state,
        gettigUserMergeFieldFolders: false,
        userMergeFieldFolderList: payload,
        };
    case ACTION.GET_USER_MERGE_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        gettigUserMergeFieldFolders: false,
        };
    case ACTION.UPDATE_USER_MERGE_FIELD_FOLDER:
      return {
        ...state,
        isUpdatingUserMergeFieldFolder: true,
        };
    case ACTION.UPDATE_USER_MERGE_FIELD_FOLDER_SUCCESS:
      return {
        ...state,
        isUpdatingUserMergeFieldFolder: false,
        userMergeFieldFolderList: state.userMergeFieldFolderList.map((folder) =>
          folder.id === payload.id
            ? {
                ...folder,
                title: payload.title,
                folder_color: payload.folder_color,
                folder_icon: payload.folder_icon,
              }
            : folder
        )
      };
    case ACTION.UPDATE_USER_MERGE_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        isUpdatingUserMergeFieldFolder: false,
        };
    case ACTION.DELETE_USER_MERGE_FIELD_FOLDER:
      return {
        ...state,
        isDeletingUserMergeFieldFolder: true,
        };
    case ACTION.DELETE_USER_MERGE_FIELD_FOLDER_SUCCESS:
      return {
        ...state,
        isDeletingUserMergeFieldFolder: false,
        userMergeFieldFolderList: state.userMergeFieldFolderList.filter(
          (folder) => folder.id !== payload
          ),
          };
    case ACTION.DELETE_USER_MERGE_FIELD_FOLDER_FAILURE:
      return {
        ...state,
        isDeletingUserMergeFieldFolder: false,
        };
    case ACTION.HANDLE_SELECTED_USER_MERGE_FIELD_FOLDER:
      return {
        ...state,
        userMergeFieldParams: {
          ...state.userMergeFieldParams,
          selectedUserMergeFieldFolder: payload,
          page: 0,
        }
      };
    


    default:
      return state;
  }
};

export default customFieldInsuranceReducer;
