import { connect } from "react-redux";
import {
  _handleLeadSourcePagination,
  _handleLeadSourceReload,
  getLeadSourceByPagination,
} from "../../../actions/leadSourceAction";
import EachRow from "./EachRow";
import LeadSourceSkeleton from "./LeadSourceSkeleton";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  headerText: {
    fontSize: "18px !important",
  },
});
const TableApp = (props) => {
  const classes = useStyles();
  const { need_to_reload, per_page, search_text, current_page } =
    props.leadSourceReducer;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (need_to_reload) {
      props._handleLeadSourceReload(false);
      if (!loading) {
        setLoading(true);
        fetchLeadSourceData();
      }
    }
  }, [need_to_reload]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      fetchLeadSourceData();
    }
  }, [per_page, search_text]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLeadSourceData = () => {
    props.getLeadSourceByPagination(
      { perPage: per_page, query: search_text, currentPage: current_page },
      (res) => {
        setList(res.data);
        setLoading(false);
        props._handleLeadSourcePagination({
          totalRecords: res.total,
          totalPages: res.last_page,
        });
      }
    );
  };

  return (
    <>
      {loading ? (
        <LeadSourceSkeleton />
      ) : (
        <table>
          <thead>
            <tr className="header">
              <th
                className={classes.headerText}
                style={{ paddingLeft: "35px" }}
              >
                Lead Source
              </th>
              <th
                className={classes.headerText}
                style={{ textAlign: "center" }}
              >
                Created
              </th>
              <th
                className={classes.headerText}
                style={{ textAlign: "center" }}
              >
                Defined By
              </th>
              <th
                className={classes.headerText}
                style={{ textAlign: "center" }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="tbody_content">
            {!loading && list.length === 0 && (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  No lead source found
                </td>
              </tr>
            )}

            {!loading ? (
              list.map((eachItem, index) => {
                return (
                  <EachRow eachItem={eachItem} eachIndex={index} key={index} />
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  leadSourceReducer: state.leadSourceReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLeadSourceByPagination: (params, callback) =>
    dispatch(getLeadSourceByPagination(params, callback)),
  _handleLeadSourcePagination: (params) =>
    dispatch(_handleLeadSourcePagination(params)),
  _handleLeadSourceReload: (params) =>
    dispatch(_handleLeadSourceReload(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableApp);
