import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const TableSkeleton = () => {
  return (
    <div>
      <Box
        border={"1px solid #e5e5e5"}
        padding={2}
        borderRadius={4}
        sx={{ margin: "16px 0", height: `calc(100vh - 445px) !important` }}
      >
        {Array.from({ length: 6 }).map((rowNum, i) => (
          <Box display={"flex"} sx={{ gridGap: "24px" }} key={rowNum} mb={2}>
            <Skeleton variant="text" width="20%" height={50} />
            <Skeleton variant="text" width="20%" height={50} />
            <Skeleton variant="text" width="20%" height={50} />
            <Skeleton variant="text" width="20%" height={50} />
            <Skeleton variant="text" width="20%" height={50} />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default TableSkeleton;
