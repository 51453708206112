import React from "react";
import {
  Backdrop,
  Button,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";

const modalStyles = makeStyles((theme) => ({
  wrapperMain: {
    marginLeft: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: (props) => props.modalWidth,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    padding: "16px",
    color: "#fff",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d3d3d3",
  },
  modalBody: {
    padding: "20px",
  },
  modalFooter: {
    borderEndEndRadius: "8px",
    borderEndStartRadius: "8px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    borderTop: "1px solid #d3d3d3",
    paddingRight: "16px",
    height: "70px",
  },
  submitButton: {
    color: "#fff !important",
    width: "100px !important",
    fontWeight: 600,
    textTransform: "capitalize !important",
    backgroundColor: "#3C7EF3",
    "&:hover, &:focus": {
      backgroundColor: "#3E50F7 !important",
    },
    "&.Mui-disabled": {
        backgroundColor: "#e0e0e0 !important",
        color: "#bdbdbd !important",
      },
  },
  backButton: {
    color: "#3C7EF3 !important",
    width: "100px !important",
    fontWeight: 600,
    textTransform: "capitalize !important",
    backgroundColor: "#FFF !important",
    border: "1px solid #3C7EF3 !important",
  },
  typographyStyle: {
    height: "unset !important",
    color: "#133159",
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: "20px",
    textAlign: "center",
  },
}));

const CustomDynamicModal = ({
  modalWidth = 550,
  open,
  onClose,
  headerIconSrc,
  headerSvgComponent,
  headerTitle,
  headerSubTitle,
  closeButtonText = "Cancel",
  submitButtonText = "Save",
  children,
  handleSubmit,
  submitButtonDisabled
}) => {
  const classes = modalStyles({ modalWidth });

  return (
    <div className={classes.wrapperMain}>
      <Modal
        open={open}
        onClose={onClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
             
                <div style={{ width: "50px", height: "50px" }}>
                {headerIconSrc && (
                  <img
                    src={headerIconSrc}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  )}
                  { headerSvgComponent && (
                      <div>
                        {headerSvgComponent}
                      </div>
                  )}
                </div>
              
              <div style={{ marginLeft: "16px" }}>
                <Typography
                  style={{
                    height: "unset !important",
                    color: "#133159",
                    fontWeight: 600,
                  }}
                  variant="h5"
                >
                  {headerTitle}
                </Typography>
                {headerSubTitle && (
                  <Typography
                    style={{
                      height: "unset !important",
                      color: "#133159",
                      fontWeight: 500,
                      fontSize: "15px",
                    }}
                    variant="h5"
                  >
                    {headerSubTitle}
                  </Typography>
                )}
              </div>
            </div>

            <div className={classes.modalBody}>{children}</div>
            <div className={classes.modalFooter}>
              <Button onClick={onClose} className={classes.backButton}>
                {closeButtonText}
              </Button>

              <Button disableElevation disabled={submitButtonDisabled} onClick={handleSubmit} className={classes.submitButton}>
                {submitButtonText}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CustomDynamicModal;
