import React, {useContext} from "react";
import {
    Backdrop,
    Button,
    Fade,
    Modal,
    makeStyles,
    styled,
} from "@material-ui/core";
import Utils from "../../../helpers/Utils";
import {HistoryProvider} from "../../../App";

const modalStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        width: 725,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],
        "&:focus-visible": {
            outline: "none",
        },
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
    },
    description: {
        fontSize: 16,
        fontWeight: 400,
    },
    logoContainer: {
        width: "80px",
        height: "80px",
    },
    logo: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    modalBody: {
        padding: "16px",
    },
}));

const Wrapper = styled("div")(({ theme }) => ({
    minWidth: 650,
    minHeight: 350,
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "& .modalHeader": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        gap: "5px",
    },

    "& .modalBody": {
        padding: "16px 24px 0",
        textAlign: "center",

        "& .bodyContent": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
        },
    },

    "& .actionButton": {
        backgroundColor: "#3E50F7",
        borderRadius: "4px",
        minWidth: 130,
        padding: "8px 5px",
        fontWeight: 600,
        fontSize: "15px",
        textTransform: "none",
        color: "#fff",
        "&:hover, &:focus": {
            backgroundColor: "#3E50F7",
        },
    },
    "& .actionButtonClose": {
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        minWidth: 130,
        padding: "8px 5px",
        fontWeight: 600,
        textTransform: "none",
        fontSize: "16px",
        color: "#000",
        border: "1px solid #D0D5DD",
        "&:hover, &:focus": {
            backgroundColor: "#ffffff",
        },
    },

    "& .modalFooter": {
        padding: 16,
        display: "flex",
        justifyContent: "center",
        gap: 8,
        marginBottom: "20px",
    },
}));

const cleanNumber = (number) => {
    try{
        if(isNaN(number)){
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split(".");
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, "") + ".00";
        }
        const decimalPart = parts[1].length < 2 ? parts[1] + "0" : parts[1];
        return `${parts[0]}.${decimalPart}`;
    }catch(err){
        return "x.xx";
    }
}

const NumberPurchaseErrorCustomModal = ({
    open,
    onClose,
    userCurrenCredit,
    virtualNumberCost,
}) => {
    const history = useContext(HistoryProvider);
    const classes = modalStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <Wrapper className="contentWrapper">
                        <div className="modalBody">
                            <div className="bodyContent">
                                <div>
                                    <div className="modalHeader">
                                        <div className={classes.logoContainer}>
                                            <img src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/conatct_source/1.0.0/telephone_icon.png`} alt="logo" className={classes.logo} />
                                        </div>
                                        <div className={classes.title}>
                                            Unable to Purchase a Phone Number
                                        </div>
                                        {Utils.getAccountData("userIsOwner")  ? (
                                            <div className={classes.description}>
                                                To purchase a number you'll first need to add more
                                                credits to your account.
                                            </div>
                                        ) : (
                                            <div className={classes.description}>
                                                To purchase a number you'll first need to add more
                                                credits to your account.
                                                <br />
                                                Please notify your team admin.
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.modalBody}>
                                        <div>Phone numbers are charged at a rate of: ${cleanNumber(virtualNumberCost)}/month.
                                        </div>
                                        <div>Your credit balance is currently: ${cleanNumber(userCurrenCredit)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modalFooter">
                            {
                                Utils.getAccountData("userIsOwner") && (
                                    <Button
                                        className={"actionButton"}
                                        disableElevation
                                        onClick={()=>{
                                            onClose();
                                            history.push("/billing");
                                        }}
                                    >
                                        Add Credits
                                    </Button>
                                )
                            }
                            <Button
                                className={"actionButtonClose"}
                                disableElevation
                                onClick={onClose}
                            >
                                Exit
                            </Button>
                        </div>
                    </Wrapper>
                </div>
            </Fade>
        </Modal>
    );
};

export default NumberPurchaseErrorCustomModal;