import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  modalContainer: {
    minWidth: "540px",
    maxWidth: "640px",
    background: "white",
    borderRadius: "14px",
    boxShadow: "7px 7px 10px 7px #ddd",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    gap: "5px",
  },
  logoContainer: {
    width: "100px",
    height: "100px",
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    paddingLeft: "5px",
  },
  content: {
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
  },
  description: {
    padding: "5px",
    textAlign: "center",
    fontSize: "16px",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    padding: "15px",
  },
  button: {
    padding: "10px 20px 10px 20px",
    borderRadius: "6px",
    cursor: "pointer",
    minWidth: "120px",
    textAlign: "center",
    fontWeight: 600,
  },
  closeButton: {
    backgroundColor: "#ffffff",
    border: "2px solid #d9dce0",
    color: "#333",
    fontSize: "16px",
    "&:focus, &:hover": {
      backgroundColor: "#f6f6f6",
    },
  },
  confirmButton: {
    backgroundColor: "#4550f8",
    color: "#fff",
    border: "none",
    fontSize: "16px",
    "&:focus, &:hover": {
      backgroundColor: "#000edd",
    },
  },
});

const CustomConfirmModal = ({
  onClose,
  handleConfirm,
  closeButtonText = "Close",
  confirmButtonText = "Confirm",
  showConfirmButton = true,
  showCloseButton = true,
  description = "",
  imgSrc = "",
  title = "",
}) => {
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onClose();
    handleConfirm();
  }, [handleConfirm, onClose]);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.header}>
        {imgSrc && (
          <div className={classes.logoContainer}>
            <img src={imgSrc} alt="logo" className={classes.logo} />
          </div>
        )}
      </div>

      <div className={classes.content}>
        {title && <span className={classes.title}>{title}</span>}

        {description && (
          <div className={classes.description}>{description}</div>
        )}
        <div className={classes.actions}>
          {showCloseButton && (
            <button
              onClick={handleClose}
              className={`${classes.button} ${classes.closeButton}`}
            >
              {closeButtonText}
            </button>
          )}
          {showConfirmButton && (
            <button
              onClick={handleSubmit}
              className={`${classes.button} ${classes.confirmButton}`}
            >
              {confirmButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomConfirmModal;
