import { IconList } from "./IconList";

export const PROFILE_TAB = {
  PROFILE_INFO: 1,
  CHANGE_PASSWORD: 2,
  COMPANY_INFO: 3,
  FAVICON: 4,
};

export const GENERAL_ACTIVE_TAB = {
  PROFILE: 1,
  TEAM: 2,
  SETTINGS: 3,
};

export const STATUS_TRUE = 1;
export const STATUS_FALSE = 0;

const number_field = 1;
const email_field = 2;
const first_name_field = 3;
const last_name_field = 4;
const address_field = 5;
const city_field = 6;
const state_field = 7;
const country_field = 8;
const zip_field = 9;
const url_field = 10;
const deal_value_field = 11;
const birth_date_field = 12;
const anniversary_date_field = 13;
const company_name = 14;
export const getContactFormFields = [
  {
    value: number_field,
    label: "Number",
    type: "phone",
    tag: "[[contact_phone_number]]",
    length: "20",
    format: "US-Number",
  },
  {
    value: email_field,
    label: "Email",
    type: "email",
    tag: "[[email]]",
    length: "200",
    format: "email",
  },
  {
    value: first_name_field,
    label: "First Name",
    type: "text",
    tag: "[[first_name]]",
    length: "100",
    format: "string",
  },
  {
    value: last_name_field,
    label: "Last Name",
    type: "text",
    tag: "[[last_name]]",
    length: "100",
    format: "string",
  },
  {
    value: address_field,
    label: "Address",
    type: "text",
    tag: "[[contact_street_address]]",
    length: "200",
    format: "string",
  },
  {
    value: city_field,
    label: "City",
    type: "text",
    tag: "[[contact_city]]",
    length: "100",
    format: "string",
  },
  {
    value: state_field,
    label: "State",
    type: "text",
    tag: "[[contact_state]]",
    length: "100",
    format: "string",
  },
  {
    value: country_field,
    label: "Country",
    type: "text",
    tag: "[[contact_country]]",
    length: "100",
    format: "string",
  },
  {
    value: zip_field,
    label: "Zip Code",
    type: "number",
    tag: "[[contact_zip_code]]",
    length: "15",
    format: "1-15",
  },
  {
    value: url_field,
    label: "url",
    type: "url",
    tag: "[[contact_url]]",
    length: "15",
    format: "url",
  },
  {
    value: deal_value_field,
    label: "Deal Value",
    type: "number",
    tag: "[[contact_deal_value]]",
    length: "255",
    format: "1-15",
  },
  {
    value: birth_date_field,
    label: "Birth Date",
    type: "date",
    tag: "[[contact_birth_date]]",
    length: "date",
    format: "date",
  },
  {
    value: anniversary_date_field,
    label: "Anniversary Date",
    type: "date",
    tag: "[[contact_anniversary_date]]",
    length: "date",
    format: "date",
  },
  {
    value: company_name,
    label: "Company Name",
    type: "",
    tag: "[[contact_company_name]]",
    length: "---",
    format: "",
  },
];

export const DEFAULT_VALUE = 0;
export const TEXT = 1;
export const MULTILINE_TEXT = 2;
export const NUMERIC = 3;
export const DATE = 4;
export const CHECKBOX = 5;
export const SELECT = 6;
export const RADIO = 7;
export const PHONE = 8;
export const ZIP_CODE = 9;
export const WEBSITE = 10;
export const DATETIME = 11;

export const ALL_FIELD_NAME = {
  1: "Text",
  2: "Multiline Text",
  3: "Number",
  4: "Date",
  5: "Checkbox",
  6: "Dropdown",
  7: "Radio",
  8: "Phone",
  9: "Zip code",
  10: "Website",
  11: "Datetime",
};

export const All_FIELD_TYPE = [
  { value: TEXT, label: "Text" },
  { value: MULTILINE_TEXT, label: "Multiline Text" },
  { value: NUMERIC, label: "Number" },
  { value: DATE, label: "Date" },
  { value: CHECKBOX, label: "Checkbox" },
  { value: SELECT, label: "Dropdown" },
  { value: RADIO, label: "Radio" },
  { value: PHONE, label: "Phone" },
  { value: ZIP_CODE, label: "Zip code" },
  { value: WEBSITE, label: "Website" },
  { value: DATETIME, label: "Datetime" },
];

export const NOTIFICATION_ICON = {
  0: IconList.smsIcon,
  1: IconList.emailIcon,
  2: IconList.callIcon,
  3: IconList.shareIcon,
  4: IconList.formIcon,
};

export const LAYOUT_SETTINGS = {
  SHORT_MENU: 19,
  TOPBAR_BACKGROUND: 20,
  TOPBAR_TEXT_COLOR: 21,
  SIDEBAR_BACKGROUND: 22,
  SIDEBAR_TEXT_COLOR: 23,
  ACCENT_COLOR: 26,
};

export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export const SITE_NOTIFICATION = {
  SMS: 0,
  EMAIL: 1,
  CALL: 2,
  CAMPAIGN_PAUSE: 5,
};
export const TWILIO_SETUP = {
  policyTypeLowVolumn: 1,
  policyTypeHighVolumn: 2,
};

export const A2P_REGISTRATION_TYPES = {
  SOLE_PROPRIETOR: "SOLE_PROPRIETOR",
};

export const resourceData = {
  name: "",
  redirect_uris: [],
  created_by: "USER",
  resourcePermissions: [
    {
      resourceName: "CONTACT",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "PIPELINE",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "STAGE",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "USER",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:false
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "ACTIVITY",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "CAMPAIGN",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "TAG",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "CUSTOM_FIELD",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "FILE",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "APPOINTMENT",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "PERSONALIZED_FIELD",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
    {
      resourceName: "FORM",
      actions: [
        {
          hasPermission: false,
          actionName: "READ",
          show:true
        },
        {
          hasPermission: false,
          actionName: "WRITE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "EDIT",
          show:false
        },
        {
          hasPermission: false,
          actionName: "DELETE",
          show:true
        },
        {
          hasPermission: false,
          actionName: "SHARE",
          show:false
        },
      ],
    },
  ],
};
export const LOOKUP_TYPE = {
    CARRIER_LOOKUP: "CARRIER_LOOKUP",
    EMAIL_LOOKUP: "EMAIL_LOOKUP",
    DNC_LOOKUP: "DNC_LOOKUP",
    SKIP_TRACING_LOOKUP: "SKIP_TRACING_LOOKUP"
};

export const SEARCH_CATEGORY = {
    HOUSE_CURRENT_RESIDENT: "HOUSE_CURRENT_RESIDENT",
    HOUSE_CURRENT_OWNER: "HOUSE_CURRENT_OWNER"
};

export const ACTION = {
    ADD_NEW_CONTACT: "ADD_NEW_CONTACT",
    ADD_UNDER_CONTACT: "ADD_UNDER_CONTACT",
    NO_ACTION: "NO_ACTION"
};

export const EMAIL_PROVIDER = {
  POSTMARK: "POSTMARK",
  NYLAS: "NYLAS"
};

export const EMAIL_PROVIDER_DEFAULT = {
  YES: "YES",
  NO: "NO"
};

export const EMAIL_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  FAILED: "FAILED",
  WARNING: "WARNING"
};

export const PRODUCT_SALES_REPORT = {
  DASHBOARD: 1,
  PRODUCTS: 2,
  GOALS: 3,
  TEAM_SALES_REPORT: 4,
  PRODUCT_SALES_REPORT: 5,
  SALES_VS_GOAL_REPORT: 6,
  DEAL_REPORT: 7,
  POLICY_TYPES: 8,
  INSURANCE_COMPANIES: 9,
  LOST_REASONS: 10,
  RECYCLE_EVENT: 11,
  LEAD_SOURCE:12

};

export const activeStatusClass = (status) => {
  switch (status) {
    case "WIN":
      return "dealWon";
    case "LOST":
      return "dealLost";
    default:
      return "dealActive";
  }
};

export const activeStatus = (status) => {
  switch (status) {
    case "WIN":
      return "Won";
    case "LOST":
      return "Lost";
    default:
      return "Active";
  }
};

export const EMPTY_DATA_IMG = "https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png";

export const FILE_FORMAT = {
  PDF: "PDF",
  CSV: "CSV"
};

export const AGENCY_BUSINESS_TYPE = {
  INSURANCE: "INSURANCE",
  REGULAR: "REGULAR"
};

export const INSURANCE_COMPANIES_STATUS = {
  ACTIVE:'ACTIVE',
  INACTIVE:'INACTIVE'
}
export const MYTAGS = "PRIVATE"
export const GLOBALTAG = "GLOBAL"

export const PRIVACY_LEVEL = {
  GLOBAL: "GLOBAL",
  PRIVATE: "PRIVATE"
};

export const PRIVACY_LEVEL_TAB_VALUE = {
  GLOBAL: 0,
  PRIVATE: 1
}

export const PRIVACY_OPTION = [
  { label: "My Tags", value: MYTAGS },
  { label: "Global Tag", value: GLOBALTAG },
]

export const SORT_BY = {
  TOTAL_CONTACT: "TOTAL_CONTACT",
  CREATED: "CREATED",
}

export const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc",
}

export const PERMISSION_MODULES = {
  MANAGE_BILLING: 0,
  MANAGE_USERS: 1,
  MANAGE_GLOBAL_ITEMS: 2,
  EXPORT_CONTACTS: 3,
  EDIT_LEAD_OWNER: 4,
  MANAGE_OTHER_USER_GOALS: 5,
  MANAGE_OTHER_USER_REPORTS: 6,
  MANAGE_OTHER_USER_CALENDAR: 7,
  MANAGE_OTHER_USER_SCHEDULER: 8,
};

export const PERMISSION_LEVELS = {
  READ: 2,
  WRITE: 4,
  DELETE: 8,
};

export const PRIVACY_OPTION_CUSTOM_FIELD = [
  { label: "My Field", value: PRIVACY_LEVEL.PRIVATE },
  { label: "Global Field", value: PRIVACY_LEVEL.GLOBAL},
];

export const PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT = [
  { label: "My Field", value: PRIVACY_LEVEL.PRIVATE },
];

export const FieldTypes = [
  {value: 1, title: 'Text', new: false},
  {value: 2, title: 'Multiline Text', new: false},
  {value: 3, title: 'Number', new: false},
  {value: 4, title: 'Date', new: false},
  {value: 5, title: 'Checkbox', new: false},
  {value: 6, title: 'Dropdown', new: false},
  {value: 7, title: 'Radio', new: false},
  {value: 8, title: 'Phone', new: false},
  {value: 9, title: 'Zip Code', new: false},
  {value: 10, title: 'Website', new: false},
  {value: 11, title: 'Datetime', new: true}
  // {value: 'new', title: 'Custom', new: true}
];