import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import SwitchC from "../customField/subComponents/Switch";
import BasicSelect from "../products/policyTypes/content/BasicSelect";
import {BasicInput} from "../products/productsStyle";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import PermissionDescriptionModal from "./PermissionDescriptionModal";
import CollaboratorsDescriptionModal from "./CollaboratorsDescriptionModal";
import PermissionUtils from "../../helpers/PermissionUtils";
import {PERMISSION_MODULES_LABELS, PERMISSION_ROLES} from "../../constants/PermissionConst";
import {useDispatch, useSelector} from "react-redux";
import {getUserCreationDetails, resetToInitial, saveUser} from "../../reducers/manageUser/manageUserSlice";
import {selectManageUserState} from "../../reducers/manageUser/manageUserSelector";
import Utils from "../../helpers/Utils";
import AddUserModalSkeleton from "./AddUserModalSkeleton";
import {useModalStyles} from "./styles";

const ROLE_LABELS = [
  { label: "Standard User", value: PERMISSION_ROLES.STANDARD_USER },
  { label: "Limited User", value: PERMISSION_ROLES.LIMITED_USER }
];

const ROLE_MAP_ROLE_ID = {
  [PERMISSION_ROLES.STANDARD_USER]: 2,
  [PERMISSION_ROLES.LIMITED_USER]: 3
};

const ROLE_ID_MAP_ROLE = {
  2: PERMISSION_ROLES.STANDARD_USER,
  3: PERMISSION_ROLES.LIMITED_USER
};

const AddNewUserModal = ({ userId = null, collaboratorId = null, open, onClose }) => {
  const dispatch = useDispatch();
  const { subUsers, roleDefaultPermissions, editUser, savingUser, loadingUserCreationDetails } = useSelector(selectManageUserState);
  const classes = useModalStyles();
  const [userInfo, setUserInfo] = useState({
    full_name: "",
    email: "",
    phone: "",
    permission_value: "0",
    role_id: null,
    edit_note: false,
    collaborator: "",
  });
  const [role, setRole] = useState("");
  const [errors, setErrors] = useState({
    full_name: "",
    email: "",
    phone: "",
    role: "",
  });

  const [isPermissionDescription, setIsPermissionDescription] = useState(false);
  const [isCollaboratorDescription, setIsCollaboratorDescription] = useState(false);

  useEffect(() => {
    dispatch(getUserCreationDetails({
      user_id: userId,
      team_id: Utils.getAccountData("teamId")
    }));
  },[userId]);

  useEffect(() => {
    if (editUser && userId){
      setUserInfo({
        full_name: editUser.full_name,
        email: editUser.email,
        phone: editUser.phone,
        permission_value: editUser.permission_value || "0",
        collaborator: collaboratorId || "",
        role_id: editUser.role_id,
        edit_note: editUser.isEnabledNoteEdit === "1",
      });

      setRole(ROLE_ID_MAP_ROLE[editUser.role_id]);
    }
  },[editUser, userId, collaboratorId]);

  const handleUserInfo = (e) => {
    setErrors({
      full_name: "",
      email: "",
      phone: "",
      role: "",
    });
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleRole = (event) => {
    setErrors({
      ...errors,
      role: "",
    });
    if (roleDefaultPermissions[event.target.value]) {
      setUserInfo((prevState)=>({
        ...prevState,
        role_id: ROLE_MAP_ROLE_ID[event.target.value],
        permission_value: roleDefaultPermissions[event.target.value],
        collaborator: "",
      }));
    }
    setRole(event.target.value);
  };

  const handlePermissionStatus = (checked, module, permissions) => {
    let permission_value = userInfo.permission_value;
    if (checked) {
      permission_value = PermissionUtils.addPermission(PermissionUtils.toBigInt(permission_value), module, permissions);
    }else {
      permission_value = PermissionUtils.removePermission(PermissionUtils.toBigInt(permission_value), module, permissions);
    }

    setUserInfo((prevState)=>({
      ...prevState,
      permission_value: permission_value.toString()
    }));

    setUserInfo((prevState)=>({
          ...prevState,
          collaborator: "",
        }
    ));
  };

  const handleCollaborators = (event) => {
    setUserInfo((prevState)=>({
      ...prevState,
      collaborator: event.target.value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate Full Name
    if (!userInfo.full_name.trim()) {
      newErrors.full_name = "Full Name is required.";
    }

    // Validate Email
    if (!userInfo.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!Utils.validateEmail(userInfo.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Validate Phone
    if (!userInfo.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!Utils.validateUsMobileNumber(userInfo.phone)) {
      newErrors.phone = "Invalid phone number.";
    }

    // Validate Permission Level
    if (!role.trim()) {
      newErrors.role = "Permission level is required.";
    }

    if (!userInfo.role_id){
      newErrors.role = "Permission level is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {

      const payload = {
        user_id: userId,
        limit: '-1',
        agency_id: Utils.getAccountData("agencyId"),
        role_id: userInfo.role_id,
        full_name: userInfo.full_name,
        email: userInfo.email,
        phone: userInfo.phone,
        can_see_all_conversation: 0,
        templateUser : false,
        snapshot_id: null,
        allowNonUsNumber: false,
        isEnabledNoteEdit: false,
        collaborator_id: userInfo.collaborator,
        permission_value: userInfo.permission_value,

        callback: () => {
          handleClose();
        }
      };

      dispatch(saveUser(payload));
    }
  };

  const handleClose = () => {
    dispatch(resetToInitial());
    onClose();
  };

  const subUsersExceptSelf = editUser && editUser.id ? subUsers.filter(user => user.id !== editUser.id) : subUsers;
  
  return (
    <>
      <Dialog
        minWidth={500}
        open={open}
        onClose={handleClose}
        className={classes.modalWrapper}
      >
        <div className="modalHeader">
          <div className="headerLeft">
            <img
              src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/manage_users_icon.png"
              alt=""
              height={50}
            />

            <div className="headerText">
              <h5 className="title">New User</h5>
              <p className="description">
                Add a new user to your team and set their permission levels.
              </p>
            </div>
          </div>
        </div>

        <div className="modalBody">
          <div className={classes.contentWrapper}>

            {
              loadingUserCreationDetails ?
                  <AddUserModalSkeleton/> :
                  <div className={classes.section}>
                    <div>
                      <Typography variant="h6" className={classes.sectionTitle}>
                        New User Info
                      </Typography>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" className={classes.fieldLabel}>
                            Full Name <span style={{color: "var(--red)"}}>*</span>
                          </Typography>
                          <BasicInput
                              name="full_name"
                              value={userInfo.full_name}
                              onChange={handleUserInfo}
                              fullWidth
                              placeholder="Sally Doe"
                              required
                          />
                          {errors.full_name && (
                              <span className="error">{errors.full_name}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" className={classes.fieldLabel}>
                            Email <span style={{color: "var(--red)"}}>*</span>
                          </Typography>
                          <BasicInput
                              name="email"
                              type="email"
                              value={userInfo.email}
                              onChange={handleUserInfo}
                              fullWidth
                              placeholder="sallydoe@example.com"
                          />
                          {errors.email && (
                              <span className="error">{errors.email}</span>
                          )}
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="body1" className={classes.fieldLabel}>
                            Phone <span style={{color: "var(--red)"}}>*</span>
                          </Typography>
                          <BasicInput
                              name="phone"
                              value={userInfo.phone}
                              onChange={handleUserInfo}
                              fullWidth
                              placeholder="248-410-6153"
                          />
                          {errors.phone && (
                              <span className="error">{errors.phone}</span>
                          )}
                        </Grid>
                      </Grid>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                          Permission Level{" "}
                          <span style={{color: "var(--red)"}}>*</span>
                        </Typography>
                        <Typography variant="h6" className={classes.sectionSubtitle}>
                          To learn More about the different permission levels{" "}
                          <span
                              style={{cursor: "pointer", color: "#3f51b5"}}
                              onClick={() => setIsPermissionDescription(true)}
                          >
                      click here
                    </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BasicSelect
                            fullWidth
                            name="role"
                            defaultText="Select permission level"
                            value={role}
                            options={ROLE_LABELS}
                            mapping={{label: "label", value: "value"}}
                            onChange={handleRole}
                        />

                        {errors.role && (
                            <span className="error">{errors.role}</span>
                        )}
                      </Grid>
                    </Grid>

                    <div>
                      <TableContainer component={"span"}>
                        <Table className={classes.table} aria-label="simple table">
                          {/* line 1 start*/}
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              {
                                  PERMISSION_MODULES_LABELS[role] &&
                                  PERMISSION_MODULES_LABELS[role]
                                      .slice(0, 4)
                                      .map((item, index) => (
                                          <TableCell
                                              align="center"
                                              className={classes.tableHeader}
                                              key={index}
                                          >
                                            <div className="flexWrapper">
                                              <span className="permissionName">{item.name}</span>
                                              <BootstrapTooltip
                                                  title={item.description}
                                                  arrow
                                                  placement="top"
                                              >
                                                <HelpIcon/>
                                              </BootstrapTooltip>
                                            </div>
                                          </TableCell>
                                      ))
                              }
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow className={classes.tableRowWrapper}>
                              {
                                  PERMISSION_MODULES_LABELS[role] &&
                                  PERMISSION_MODULES_LABELS[role]
                                      .slice(0, 4)
                                      .map((item, index) => (
                                          <TableCell key={index} align="center">
                                            <SwitchC
                                                brandColor={"#3f51b5"}
                                                status={PermissionUtils.hasPermission(
                                                    PermissionUtils.toBigInt(userInfo.permission_value),
                                                    item.module_id,
                                                    item.permissions
                                                )}
                                                onChange={(checked) => {
                                                  handlePermissionStatus(
                                                      checked,
                                                      item.module_id,
                                                      item.permissions
                                                  );
                                                }}
                                                onText="On"
                                                offText="Off"
                                            />
                                          </TableCell>
                                      ))
                              }
                            </TableRow>
                          </TableBody>
                          {/* line 1 end*/}

                          {/* line 2 start */}
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              {
                                  PERMISSION_MODULES_LABELS[role] &&
                                  PERMISSION_MODULES_LABELS[role]
                                      .slice(4, 8)
                                      .map((item, index) => (
                                          <TableCell
                                              align="center"
                                              className={classes.tableHeader}
                                              key={index}
                                          >
                                            <div className="flexWrapper">
                                              <span className="permissionName">{item.name}</span>
                                              <BootstrapTooltip
                                                  title={item.description}
                                                  arrow
                                                  placement="top"
                                              >
                                                <HelpIcon/>
                                              </BootstrapTooltip>
                                            </div>
                                          </TableCell>
                                      ))
                              }
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow className={classes.tableRowWrapper}>
                              {
                                  PERMISSION_MODULES_LABELS[role] &&
                                  PERMISSION_MODULES_LABELS[role]
                                      .slice(4, 8)
                                      .map((item, index) => (
                                          <TableCell key={index} align="center">
                                            <SwitchC
                                                brandColor={"#3f51b5"}
                                                status={PermissionUtils.hasPermission(
                                                    PermissionUtils.toBigInt(userInfo.permission_value),
                                                    item.module_id,
                                                    item.permissions
                                                )}
                                                onChange={(checked) => {
                                                  handlePermissionStatus(
                                                      checked,
                                                      item.module_id,
                                                      item.permissions
                                                  );
                                                }}
                                                onText="On"
                                                offText="Off"
                                            />
                                          </TableCell>
                                      ))
                              }
                            </TableRow>
                          </TableBody>
                          {/* line 2 end */}

                          {/* line 3 start */}
                          <TableHead className={classes.tableHead}>
                            <TableRow>
                              {
                                  PERMISSION_MODULES_LABELS[role] &&
                                  PERMISSION_MODULES_LABELS[role]
                                      .slice(8, 9)
                                      .map((item, index) => (
                                          <TableCell
                                              align="center"
                                              className={classes.tableHeader}
                                              key={index}
                                          >
                                            <div className="flexWrapper">
                                              <span className="permissionName">{item.name}</span>
                                              <BootstrapTooltip
                                                  title={item.description}
                                                  arrow
                                                  placement="top"
                                              >
                                                <HelpIcon/>
                                              </BootstrapTooltip>
                                            </div>
                                          </TableCell>
                                      ))
                              }
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow className={classes.tableRowWrapper}>
                              {
                                  PERMISSION_MODULES_LABELS[role] &&
                                  PERMISSION_MODULES_LABELS[role]
                                      .slice(8, 9)
                                      .map((item, index) => (
                                          <TableCell key={index} align="center">
                                            <SwitchC
                                                brandColor={"#3f51b5"}
                                                status={PermissionUtils.hasPermission(
                                                    PermissionUtils.toBigInt(userInfo.permission_value),
                                                    item.module_id,
                                                    item.permissions
                                                )}
                                                onChange={(checked) => {
                                                  handlePermissionStatus(
                                                      checked,
                                                      item.module_id,
                                                      item.permissions
                                                  );
                                                }}
                                                onText="On"
                                                offText="Off"
                                            />
                                          </TableCell>
                                      ))
                              }
                            </TableRow>
                          </TableBody>

                          {/* line 3 end */}
                        </Table>
                      </TableContainer>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                          Collaborators
                        </Typography>
                        <Typography variant="h6" className={classes.sectionSubtitle}>
                          Select any standard users on your team that you'd like to
                          add as a collaborator{" "}
                          <span style={{color: "#3f51b5"}}>(optional)</span>
                          <br/>
                          If someone is added as a collaborator, they'll have access
                          and permission as the user.
                          <br/>
                          <br/>
                          To learn more about collaborators{" "}
                          <span
                              style={{cursor: "pointer", color: "#3f51b5"}}
                              onClick={() => setIsCollaboratorDescription(true)}
                          >
                      click here
                    </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <BasicSelect
                            fullWidth
                            defaultText="Select Collaborator"
                            value={userInfo.collaborator}
                            renderValue={(selected) => {
                              return selected.join(", ");
                            }}
                            options={subUsersExceptSelf}
                            mapping={{label: "name", value: "id"}}
                            onChange={handleCollaborators}
                            mayEmpty={true}
                            selectedOption={(option) => {
                              setUserInfo((prevState) => ({
                                ...prevState,
                                permission_value: option?.permission_value ?? roleDefaultPermissions[role],
                              }));
                            }}
                        />
                      </Grid>
                    </Grid>
                  </div>
            }
          </div>
        </div>

        <div className="modalFooter">
          <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              size="small"
              disableElevation
          >
            Cancel
          </Button>

          <Button
              variant="contained"
              className="submitButton"
              color="primary"
              size="small"
              disableElevation
              onClick={handleSubmit}
              disabled={savingUser || loadingUserCreationDetails}
          >
            Save
          </Button>
        </div>
      </Dialog>

      <PermissionDescriptionModal
          open={isPermissionDescription}
          onClose={() => setIsPermissionDescription(false)}
      />

      <CollaboratorsDescriptionModal
          open={isCollaboratorDescription}
          onClose={() => setIsCollaboratorDescription(false)}
      />
    </>
  );
};

export default AddNewUserModal;
