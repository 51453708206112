import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  addLeadSource,
  editLeadSource,
  _handleLeadSourceEdit,
  _handleLeadSourceModal,
  _handleLeadSourcePagination,
  _handleLeadSourceReload,
} from "../../../../actions/leadSourceAction";
import { BasicInput } from "../../../products/productsStyle";
import CustomDynamicModal from "../../../invoiceSettings/common/modal/CustomDynamicModal";
import { Box, Grid, makeStyles, Typography, withStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  fieldLabel: {
    fontSize: 14,
    marginBottom: "8px",
  },
  note: {
    fontSize: 14,
    marginTop: "8px",
  },
}));
const mapStateToProps = (state) => ({
  leadSourceReducer: state.leadSourceReducer,
});
const ErrorMessage = withStyles((theme) => ({
    root: {
        fontSize: "11px",
        color: "red",
        marginLeft: "4px",
        marginTop: "2px",

        "& .restoreText": {
            color: "#0b0be1",
            textDecoration: "underline",
            fontWeight: 600,
            cursor: "pointer",
            userSelect: "none"
        }
    },
}))(Typography);

const mapDispatchToProps = (dispatch) => ({
  addLeadSource: (params, callback) =>
    dispatch(addLeadSource(params, callback)),
  editLeadSource: (params, callback) =>
    dispatch(editLeadSource(params, callback)),
  _handleLeadSourceModal: (params) => dispatch(_handleLeadSourceModal(params)),
  _handleLeadSourceEdit: (params) => dispatch(_handleLeadSourceEdit(params)),
  _handleLeadSourceReload: (params) =>
    dispatch(_handleLeadSourceReload(params)),
  _handleLeadSourcePagination: (params) =>
    dispatch(_handleLeadSourcePagination(params)),
});

const AddLeadSourceModalApp = (props) => {
  const { edit, edit_data } = props.leadSourceReducer;
  const [submit, setSubmit] = useState(false);
  const [title, setTitle] = useState(edit ? edit_data.title : "");
  const [error, setError] = useState("");
  const classes = useStyles();
  useEffect(() => {
    if (edit) {
      setTitle(
        edit_data.source_title !== undefined ? edit_data.source_title : ""
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      setError("Lead source title is required");
      return;
    }

    if (!title.match(/^[A-Za-z 0-9]+$/)) {
      setError("Invalid lead source title. Valid text: [A-Z, a-z, 0-9]");
      return;
    }

    setSubmit(true);
    if (edit) {
      props.editLeadSource(
        { "contact-source-type-id": edit_data.id, source_title: title },
        (res) => {
          setSubmit(false);
          if (res.status === "validation-error") {
            window.showNotification("ERROR",res["html"]["source_title"][0]);
          } else if (res.status === "success") {
            window.showNotification("SUCCESS", res["html"]);
            props.onClose();
            props._handleLeadSourceEdit({ editFlag: false, editData: null });
            props._handleLeadSourceReload(true);
          }
        }
      );
    } else {
      props.addLeadSource({ source_title: title }, (res) => {
        setSubmit(false);
        if (res.status === "validation-error") {
          window.showNotification("ERROR",res["html"]["source_title"][0]);
        } else if (res.status === "success") {
          window.showNotification("SUCCESS", res["html"]);
          props.onClose();
          props._handleLeadSourcePagination({ currentPage: 1 });
          props._handleLeadSourceReload(true);
        }
      });
    }
  };

  return (
    <CustomDynamicModal
      open={props.open}
      onClose={props.onClose}
      headerIconSrc={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/lead_sources_icon.png`}
      headerTitle={edit ? "Edit Lead Source" : "Add A New Lead Source"}
      closeButtonText={"Close"}
      submitButtonText={"Save"}
      modalWidth={580}
      handleSubmit={handleSubmit}
      submitButtonDisabled={
        submit || (edit && title === edit_data.source_title)
      }
    >
      <Box className={classes.cardWrapper}>
        <Grid md={12}>
          <Typography variant="body1" className={classes.fieldLabel}>
            Lead Source Name <span style={{ color: "red" }}>*</span>
          </Typography>
          <BasicInput
            fullWidth
            name="name"
            placeholder={"Enter Lead Source Name"}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setError("");
            }}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <Typography variant="body1" className={classes.note}>
            <span style={{ fontWeight: 500, textDecoration: "underline" }}>
              Note:
            </span>{" "}
            Lead Sources are global and shared with all users on your team.
          </Typography>
        </Grid>
      </Box>
    </CustomDynamicModal>
  );
};
const AddLeadSourceModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLeadSourceModalApp);
export default AddLeadSourceModal;