import { makeStyles } from "@material-ui/core";

export const RecycleEventAddEditModalStyle = makeStyles({
    modalContainer: {
      minWidth: 550,
    },
    headerContainer: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      borderBottom: "1px solid #d3d3d3",
      padding: "5px 10px",
      gap: "10px",
    },
    titleHeader: {
      fontSize: "20px",
    },
    bodyContainer: {
      padding: "20px",
    },
    errorStyle: {
      border: "1px solid red !important",
      borderRadius: "4px",
    },
    errorText: {
      color: "red",
    },
    buttonContainer: {
      padding: "15px 20px",
      display: "flex",
      justifyContent: "end",
      gap: "10px",
    },
    description: {
      border: "1px solid #ced4da !important",
      borderRadius: "4px !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .MuiInputBase-multiline": {
        color: "var(--dark_blue)",
      },
    },
    editDescription: {
      padding: "20px",
      textAlign: "center",
    },
    lightBlue:{
        fontWeight:400,
        background:'#f5f5f5 !important',
        color:'#133159',
        width:'85px',
        border:"1px solid #ced4da !important",
        "&:focus,&:hover":{
            background:'#c1c1c1',
            color:'#ff0000 !important',
            border:"1px solid #ff0000 !important",
        }
    },
    topbarNewProductButton:{
        fontWeight:400,
        background:'#346fef',
        color:'white',
        width:'85px',
        "&:focus,&:hover":{
            background:'#2461e5'
        }
    },
  });