import { Box, Button, Dialog, Typography, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { BasicInput } from "../../productsStyle";
import { LostReasonStyle } from "./LostReasonStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  addLostReason,
  resetAddLostReasonSuccess,
  updateLostReason,
} from "../../../../actions/ProductAction";
import { lostReasonState } from "../../common/productSelector";
import { Skeleton } from "@material-ui/lab";
import { modalStylesLostReason } from "./LostReasonBody/modalStylesLostReason";

const AddOrEditLostReasonModal = ({ open, onClose, edit }) => {
  const dispatch = useDispatch();
  const { isAddLostReasonSuccessful, isSubmitting } =
    useSelector(lostReasonState);
  const [formData, setFormData] = useState({
    title: edit?.reason || "",
  });

  const [error, setError] = useState({
    title: "",
    description: "",
    closeDate: "",
  });

  useEffect(() => {
    if (isAddLostReasonSuccessful) {
      onClose();
      dispatch(resetAddLostReasonSuccess());
    }
  }, [isAddLostReasonSuccessful]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: "",
    };
    if (edit && formData?.title === edit?.reason) {
      isValid = false;
      errors.title = "Reason is already in use";
    }
    if (formData?.title.trim() === "") {
      errors.title = "Reason is required";
      isValid = false;
    }
    if (formData?.title.length > 20) {
      errors.title = "Reason must be less than 20 characters";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    if (edit) {
      dispatch(
        updateLostReason({
          lost_deal_reason_id: edit.id,
          reason: formData.title,
        })
      );
    } else {
      dispatch(addLostReason({ reason: formData.title }));
    }
  };

  const classes = modalStylesLostReason();
  const { topbarNewProductButton, lightBlue } = LostReasonStyle();
  const handleOnChange = (e) => {
    const newValue = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      title: newValue,
    }));

    if (newValue.length > 50) {
      setError((prevState) => ({
        ...prevState,
        title: "Reason must be less than 50 characters",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        title: "",
      }));
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.modalContainer}>
        <div className={classes.headerContainer}>
          <div style={{ width: "50px", height: "50px" }}>
            <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <div className={classes.titleHeader}>
            {edit ? "Edit Reason" : "Add a New Reason"}
          </div>
        </div>
        <div className={classes.bodyContainer}>
          {edit && (
            <div className={classes.editDescription}>
              <Typography className={classes.fieldDescription}>
                You are attempting to edit a global lost deal reason.
                <br />
                This change will be applied to all users on your team.
              </Typography>
            </div>
          )}
          <div mb={2}>
            <Typography className={`${error.title ? classes.errorText : ""}`}>
              Lost Deal Reason<span style={{ color: "red" }}>*</span>
            </Typography>
            {isSubmitting ? (
              <Skeleton width={"100%"} height={"48px"} />
            ) : (
              <div mt={1}>
                <BasicInput
                  fullWidth
                  value={formData?.title}
                  className={`${error.title ? classes.errorStyle : ""}`}
                  onChange={handleOnChange}
                  required
                />
                {error.title && (
                  <Typography variant="body2" className={classes.errorText}>
                    {error.title}
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            onClick={onClose}
            color="primary"
            className={lightBlue}
            variant="text"
            disableElevation
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className={topbarNewProductButton}
            variant="contained"
            disableElevation
            disabled={isSubmitting}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddOrEditLostReasonModal;
