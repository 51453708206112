import { sendRequest } from './rootApi';
import Api from "../constants/Api";
import HttpRequest from './HttpRequest';

export const loadCustomFieldsApi = async (payload) => {
    return await sendRequest("post", Api.loadCustomFields,payload);
}

export const updateCustomFieldApi = async (payload) => {
    return await sendRequest("post", Api.updateCustomField, payload);
}

export const updateCustomFieldLengthApi = async (payload) => {
    return await sendRequest("post", Api.updateCustomFieldLength, payload);
}

export const deleteCustomFieldApi = async (payload) => {
    return await sendRequest("post", Api.deleteCustomField, payload);
}

export const changeCustomFieldStatusApi = async (payload) => {
    return await sendRequest("post", Api.changeCustomFieldStatus, payload);
}

export const customFieldAddsApi = async (payload) => {
    return await sendRequest("post", Api.customFieldAdd, payload);
}

export const customFieldCreateOrUpdateApi = async (payload) => {
    return await sendRequest("post", Api.customFieldCreateOrUpdateApi, payload);
}

export const changeCustomFieldValueApi = async (payload) => {
    return await sendRequest("post", Api.changeCustomFieldValue, payload);
}

export const loadUserPersonalizedDataApi = async (payload) => {
    return await sendRequest("post", Api.loadPersonalizedField, payload);
}

export const createPersonalizedFieldDataApi = async (payload) => {
    return await sendRequest("post", Api.createPersonalizedTag, payload);
}

export const updatePersonalizedFieldValueApi = async (payload) => {
    return await sendRequest("post", Api.updatePersonalizedFieldValue, payload);
}

export const deletePersonalizedTagApi = async (payload) => {
    return await sendRequest("post", Api.deletePersonalizedTag, payload);
}

export const createNewPersonalizedFieldApi = async (payload) => {
    return await sendRequest("post", Api.createNewPersonalizedField, payload);
}

/* custom field v2 */
export const getCustomFieldDataV2Api = async (payload) => {
    return await sendRequest("post", Api.getCustomFieldDataV2, payload);
}
export const reorderCustomFieldDataV2Api = async (payload) => {
    return await sendRequest("post", Api.reorderCustomFieldV2, payload);
}
export const updateCustomFieldDataApi = async (payload) => {
    return await sendRequest("post", Api.updateCustomFieldDataV2, payload);
}

/* custom field refactor v2 */
export const getCustomFieldFolderV2Api = async (payload) => {
  return await sendRequest("post", Api.getCustomFieldFolderV2, payload);
}
export const getCustomFieldsV2Api = async (payload) => {
  return await sendRequest("post", Api.getCustomFieldsV2, payload);
}

export const addCustomFieldsV2Api = async (payload) => {
    return await sendRequest("post", Api.addCustomFieldsV2, payload);
}

export const deleteCustomFieldsV2Api = async (payload) => {
    return await sendRequest("post", Api.deleteCustomFieldsV2, payload);
}

export const addCustomFieldFolder = async ( payload ) => {
    return HttpRequest.post(Api.userCustomFieldFolder, payload);
};

export const getCustomFieldFolder = async ( payload ) => {
    return HttpRequest.get(Api.userCustomFieldFolder, payload);
};

export const updateCustomFieldFolder = async ( payload ) => {
    return HttpRequest.put(Api.userCustomFieldFolder, payload);
};

export const deleteCustomFieldFolder = async ( payload ) => {
    return HttpRequest.delete(Api.userCustomFieldFolder, payload);
};

export const addCustomField = async ( payload ) => {
    return HttpRequest.post(Api.userCustomField, payload);
};

export const getCustomField = async ( payload ) => {
    return HttpRequest.get(Api.userCustomField, payload);
};

export const updateCustomField = async ( payload ) => {
    return HttpRequest.put(Api.userCustomField, payload);
};

export const deleteCustomField = async ( payload ) => {
    return HttpRequest.delete(Api.userCustomField, payload);
};

export const handleUserCustomFieldStatus = async ( payload ) => {
    return HttpRequest.put(Api.userCustomFieldStatus, payload);
};

export const handleUserCustomFieldGroup = async ( payload ) => {
    return HttpRequest.put(Api.userCustomFieldGroupChange, payload);
};

export const handleUserCustomFieldOrderChange = async ( payload ) => {
    return HttpRequest.post(Api.userCustomFieldOrderChange, payload);
};


  