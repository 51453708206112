import { styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleAssetLoadingState, setPipelines, setTeamUsers } from "../../actions/ProductAction";
import { fetchPipelineApi, getTeamUserListApiV2 } from "../../api/productsApi";
import useQueryParam from "../../hooks/useQueryParam";
import LeadSource from "../leadSource/index.js";
import { HOME_PAGE_TABS } from "../products/helper/coreConstant.js";
import InsuranceCompaniesNew from "../products/insuranceCompaniesNew/index.jsx";
import LostReasons from "../products/lostReasons/content/LostReasons.jsx";
import PolicyTypes from "../products/policyTypes/index.jsx";
import RecycleEvent from "../products/recycleEvent/content/RecycleEvent.jsx";
import HomePageTab from "./HomePageTab";
import Utils from "../../helpers/Utils.js";
import LeadSourceV2 from "../insurance/leadSourceV2/index.js";

const ProductsWrapper = styled("div")(({ theme }) => ({
  height: ` calc(100vh - 100px)`,
}));
const Policies = () => {
  const dispatch = useDispatch();
  const [openTab] = useQueryParam("open-tab");
  const history = useHistory();
  const [value, setValue] = useState(null);

  useEffect(() => {
    let tab = HOME_PAGE_TABS.POLICY_TYPES
    if (history &&
      history.location &&
      history.location.state &&
      history.location.state.from === "settingPage"
    ) {
      tab = history.location.state.tab;

      history.replace({});
    }

    try {
      if (openTab && HOME_PAGE_TABS[openTab]) {
        tab = HOME_PAGE_TABS[openTab];
      }
    } catch (err) {

    } finally {

    }

    setValue(tab);
    window.setActiveSidebar('setting')
    loadAssets().then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadAssets = async () => {
    try {
      const [response1, response2] = await Promise.all([
        fetchPipelineApi(),
        getTeamUserListApiV2()
      ]);

      if (response1 && response1.data && response1.data.data) {
        dispatch(setPipelines(response1.data.data));
      }

      if (response2 && response2.data && response2.data.status && response2.data.data) {
        dispatch(setTeamUsers(response2.data.data));
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(handleAssetLoadingState(false));
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RenderTabContent = () => {

    switch (value) {
      case HOME_PAGE_TABS.DASHBOARD:
        // return <DashboardContent />;
        break;

      case HOME_PAGE_TABS.POLICY_TYPES:
        return <PolicyTypes />

      case HOME_PAGE_TABS.INSURANCE_COMPANIES:
        return <InsuranceCompaniesNew/>

      case HOME_PAGE_TABS.LOST_REASONS:
        return <LostReasons />

      case HOME_PAGE_TABS.RECYCLE_EVENT:
        return <RecycleEvent />

      case HOME_PAGE_TABS.LEAD_SOURCE:
        return (
          (Utils.getAccountData("agencyBusinessType") === undefined || 
            Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?  
            <LeadSource/> : 
            <LeadSourceV2 />
        )
      default:
        return null;
    }
  };

  return (
    <ProductsWrapper>
      <HomePageTab
        value={value}
        handleChange={handleChange}
      />
      {RenderTabContent()}
    </ProductsWrapper>
  );
};

export default Policies;
