import { Box, Button, Typography } from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../constants/PermissionConst";
import HowItWorksModal from "../../../common/Modal/HowItWorksModal";
import { RecycleEventIcon } from "../../../ten-dlc/helpers/Icon";
import AddOrEditRecycleEventModal from "./AddOrEditRecycleEventModal";
import { CustomButton } from "./RecycleEventBody/CustomButton";
import { LostReasonStyle } from "./RecycleEventStyle";

const RecycleEventHeader = () => {
  const { topbarHeaderTitle,subTitle } = LostReasonStyle();
  const [openAddRecycleModal, setOpenAddRecycleModal] = useState(false);
  const [openHowItsWorksModal, setOpenHowItsWorksModal] = useState(false);
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
  const handleCloseModal = () => {
    setOpenAddRecycleModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <div style={{display:"flex", gap:'6px',alignItems:"center"}}>
       <div>
        <RecycleEventIcon/>
       </div>
        <div >
        <Typography style={{height:'unset !important'}} variant="h5" className={topbarHeaderTitle}>
          Recycle Events
        </Typography>
        <Typography style={{height:'unset !important'}} className={subTitle}>
            Don't give up just because you lost a deal. Recycle your old leads to
            turn them into new opportunities in the future.
          </Typography>
        </div>
        
       </div>
        <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
          <Button
            size="small"
            variant="text"
            startIcon={<HelpOutlineOutlined />}
            onClick={() => setOpenHowItsWorksModal(true)}
          >
            <i>How does this work?</i>
          </Button>
          {isUserHasPermission &&(
            <CustomButton
              variant="contained"
              onClick={() => setOpenAddRecycleModal(true)}
              startIcon={<AddIcon />}
              disableElevation
            >
              New Event
            </CustomButton>
          )}
        </Box>
      </Box>

      {openAddRecycleModal && (
        <AddOrEditRecycleEventModal
          open={openAddRecycleModal}
          onClose={handleCloseModal}
        />
      )}
      {openHowItsWorksModal && (
        <HowItWorksModal
          open={openHowItsWorksModal}
          onClose={() => setOpenHowItsWorksModal(false)}
          videoSrc="https://cdn.pixabay.com/video/2020/06/22/42890-432450896_large.mp4" // this need to be changed
        />
      )}
    </div>
  );
};

export default RecycleEventHeader;
