import { useState } from "react";
import Styles from "./style.module.css";
import {
  CloseIcon,
  EditIcons,
} from "../../../../customField/custom-field-v2/helpers/IconList";
import BasicModal from "../../../../invoiceSettings/common/modal/BasicModal";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../../../products/common/productSelector";
import SelectWithIcon from "../add-field/SelecteWithIcon";
import { handleSelectedCustomFieldFolder, userCustomFieldGroupChange } from "../../../../../actions/CustomFieldInsuranceAction";

const MoveField = ({ field, open, onClose = () => {} }) => {
  const { folderList, isUpdatingField } =
    useSelector(customFieldInsuranceState);
  const [selectedFolder, setSelectedFolder] = useState(
    field?.user_custom_field_group_id || ""
  );
  const dispatch = useDispatch();
  const folderObj = {};
  folderList.forEach((folder) => {
    folderObj[folder.id] = folder.title;
  });
  const getGroupNameById = (id) => folderObj[id] || " ";

  const handleSubmitGroupField = () => {
    if(isUpdatingField){
      return;
    }
    const payload = {
      id: field.id,
      group_id: selectedFolder,
      group_name: getGroupNameById(selectedFolder),
    }
    dispatch(userCustomFieldGroupChange({
      ...payload,
      callback: () => {
        dispatch(handleSelectedCustomFieldFolder(selectedFolder));
        onClose();
      }
    }))
    
  };
  return (
    <BasicModal open={open} onClose={onClose}>
      <div className={Styles.mainWrp}>
        <div className={Styles.header}>
          <span className={Styles.headerText}>
            Move field to another folder
          </span>
        </div>

        <div className={Styles.body}>
          <div className={Styles.inputWrp}>
            <span className={Styles.lebelText}>Folder Group</span>
            <div className={Styles.inputFieldWrp}>
              <SelectWithIcon
                options={folderList}
                mapping={{ label: "title", value: "id" }}
                defaultText="Select Folder Group"
                onChange={(e) => 
                  setSelectedFolder(e.target.value)}
                value={selectedFolder}
              />
            </div>
          </div>
        </div>

        <div className={Styles.footer}>
          <div
            className={`${Styles.button} ${Styles.cancelButton}`}
            onClick={onClose}
          >
            <span className={Styles.icon}>
              <CloseIcon color={"#fff"} height="18px" width="18px" />
            </span>
            <span className={Styles.buttonText}>Cancel</span>
          </div>
          <div
            className={ isUpdatingField ? Styles.disabledButton : `${Styles.button} ${Styles.submitButton}`}
            onClick={handleSubmitGroupField}
          >
            <span className={Styles.icon}>
              <EditIcons height="18px" width="18px" color="#fff" />
            </span>
            <span className={Styles.buttonText}>
              {isUpdatingField ? "Submitting..." : "Submit Move"}
            </span>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
export default MoveField;
