import React from "react";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import emailStyle from "./style.module.css"
import BasicInput from "../../custom/BasicInput";

const ConfirmAlertV3 = (props) => {
    let default_params = {
        type: "warning",
        title: "",
        description: "",
        highlightText: "",
        cancelText: "No, Keep it",
        submitText: "Yes, Delete it",
        handleClose: () => {
        },
        handleConfirm: () => {
        },
        closeOnClickOutside: true,
        width: "570px",
        typeConfirm: null,
        callConfirmLast: false,
        imgSrc: ""
    }
    let data_params = { ...default_params, ...props };

    let {
        title,
        description,
        closeOnClickOutside,
        cancelText,
        submitText,
        handleClose,
        handleConfirm,
        width,
        typeConfirm,
        callConfirmLast,
        imgSrc
    } = data_params;

    const handleSubmit = (onClose) => {
        if (typeConfirm){
            let confirmTypeInput = document.getElementById("confirmTypeInput-qazwsxedc");

            if (confirmTypeInput.value.toLowerCase() !== typeConfirm.toLowerCase()){
                confirmTypeInput.focus();
                window.showNotification("ERROR", "Please enter correct type");
                return;
            }

            if(callConfirmLast){
                onClose();
                setTimeout(() => {
                    handleConfirm();
                }, 0);
            }else{
                handleConfirm();
                onClose();
            }
        }else {
            if(callConfirmLast){
                onClose();
                setTimeout(() => {
                    handleConfirm();
                }, 0);
            }else{
                handleConfirm();
                onClose();
            }
        }
    }

    return (
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className={emailStyle.mainWrp} style={{width: width}}>
                        {imgSrc && (
                            <div className={emailStyle.logoContainer}>
                                <img src={imgSrc} alt="logo" className={emailStyle.logo} />
                            </div>
                        )}
                        <div className={`${emailStyle.bodyWrp} `}>
                            <span className={emailStyle.bodyTitle}>{title}</span>
                            {
                                description ? <span className={emailStyle.descriptionText}>{description}</span> : ""
                            }

                            {
                                typeConfirm ? <span className={emailStyle.descriptionText}>Type <span className={emailStyle.strongText}>{typeConfirm}</span> below to confirm.</span> : ""
                            }

                            {
                                typeConfirm ? <BasicInput id="confirmTypeInput-qazwsxedc" autoFocus/> : ""
                            }
                        </div>
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                            <div className={emailStyle.footerWrp}>
                                <span
                                    onClick={() => {
                                        handleSubmit(onClose)
                                    }}
                                    className={`${emailStyle.button} ${emailStyle.submitButton}`}
                                >
                                    {submitText}
                                </span>

                                <span
                                    onClick={() => {
                                        handleClose();
                                        onClose();
                                    }}
                                    className={`${emailStyle.button} ${emailStyle.cancelButton}`}
                                >
                                    {cancelText}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            },
            closeOnClickOutside: closeOnClickOutside,
            overlayClassName: emailStyle.overlay
        })
    )
}
export default ConfirmAlertV3;