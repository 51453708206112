import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { addTagWithPrivacy, editTagWithPrivacy } from "../../api/profileApi";
import { PERMISSION_LEVELS, PERMISSION_MODULES, PRIVACY_LEVEL, PRIVACY_LEVEL_TAB_VALUE } from "../../constants/CoreConstants";
import BasicInput from "../custom/BasicInput";
import BasicSelect from "../custom/BasicSelect";
import { TagStyleForAddorEditModal } from "./TagStyleForAddorEditModal";
const AddOrEditTagModal = ({ open, onClose, edit=null, taglist, setTagList, privacyLevelTab }) => {
  const [selectedColor, setSelectedColor] = useState(edit ? (edit.color || "#346fef") : "#346fef");
  const classes = TagStyleForAddorEditModal();
  const isUserManageGlobalItem = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
  const privacyLevelTabValue = (privacyLevelTab === PRIVACY_LEVEL_TAB_VALUE.GLOBAL) ? PRIVACY_LEVEL.GLOBAL : PRIVACY_LEVEL.PRIVATE 
  const [tagName, setTagName] = useState(edit ? edit.title : '');
  const [tagPrivacyLevel, setTagPrivacyLevel] = useState(edit ? edit.privacyLevel : isUserManageGlobalItem ? privacyLevelTabValue : '' );
  const [errorMessage, setErrorMessage] = useState({
    tagName: '',
    tagPrivacyLevel: '',
  });
  const validateForm = () => {
    const errors = {};
  
   
    if (!tagName.trim()) {
      errors.tagName = "Tag name is required.";
    }
    
    if (!tagPrivacyLevel.trim()) {
      errors.tagPrivacyLevel = "Privacy level is required.";
    }
  
    setErrorMessage(errors);
    return Object.keys(errors).length === 0; 
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; 
    }
  
    const submitPayload = {
      title: tagName, 
      privacyLevel: tagPrivacyLevel,
      color : selectedColor,
    };
  
    try {
      let resp;
      if (edit) {
        submitPayload.id = edit.id;
        resp = await editTagWithPrivacy(submitPayload);
      } else {
        resp = await addTagWithPrivacy(submitPayload);
      }
  
      if (resp.success) {
        if(privacyLevelTabValue === tagPrivacyLevel ){
          if(edit){
            let updatedTagList = taglist.map((tag) =>
              tag.id === submitPayload.id
                ? { ...tag, title: submitPayload.title, privacyLevel: submitPayload.privacyLevel ,color: submitPayload.color }
                : tag
            );
            setTagList(updatedTagList);
          } else {
            setTagList([resp.data, ...taglist]);
          }
        }
        window.showNotification("SUCCESS", resp.message);
        onClose();
      } else {
        window.showNotification("error", resp.message);
      }
    } catch (error) {
      console.log('caldsdssdsdsq dfmel');
      console.error("Error submitting tag:", error);
      window.showNotification("error", "Something went wrong! Please try again later.");
    }
  };
  
  const PRIVACY_OPTION = isUserManageGlobalItem ? [
    { label: "My Tags", value: PRIVACY_LEVEL.PRIVATE },
    { label: "Global Tag", value: PRIVACY_LEVEL.GLOBAL },
  ] : [
    { label: "My Tags", value: PRIVACY_LEVEL.PRIVATE }];

  return (
    <>
      <Dialog
        minWidth={500}
        open={open}
        onClose={onClose}
        className={classes.modalWrapper}
      >
        <div className="modalHeader">
          <div className="headerLeft">
            <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/tags_icon.png`}
              alt=""
              height={50}
            />

            <div className="headerText">
              <h5 className="title">{edit ? "Edit Tag" : "Create a New Tag"}</h5>
              {/* <p className="description">
                Add a new user to your team and set their permission levels.
              </p> */}
            </div>
          </div>

          <div className="headerRight">
            <IconButton color="secondary">
              <CloseIcon onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className="modalBody">
          <div className={classes.contentWrapper}>
            <div className={classes.section}>
              <div>
                {/* <Typography variant="h6" className={classes.sectionTitle}>
                  New User Info
                </Typography> */}

                <Grid container spacing={2}>
                  <Grid item xs={12} md={edit ? 12 : 6}>
                    <div variant="body1" className={classes.fieldLabel}>
                      Tag Name <span style={{ color: "var(--red)" }}>*</span>
                    </div>
                    <BasicInput
                      name="fullName"
                         value={tagName}
                         placeholder="Enter Tag Name"
                        onChange={(e)=>{setTagName(e.target.value);
                          setErrorMessage((prevState) => ({
                            ...prevState,
                            tagName: "",
                        }));
                        }}
                      fullWidth
                      required
                    />
                    {errorMessage.tagName && (
                <div className={classes.errorText}>
                  {errorMessage.tagName}
                </div>
              )}
                  </Grid>
                 {(!edit) && ( 
                  <Grid item xs={12} md={6}>
                    <div className={classes.fieldLabel}>
                      Type <span style={{ color: "var(--red)" }}>*</span>
                    </div>
                    <BasicSelect
                      fullWidth
                      name="category"
                      defaultText="Select a tag"
                      value={tagPrivacyLevel}
                      options={PRIVACY_OPTION}
                      mapping={{ label: "label", value: "value" }}
                       onChange={(e) => {setTagPrivacyLevel(e.target.value);
                        setErrorMessage((prevState) => ({
                          ...prevState,
                          tagPrivacyLevel: "",
                      }));
                       }}
                    />
                     {errorMessage.tagPrivacyLevel && (
                <div className={classes.errorText}>
                  {errorMessage.tagPrivacyLevel}
                </div>
              )}
                  </Grid>
                )}

                  <Grid item xs={12}>
                    <div style={{ marginBottom: "20px" }}>
                      <Typography>Choose a tag color</Typography>
                      <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #346fef" }}
                          onClick={() => setSelectedColor("#346fef")}
                        >
                          {selectedColor === "#346fef" && (
                            <div
                              style={{ background: " #346fef" }}
                              className={classes.innerIconColor}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #924cff" }}
                          onClick={() => setSelectedColor("#924cff")}
                        >
                          {selectedColor === "#924cff" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: " #924cff" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #008951" }}
                          onClick={() => setSelectedColor("#008951")}
                        >
                          {selectedColor === "#008951" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: " #008951" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #f79009" }}
                          onClick={() => setSelectedColor("#f79009")}
                        >
                          {selectedColor === "#f79009" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: " #f79009" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: "#00bec5" }}
                          onClick={() => setSelectedColor("#00bec5")}
                        >
                          {selectedColor === "#00bec5" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: "#00bec5" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: "#1d2939" }}
                          onClick={() => setSelectedColor("#1d2939")}
                        >
                          {selectedColor === "#1d2939" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: "#1d2939" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: "#475467" }}
                          onClick={() => setSelectedColor("#475467")}
                        >
                          {selectedColor === "#475467" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: "#475467" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <p style={{ textAlign: "center",margin:"0px"}}>
                      Admins with the proper permissions can create both global
                      tags and My Tags.<br/> Global Tags are shared with all users on
                      your team. My Tags are individual<br/> tags only available in
                      your account.
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>

        <div className="modalFooter">
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            size="small"
            disableElevation
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitButton"
            color="primary"
            size="small"
            disableElevation
             onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default AddOrEditTagModal;
