export const PERMISSION_MODULES = {
    MANAGE_BILLING: 0,
    MANAGE_USERS: 1,
    MANAGE_GLOBAL_ITEMS: 2,
    EXPORT_CONTACTS: 3,
    EDIT_LEAD_OWNER: 4,
    MANAGE_OTHER_USER_GOALS: 5,
    MANAGE_OTHER_USER_REPORTS: 6,
    MANAGE_OTHER_USER_CALENDAR: 7,
    MANAGE_OTHER_USER_SCHEDULER: 8,
};

export const PERMISSION_LEVELS = {
    READ: 2,
    WRITE: 4,
    DELETE: 8,
};

export const PERMISSION_ROLES = {
    STANDARD_USER: "STANDARD_USER",
    LIMITED_USER: "LIMITED_USER",
};

export const PERMISSION_MODULES_LABELS = {
    [PERMISSION_ROLES.STANDARD_USER]: [
        {
            module_id: PERMISSION_MODULES.MANAGE_BILLING,
            name: "Manage Billing",
            description: "If toggled on, the Standard User will gain access to view and manage the billing associated with your account.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_USERS,
            name: "Manage Users",
            description: "If toggled on, the Standard User will gain access to add/remove users and adjust their permission levels.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,
            name: "Manage Global Items",
            description: "If toggled on, the Standard User will gain access to manage Global Items (global campaigns, global tags, global triggers, global custom fields, global quick replies, etc).",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.EXPORT_CONTACTS,
            name: "Export Contacts",
            description: "If toggled on, the Standard User will gain access to export contacts from the system.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.EDIT_LEAD_OWNER,
            name: "Edit Lead Owner",
            description: "If toggled on, the Standard User will gain access to edit the lead owner of any contact in the system. They will have the ability to reassign contacts to themselves or any other user on their team.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_OTHER_USER_GOALS,
            name: "Manage Other User Goals",
            description: "If toggled on, the Standard User will gain access to view and manage other user’s goals.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_OTHER_USER_REPORTS,
            name: "Manage Other User Reports",
            description: "If toggled on, the Standard User will gain access to manage other user's report.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_OTHER_USER_CALENDAR,
            name: "Manage Another User’s Calendar",
            description: "If toggled on, the Standard User will gain access to view and manage other user’s calendars.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_OTHER_USER_SCHEDULER,
            name: "View Another User’s Appointment Scheduler",
            description: "If toggled on, the Standard User will gain access to view the appointment scheduler page of other users.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        }
    ],
    [PERMISSION_ROLES.LIMITED_USER]: [
        {
            module_id: PERMISSION_MODULES.EXPORT_CONTACTS,
            name: "Export Contacts",
            description: "If toggled on, the Standard User will gain access to export contacts from the system.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.EDIT_LEAD_OWNER,
            name: "Edit Lead Owner",
            description: "If toggled on, the Standard User will gain access to edit the lead owner of any contact in the system. They will have the ability to reassign contacts to themselves or any other user on their team.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_OTHER_USER_CALENDAR,
            name: "Manage Another User’s Calendar",
            description: "If toggled on, the Limited User will gain access to view and manage other user’s calendars.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        },
        {
            module_id: PERMISSION_MODULES.MANAGE_OTHER_USER_SCHEDULER,
            name: "View Another User’s Appointment Scheduler",
            description: "If toggled on, the Limited User will gain access to view the appointment scheduler page of other users.",
            permissions: [PERMISSION_LEVELS.READ, PERMISSION_LEVELS.WRITE, PERMISSION_LEVELS.DELETE],
        }
    ]
};