import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BasicInput } from "../../../products/productsStyle";
import { icons } from "../icon";
import {
  _handleLeadSourcePagination,
  _handleLeadSourceSearch,
} from "../../../../actions/leadSourceAction";
import useDebounce from "../../../../hooks/useDebounce";

const SearchLeadSource = (props) => {
  const { per_page, search_text } = props.leadSourceReducer;
  const [perPage, setPerPage] = useState(per_page);
  const [search, setSearch] = useState(search_text);
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
      props._handleLeadSourceSearch(debouncedSearch);
  }, [debouncedSearch]);

  const handlePerPageChange = (event) => {
    const value = event.target.value;
    setPerPage(value);
    props._handleLeadSourcePagination({ perPage: value });
  };

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearch(newValue);
    props._handleLeadSourcePagination({ currentPage: 1 });
  };

  return (
    <div className="items">
      <div className="records">
        <span>Show</span>
        <div className="input-field col s12">
          <select
            style={{ display: "block" }}
            value={perPage}
            onChange={handlePerPageChange}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="100">100</option>
          </select>
        </div>
        <p>Records</p>
      </div>
      <div className="record_search">
        <BasicInput
          fullWidth
          type="text"
          placeholder="Search lead source"
          value={search}
          onChange={handleSearchChange}
        />
        <div className="search_icon">{icons.search}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  leadSourceReducer: state.leadSourceReducer,
});

const mapDispatchToProps = (dispatch) => ({
  _handleLeadSourcePagination: (params) =>
    dispatch(_handleLeadSourcePagination(params)),
  _handleLeadSourceSearch: (params) =>
    dispatch(_handleLeadSourceSearch(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchLeadSource);
