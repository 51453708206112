import { styled, TableRow } from "@material-ui/core";

export const RecycleEventStyle = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
  },
    color: theme.palette.text.primary,
  
    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      color:'var( --dark_blue)',
      fontSize:'15px'
    },
    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
      padding:'10px'
    },
  
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
    "& .buttonWrap": {
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      gap:10
    },
    "& .editButton": {
      display: "flex",
      cursor: "pointer",
      border:"none",
      background: "#dedede",
      borderRadius: "5px",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 0",
      width: "95px",
      fontSize: "1rem",
      color: "#133159",
      "& .MuiSvgIcon-root": {
      fontSize: "18px", 
      fill:"#000" 
      },
      "&:hover": {
        backgroundColor: "#E5E8EF !important",
        border: "none",
        color: "#466fef",
        "& .MuiSvgIcon-root": {
          fill: "#466fef",
          fontSize: "18px",  
        },
      },
      "&:focus": {
        backgroundColor: "#dedede !important",
        border: "none",
      },
    },
    "& .deleteButton": {
      display: "flex",
      border:"none",
      cursor: "pointer",
      background: "#dedede",
      borderRadius: "5px",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 0",
      width: "95px",
      fontSize: "1rem",
      color: "#133159",
      "& .MuiSvgIcon-root": {
          fill: "#000",
          fontSize: "18px",  
        },
      "&:hover": {
        backgroundColor: "#E5E8EF !important",
        border: "none",
        color: "#FF264A !important",
        "& .MuiSvgIcon-root": {
          fill: "#FF264A",
        },
      },
      "&:focus": {
        backgroundColor: "#dedede !important",
        border: "none",
      },
    },
    "& .actionContainer":{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 3,
    }
  }));
  