const TextInput = ({fieldName, callBack, value = "", fieldType = 'text', placeHolder = "", extraClassName = "", isRequired=false, isReadOnly=false }) => {
    return (
        <input className={"personal_info_text_field "+extraClassName}
               type={fieldType}
               name={fieldName}
               value={value}
               onChange={(event)=>callBack(event.target.name,event.target.value)}
               placeholder={placeHolder}
               required={isRequired}
               readOnly={isReadOnly}/>
    )
}
export default TextInput;