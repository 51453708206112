import { useState } from "react";

import Styles from "./style.module.css";
import {
  AddIcon,
  CloseIcon,
} from "../../../../customField/custom-field-v2/helpers/IconList";
import BasicSelect from "../../../../custom/BasicSelect";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../../../products/common/productSelector";
import {
  PERMISSION_LEVELS,
  PERMISSION_MODULES,
  PRIVACY_LEVEL,
  PRIVACY_OPTION_CUSTOM_FIELD,
  PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT,
} from "../../../../../constants/CoreConstants";
import SelectWithIcon from "./SelecteWithIcon";
import BasicInput from "../../../../custom/BasicInput";

const AddCustomField = ({
  title = "Add New personalized Field",
  onClose = () => {},
  edit = null,
}) => {
  const { folderList, fieldsObject, isAddingCustomField, isUpdatingField } =
    useSelector(customFieldInsuranceState);
  const folderObj = {};
  folderList.forEach((folder) => {
    folderObj[folder.id] = folder.title;
  });

  const getGroupNameById = (id) => folderObj[id] || " ";
  const canUserManageGlobalItem = window.hasPermission(
    PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,
    PERMISSION_LEVELS.WRITE
  );

  const [states, setStates] = useState({
    field_name: edit ? edit.title : "",
    field_value: edit ? edit.field_value : "",
    group_id: fieldsObject.selectedFolder || folderList[0]?.id || null,
    group_name: getGroupNameById(
      fieldsObject.selectedFolder || folderList[0]?.id
    ),
    privacy_level: !canUserManageGlobalItem
      ? PRIVACY_LEVEL.PRIVATE
      : PRIVACY_LEVEL.GLOBAL,
  });
  const [errors, setErrors] = useState({
    field_name: "",
    field_value: "",
  });
  const isFieldUpdated =
    states.field_name !== edit?.title ||
    states.field_value !== edit?.field_value ||
    states.group_id !== edit?.user_custom_field_group_id;

  const dispatch = useDispatch();

  const handleFieldName = (e) => {
    setErrors({ ...errors, field_name: "" });
    setStates({ ...states, field_name: e.target.value });
  };
  const handleFieldValue = (e) => {
    setStates({ ...states, field_value: e.target.value });
  };
  const handleGroupName = (id) => {
    setStates({ ...states, group_id: id, group_name: getGroupNameById(id) });
  };
  const handlePrivacyLevel = (e) => {
    setStates({ ...states, privacy_level: e.target.value });
  };

  const validationForm = () => {
    const errors = {};
    const fieldName = states.field_name?.trim();

    if (!fieldName) {
      errors.field_name = "Personalized field name is required.";
    } else if (fieldName.length > 180) {
      errors.field_name =
        "Personalized field name must not exceed 180 characters.";
    }

    if (!states.field_value) {
      errors.field_value = "Personalized field value is required.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (
      !validationForm() ||
      isAddingCustomField ||
      isUpdatingField ||
      !isFieldUpdated
    ) {
      return;
    }

    const payload = {
      personalized_title: states.field_name,
      personalized_value: states.field_value,
      group_id: states.group_id,
      group_name: states.group_name,
    };
    console.log(payload);
  };
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <span className={Styles.title}>{title}</span>
        <span className={Styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      <div className={`${Styles.bodyWrp} cf_scroll_bar`}>
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            Personalized Field Name <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.inputFieldWrp}>
            <BasicInput
              onChange={handleFieldName}
              value={states.field_name}
              placeholder="Enter Personalized Field Name"
              fullWidth
            />
          </div>
          {errors.field_name && (
            <div className={Styles.error}>{errors.field_name}</div>
          )}
        </div>

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            Personalized value <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.inputFieldWrp}>
            <BasicInput
              onChange={handleFieldValue}
              value={states.field_value}
              placeholder="Enter Personalized Field Name"
              fullWidth
            />
          </div>
          {errors.field_name && (
            <div className={Styles.error}>{errors.field_name}</div>
          )}
        </div>

        {!edit && (
          <div className={Styles.inputWrp}>
            <span className={Styles.lebelText}>Field Type</span>
            <div className={Styles.inputFieldWrp}>
              <BasicSelect
                options={
                  canUserManageGlobalItem
                    ? PRIVACY_OPTION_CUSTOM_FIELD
                    : PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT
                }
                mapping={{ label: "label", value: "value" }}
                defaultText="Select Field Type"
                onChange={handlePrivacyLevel}
                value={states.privacy_level}
              />
            </div>
          </div>
        )}

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Folder Group</span>
          <div className={Styles.inputFieldWrp}>
            <SelectWithIcon
              options={folderList}
              mapping={{ label: "title", value: "id" }}
              defaultText="Select Folder Group"
              onChange={(e) => handleGroupName(e.target.value)}
              value={states.group_id}
            />
          </div>
        </div>
      </div>

      <div className={Styles.footerWrp}>
        <div className={Styles.buttonWrp}>
          <div
            className={
              isAddingCustomField || isUpdatingField || !isFieldUpdated
                ? Styles.disabledButton
                : Styles.button
            }
            onClick={handleSubmit}
          >
            <span className={Styles.submitIcon}>
              <AddIcon />{" "}
            </span>
            <span className={Styles.submitText}>
              {edit
                ? isUpdatingField
                  ? "Updating Field"
                  : "Update Field"
                : isAddingCustomField
                ? "Adding Field..."
                : "Add Field"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCustomField;
