import HttpRequest from "../../api/HttpRequest";
import Api from "../../constants/Api";
import {deactivateUser} from "../../api/profileApi";

class manageUserService {
    getUsers = (params) => {
        return HttpRequest.post(Api.subUserList, params);
    }

    saveUser = (body) => {
        return HttpRequest.post(Api.saveSubUser, body);
    }

    getSubUserFormData = (params) => {
        return HttpRequest.get(Api.subUserFormData, params);
    }

    getUserCreationDetails = (params) => {
        return HttpRequest.post(Api.userCreationDetails, params);
    }

    forceLogin = (params) => {
        return HttpRequest.post(Api.getSubUserAuthKey, params);
    }

    deactivateUser = (params) => {
        return HttpRequest.post(Api.deactivateUser, params);
    }

    activateUser = (params) => {
        return HttpRequest.post(Api.activateUser, params);
    }

    deleteUserWithoutTransfer = (params) => {
        return HttpRequest.post(Api.deleteUserWithoutTransfer, params);
    }
}

export default new manageUserService();