import { makeStyles } from "@material-ui/core";
import React from "react";
import SettingsTable from "./SettingsTable";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        padding: theme.spacing(2),

        "& .record_search": {
          display: "flex",
          justifyContent: "flex-end",
          "& .search": {
              margin: 0,
              "&:fucus": {
                  boxShadow: "none !important"
              }
          },
  
          "& .search_icon": {
              right: 12,
              top: 12,
          }
      }
    }
}));



const MainContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainContent}>
      <SettingsTable />
    </div>
  );
};

export default MainContent;
