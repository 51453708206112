import { connect } from "react-redux";
import { _handleLeadSourcePagination, _handleLeadSourceReload } from "../../../../actions/leadSourceAction";
import { icons } from "../icon";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  actionButton: {
    display: "flex",
    padding: "8px 16px",
    background:"#3c7ef3",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    "&:focus, &:hover": {
      backgroundColor: "#9daac1",
    },
  },
  button: {
    padding: "8px 16px",
    borderRadius: "4px", 
    border: "2px solid #3c7ef3",
    cursor: "pointer",
    background: "transparent",
    color:"#3c7ef3",
    "&:focus, &:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonContainer: {
    padding: "8px 16px",
    border: "none",
    cursor: "pointer",
    background: "transparent",
    color:"#3c7ef3",
    "&:focus, &:hover": {
      backgroundColor: "transparent",
    },
  },
}));
const PaginationLeadSrouce = (props) => {
    const { total_records, total_pages, current_page } = props.leadSourceReducer;
    const classes = useStyles();

    const handlePaginationChange = (event, page) => {
      event.preventDefault();
      if (page != null) {
        props._handleLeadSourcePagination({ currentPage: page });
        props._handleLeadSourceReload(true);
      }
    };
  
    let previous_page = null,
      next_page = null;
    if (current_page > 1) {
      previous_page = current_page - 1;
    }
  
    // eslint-disable-next-line eqeqeq
    if (current_page != total_pages) {
      next_page = current_page + 1;
    }
  
    // eslint-disable-next-line eqeqeq
    if (total_records == 0) {
      return false;
    }
  
    return (
      <div style={{display:"flex",gap: 10,justifyContent:"center",alignItems:"center"}}>
   
          <div>
            <button
              className={classes.actionButton}
              onClick={(event) => handlePaginationChange(event, previous_page)}
              style={
                previous_page == null
                  ? {
                      cursor: "not-allowed",
                      backgroundColor: "#9daac1",
                      borderColor: "#9daac1",
                    }
                  : {}
              }
            >
              {icons.previous}
            </button>
          </div>
          <div>
            <button
             className={`${classes.button} d-flex align-items-center`} 
            onClick={(e) => e.preventDefault()}>
              <span>{current_page}</span>
            </button>
          </div>
          <div>
            <button
              className={`${classes.buttonContainer} d-flex align-items-center`}
              onClick={(e) => e.preventDefault()}
            >
              <span>{icons.backwardSlash}</span>
              {total_pages}
            </button>
          </div>
          <div>
            <button
             className={classes.actionButton}
              onClick={(event) => handlePaginationChange(event, next_page)}
              style={
                next_page == null
                  ? {
                      cursor: "not-allowed",
                      backgroundColor: "#9daac1",
                      borderColor: "#9daac1",
                    }
                  : {}
              }
            >
              {icons.next}
            </button>
          </div>
          <div style={{ color: "#9daac1", marginLeft: 20 }}>
            Total lead sources : {total_records}
          </div>
      </div>
    );
  };
  const mapStateToProps = (state) => ({
    leadSourceReducer: state.leadSourceReducer,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    _handleLeadSourcePagination: (params) => dispatch(_handleLeadSourcePagination(params)),
    _handleLeadSourceReload: (params) => dispatch(_handleLeadSourceReload(params)),
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(PaginationLeadSrouce);