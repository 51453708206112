import React, { useEffect, useState } from "react";
import {
  Button,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { CustomScrollbar } from "../../products/productsStyle";
import AddCustomField from "./modal/add-field/AddCustomField";
import { useDispatch, useSelector } from "react-redux";
import {
  handlePageChange,
  handleRowChange,
  handleSearch,
} from "../../../actions/CustomFieldInsuranceAction";
import BasicInput from "../../custom/BasicInput";
import useDebounce from "../../../hooks/useDebounce";
import GlobalEmptyPage from "../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import EachContantItem from "./EachContantItem";
import CustomDrawerPype from "../../common/drawer/CustomDrawerPype";
import { EMPTY_DATA_IMG } from "../../../constants/CoreConstants";
import SkeletonComponent from "./ContantSkeleton";
import { IconList } from "../../../constants/IconList";
const data = [
  {
    id: 1426,
    user_id: 14752,
    title: "Policy Number",
    status: 1,
    field_value: "100",
    personalize_tag: "[[policy_number dfghkb ubiu iiub ]]",
    created_at: "2024-09-24 11:05:51",
    updated_at: "2024-09-24 11:05:51",
    uuid: null,
    privacy_level: "GLOBAL",
  },
  {
    id: 1427,
    user_id: 14752,
    title: "Policy Name",
    status: 1,
    field_value: "120",
    personalize_tag: "[[policy_name]]",
    created_at: "2024-09-24 11:05:51",
    updated_at: "2024-09-24 11:05:51",
    uuid: null,
    privacy_level: "PRIVATE",
  },
  {
    id: 1426,
    user_id: 14752,
    title: "Policy Number",
    status: 1,
    field_value: "100",
    personalize_tag: "[[policy_number]]",
    created_at: "2024-09-24 11:05:51",
    updated_at: "2024-09-24 11:05:51",
    uuid: null,
    privacy_level: "GLOBAL",
  },
  {
    id: 1427,
    user_id: 14752,
    title: "Policy Name",
    status: 1,
    field_value: "120",
    personalize_tag: "[[policy_name]]",
    created_at: "2024-09-24 11:05:51",
    updated_at: "2024-09-24 11:05:51",
    uuid: null,
    privacy_level: "PRIVATE",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    borderRadius: "8px",
    padding: "16px",
    flex: 1,

    "& .headWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: "1px solid #cdcdcd",
    },

    "& .newFieldButton": {
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },

    "& .record_search": {
      display: "flex",
      justifyContent: "flex-end",
      "& .search": {
        border: "1px solid rgb(167 167 167 / 65%) !important",
        margin: 0,
        "&:focus": {
          boxShadow: "none !important",
        },
      },

      "& .search_icon": {
        right: 12,
        top: 12,
      },
    },

    "& .customFieldList": {
      "& .listItem": {
        padding: 8,
        border: "1px solid #e1e1e1",
        borderRadius: 4,
        marginBottom: 8,

        "&:not(:last-child)": {
          marginBottom: 8,
        },

        "& .itemHeader": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 8,
          marginBottom: 8,
          borderBottom: "1px solid #e1e1e1",

          "& .title": {
            fontSize: 15,
            fontWeight: 600,
            color: "var(--dark_blue)",
          },

          "& .privateChip": {
            marginRight: 40,
            backgroundColor: "#00ff91",
          },
          "& .globalChip": {
            marginRight: 40,
            backgroundColor: "#6258FF",
            color: "#FFF",
          },
          "& .actions": {
            display: "flex",
            alignItems: "center",
            gap: 16,
            width: "300px",
          },
        },

        "& .itemDetails": {
          paddingInline: 16,
          "& .title": {
            fontSize: 15,
            fontWeight: 600,
            color: "var(--dark_blue)",
          },
          "& .sub": {
            fontSize: 14,
            color: "#546376",
          },
        },
      },
    },
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#fafafa",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: "var( --deep_dark_blue)",
    padding: "20px 10px",
  },
  tableBodyHeight: {
    height: `calc(100vh - 445px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
}));

const Content = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const debouncedSearch = useDebounce(searchText, 1000);

  const handleSearchInput = (e) => {
    dispatch(handleSearch(e.target.value?.trim()));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(handlePageChange(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(handleRowChange(event.target.value));
  };

  return (
    <div className={classes.root}>
      <div className="headWrapper">
        <div class="record_search">
          <BasicInput
            placeholder="Search By Field Name"
            onChange={(e) => handleSearchInput(e)}
          />
          <div class="search_icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="#133159"
                fill-opacity="0.65"
              ></path>
            </svg>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="newFieldButton"
          startIcon={<AddIcon />}
          disableElevation
          onClick={() => setIsDrawerOpen(true)}
        >
          New Merge Field
        </Button>
      </div>
      {/* {gettingFields ? (
        <SkeletonComponent/>
      ) : Array.isArray(fields) && fields[0] ? ( */}
      <div style={{ display: "flex", gap: "20px", width: "100%" }}>
        <div style={{ width: "80%" }}>
          <CustomScrollbar style={{ height: "calc(100vh - 410px)" }}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell style={{display:'flex', alignItems:'center', gap:'6px'}} className={classes.tableHeader}>
                      <span>Field Label</span>
                      <span style={{marginTop:'3px'}}>{IconList.info}</span>
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Personalize Value{" "}
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Personalize Tag
                    </TableCell>
                    <TableCell className={classes.tableHeader}>Type</TableCell>
                    <TableCell style={{width:'80px'}} className={classes.tableHeader}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((field, index) => {
                    return <EachContantItem field={field} key={index} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomScrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={10}
            rowsPerPage={10}
            page={1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div style={{ width: "20%", height: "calc(100vh - 410px)" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/personalized_fields_tab.png`}
            alt="custom field"
          />
        </div>
      </div>
      {/* ) : ( */}
      {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <GlobalEmptyPage image={EMPTY_DATA_IMG} title="No Fields Found" />
        </div> */}
      {/* )} */}

      {isDrawerOpen && (
        <CustomDrawerPype
          isShow={isDrawerOpen}
          hanldeDrawer={(status) => {}}
          renderChild={() => {
            return <AddCustomField onClose={() => setIsDrawerOpen(false)} />;
          }}
        />
      )}
    </div>
  );
};

export default Content;
