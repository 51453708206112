import { Chip, Grid, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import BootstrapTooltip from '../../common/BootstrapTooltip';
import { MoveIcons } from '../../customField/custom-field-v2/helpers/IconList';
import SwitchC from '../../customField/subComponents/Switch';
import { AGENCY_BUSINESS_TYPE, ALL_FIELD_NAME, CHECKBOX, DATE, DATETIME, MULTILINE_TEXT, NUMERIC, PERMISSION_LEVELS, PERMISSION_MODULES, PHONE, PRIVACY_LEVEL, RADIO, SELECT, TEXT, WEBSITE, ZIP_CODE } from '../../../constants/CoreConstants';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import AddCustomField from './modal/add-field/AddCustomField';
import CustomDrawerPype from '../../common/drawer/CustomDrawerPype';
import ConfirmAlertV3 from '../../common/confirm-alert/ConfirmAlertV3';
import { customFieldStatus, deleteCustomFieldInsurance, handleSelectedCustomFieldFolder } from '../../../actions/CustomFieldInsuranceAction';
import { useDispatch, useSelector } from 'react-redux';
import { customFieldInsuranceState } from '../../products/common/productSelector';
import CustomConfirmAlert from '../../customField/custom-field-v2/helpers/confirm-alert/CustomConfirmAlert';
import MoveField from './modal/move-field/MoveField';
import Utils from '../../../helpers/Utils';

const format = (typeId) => {
    switch (typeId) {
        case TEXT:
            return 'text';
        case MULTILINE_TEXT:
            return 'text';
        case NUMERIC:
            return '0-9';
        case DATE:
            return 'date';
        case CHECKBOX:
            return 'checkbox';
        case SELECT:
            return 'select';
        case RADIO:
            return 'radio';
        case PHONE:
            return '0-9';
        case ZIP_CODE:
            return '0-9';
        case WEBSITE:
            return 'url';
        case DATETIME:
            return 'datetime';
        default:
            return '';
    }
}

const EachContantItem = ({field}) => {
  const { fieldsObject } = useSelector(customFieldInsuranceState);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
    const dispatch = useDispatch();
    const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
    const canUserManageGlobalItem = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
    const isCustomFieldPrivate = field.privacy_level === PRIVACY_LEVEL.PRIVATE; 

    const handleEdit = () => {
        setIsEditDrawerOpen(true);
      }
      const confirmDelete = () => {
        const payload = {
          id: field.id
        };
        dispatch(
          deleteCustomFieldInsurance({
            ...payload,
            callback: () => {
               dispatch(handleSelectedCustomFieldFolder(fieldsObject.selectedFolder));
            },
          })
        );
       };
     
       const handleDelete = () => {
         ConfirmAlertV3({
           handleConfirm: () => confirmDelete(),
           title: "Are you sure you want to delete this group?",
           description: "This action cannot be undone.",
         });
       };

       const handleStatus = () => {
         CustomConfirmAlert({
           onSubmit: () => {
             dispatch(
               customFieldStatus({ id: field.id, status: field.status ? 0 : 1 })
             );
           },
           title:
             "Are you sure to " + (field.status ? "disable" : "enable") + "?",
           description: "Updated & sync with account information.",
           cancelText: "No, Keep it",
           submitText: "Yes, Change it",
         });
       };

       const handleMoveGroup = () => {
         setIsMoveModalOpen(true);
       };

       const parseDefaultValues = (defaultValues) => {
        try {
          return JSON.parse(defaultValues).join(", ");
        } catch (error) {
          console.error("Invalid JSON in defaultValues:", error);
          return ''; 
        }
      };
    return (
        <>
            <div className="listItem">
        <div className="itemHeader">
          <div className="title">{field.title || ""}</div>
          <div className="actions">
            {(isAgencyTypeInsurance && field.privacy_level) && (
              <Chip
                size="small"
                label={ isCustomFieldPrivate ? "My Field" : "Global Field"}
                className={isCustomFieldPrivate ? "privateChip" : "globalChip"}
              />
            )}
            { ((isAgencyTypeInsurance && canUserManageGlobalItem) || isCustomFieldPrivate) && (
              <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end', width:'100%', gap:'8px'}}>
            <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
              <IconButton size="small" onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </BootstrapTooltip>
           
            <BootstrapTooltip
              title={"Delete"}
              placement={"top"}
              arrow
            >
              <IconButton size="small" onClick={handleDelete}>
                <DeleteOutlineIcon  />
              </IconButton>
            </BootstrapTooltip>
            <BootstrapTooltip title={"Move"} placement={"top"} arrow>
              <IconButton size="small" onClick={handleMoveGroup}>
                <MoveIcons />
              </IconButton>
            </BootstrapTooltip>
            <SwitchC
              status={field.status || 0}
              brandColor={"#3f51b5"}
              onText="On"
              offText="Off"
              onChange={handleStatus}
            />
            </div>
          )}
           
          </div>
          
        </div>
        <div className="itemDetails">
          <Grid container>
            <Grid item xs={6}>
              <span className="title">
                Personal Tag: {field.personalize_tag || ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              <span className="sub">
                Type: {ALL_FIELD_NAME[field.type_id]}
              </span>
            </Grid>
            <Grid item xs={6}>
              <span className="sub">
                Maximum Character Allowed: {field.max_length || 100}
              </span>
            </Grid>
            <Grid item xs={6}>
              <span className="sub">
                Format: {format(field.type_id)}
              </span>
            </Grid>
            {field.default_values && (
              <Grid item xs={6}>
                <span className="sub">
                  Value: {parseDefaultValues(field.default_values)}
                </span>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      {isEditDrawerOpen && 
     <CustomDrawerPype
        isShow={isEditDrawerOpen}
        hanldeDrawer={(status) => {}}
        renderChild={() => {
          return <AddCustomField title='Edit Custom Field ' onClose={() => setIsEditDrawerOpen(false)} edit={field} />;
        }}
      />}
      { isMoveModalOpen && (
        <MoveField open={isMoveModalOpen} field={field} onClose={() => setIsMoveModalOpen(false)} />
      )}
        </>
    );
};

export default EachContantItem;