import React from 'react';
import {Skeleton} from "@material-ui/lab";
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {useSkeletonStyles} from "./styles";

const AddUserModalSkeleton = () => {
    const classes = useSkeletonStyles();

    return (
        <div className={classes.section}>
            <div>
                <Skeleton height={"30px"} width={"200px"} variant="text" className={classes.sectionTitle} />

                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Skeleton height={"25px"} width={"150px"} variant="text" className={classes.fieldLabel} />
                        <Skeleton height={"30px"} variant="rect" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton height={"25px"} width={"150px"} variant="text" className={classes.fieldLabel} />
                        <Skeleton height={"30px"} variant="rect" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Skeleton height={"25px"} width={"150px"} variant="text" className={classes.fieldLabel} />
                        <Skeleton height={"30px"} variant="rect" />
                    </Grid>
                </Grid>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Skeleton height={"25px"} width={"150px"} variant="text" className={classes.sectionTitle} />
                    <Skeleton height={"15px"} width={"450px"} variant="text" className={classes.sectionSubtitle} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton height={"40px"} variant="rect"/>
                </Grid>
            </Grid>

            <div>
                <TableContainer component={"span"}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow className={classes.tableRowWrapper}>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton height={"30px"} variant="text"/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Skeleton height={"25px"} width={"150px"} variant="text" className={classes.sectionTitle} />
                    <Skeleton height={"15px"} width={"450px"} variant="text" className={classes.sectionSubtitle} />
                    <Skeleton height={"15px"} width={"350px"} variant="text" className={classes.sectionSubtitle} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton height={"40px"} variant="rect"/>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddUserModalSkeleton;