import { Button, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "white",
    borderBottom: "1px solid #3c7ef31a",

    "& .headerLeft": {
      display: "flex",
      alignItems: "center",
      gap: 5,

      "& .headerText": {
        "& .title": {
          fontSize: 20,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 14,
          color: "#525252",
          margin: 0,
        },
      },
    },

    "& .headerRight": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& .backButton": {
        width: 110,
      },
    },
  },
}));
const CalenderHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/calendar/1.0.0/calendar_icon.png`}
          alt=""
          width={70}
          height={70}
        />

        <div className="headerText">
          <h5 className="title">Calendar</h5>
          <p className="description">
            Connect your calendar to synchronize your meetings and events
          </p>
        </div>
      </div>
      <div className="headerRight">
        {history.location.state &&
          history.location.state.from === "accountPage" && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push("/user/profile")}
              >
                Back
              </Button>
          )}

        {history.location.state &&
          history.location.state.from === "settingPage" && (
              <Button
                variant="contained"
                color="primary"
                style={{background:"#3e50f7"}}
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push("/user/settings")}
              >
                Back
              </Button>
          )}
      </div>
    </div>
  );
};

export default CalenderHeader;
